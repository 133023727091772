import { Component, OnInit } from '@angular/core';
import { ChatBotMessage } from '../../types/chatbotMessages';
import { ChatService } from '../../services/chat.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  public userMessage: ChatBotMessage = {role: 'user', content: ''}

  constructor(public chatService: ChatService,
              private messageService: MessageService        
    ) { }

  ngOnInit(): void {
    
  }

  public sendUserMessage(): void {
    this.chatService.messages.push(this.userMessage)
    this.userMessage = {role: 'user', content: ''}
    const callbackId = 'groqAnswer';
    const message = {
          name: 'askGroq',
          args: [this.chatService.messages, callbackId]
    };
    this.messageService.sendMsg(message, 'main');
    //this.chatService.messages.push({role: 'assistant', content: 'I am a bot, I am not able to help you with that.'})

  }

}
