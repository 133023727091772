
<div class="monthly-overview-wrapper">
  <button *ngIf="toggleButton" mat-stroked-button color="primary" class="toggle" (click)="toggleSubject()" [matTooltip]="toggleSubjectTooltip">
    <mat-icon>{{toggleSubjectIcon}}</mat-icon>
  </button>
  <div class="monthly-overview-container">
    <!---first row/ header-->
    <div [ngStyle]="{'text-align': 'center'}">
      <div class="header-month">{{month}} {{year}}</div>
      <div class="header-weeks">
        <div class="subject" *ngIf="displaySubject" [ngStyle]="getSubjectStyle()">
        </div>
        <div *ngFor="let week of monthlyOverviewWeeks" class="header-week" [ngStyle]="{'width': getWeekWidth(week.days.length)}">
          <div class="week-label">{{week.displayLabel}}</div>
          <div [ngStyle]="{'display': 'flex'}" class="week-days">
            <div *ngFor="let day of week.days" [ngClass]="[day.isThisMonth ? '' : 'isNotThisMonth', day.isToday ? 'isToday' : '']" [ngStyle]="{'width': dayWidth + 'px'}" class="week-day">
              <div>{{day.displayWeekDay}}</div>
              <div>{{day.displayDate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll">
      <div>
        <div *ngFor="let subject of subjects" class="row" (click)="selectRow(subject.subject_id)"  [ngStyle]="{height: defaultRowBorderHeight + 'px'}">
          <!---first layer in a row-->
          <div class="grid" [ngClass]="[subject.is_selected ? 'isSelected' : '']">
            <div *ngIf="displaySubject" class="subject" [ngStyle]="getSubjectStyle()">
            </div>
            <div *ngFor="let week of monthlyOverviewWeeks" class="grid-week" [ngStyle]="{'display': 'flex', 'width': getWeekWidth(week.days.length)}">
              <div *ngFor="let day of week.days" [ngClass]="[day.isToday ? 'isToday' : '']" [ngStyle]="{'width': dayWidth + 'px', 'height': defaultRowBorderHeight + 'px'}" class="week-day"></div>
            </div>
          </div>
          <!---second layer in a row-->
          <div class="tasks">
            <div *ngIf="displaySubject" class="subject" [ngStyle]="getSubjectStyle()" [ngClass]="subjectVertical ? 'vertical' : 'horizontal'">
                <div *ngFor="let subject_label of subject.display_label" [matTooltip]="subject_label" class="subject-label" 
                [ngStyle]="{'white-space': 'nowrap', 'text-overflow' : 'ellipsis', 'overflow' : 'hidden'}">
                    {{subject_label}}
                </div>
            </div>
            <div class="tasks-container" [ngStyle]="{width: daysWidth + 'px'}">
              <div class="day-part" [ngClass]="[task.type]" [matTooltip]="task.tooltip" [ngStyle]="{left: task.task_left + 'px', width: task.task_width + 'px', height: defaultTaskHeight + 'px', top: taskMargin + 'px', 'border-top': task.color? '7px solid ' + task.color: ''}" *ngFor="let task of tasks_data.item(subject.subject_id)">
                <div *ngFor="let label of task.display_label" [ngStyle]="{'white-space': 'nowrap', 'text-overflow' : 'ellipsis', 'overflow' : 'hidden'}">{{label}}</div>
                <mat-icon *ngIf="task.icon" class="day-icon" svgIcon="{{task.icon}}"></mat-icon>
              </div>
              <div class="day-part" [matTooltip]="task.tooltip" [ngClass]="[task.type, task.subType]" [ngStyle]="{left: task.task_left + 'px', width: task.task_width + 'px', top: defaultTaskHeight + 3*taskMargin + 'px', height: defaultTaskHeight + 'px'}" *ngFor="let task of action_tasks_data.item(subject.subject_id)">
                <!-- <div *ngFor="let label of task.display_label" [ngStyle]="{'white-space': 'pre-line'}"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>