import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-plans-view',
  templateUrl: './subscription-plans-view.component.html',
  styleUrls: ['./subscription-plans-view.component.scss']
})
export class SubscriptionPlansViewComponent implements OnInit {

  constructor() { }

  public subscriptionBasic: any = { price:"9,99", n_product: 2, language: 1, branding: "Basic Customization Options"}
  public subscriptionAdvance: any = { price:"19,99", n_product: 5, language: 2, branding: "Advanced Customization Options", secure: "None", additional_features: ["Analytical Tools", "Tracking Information"]}
  public subscriptionPremium: any = { price:"34,99", n_product: 10, language: 3, branding: "Premium Customization Options", secure: "Basic", additional_features: ["Analytical Tools", "Tracking Information"]}
  public subscriptionEnterprise: any = { additional_features: ["Analytical Tools", "Tracking Information"]}

  ngOnInit(): void {
  }

}
