import { Component, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar'

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>) { }

  ngOnInit(): void { 
      // console.log("....", this.data);
  }

  public snackDismiss() {
    this.snackBarRef.dismiss(); 
  }

}
