<!--Popup window-->
<h3 mat-dialog-title class="title-dialog">Login</h3>
<div mat-dialog-content class="login">
    <form class="login-form" [formGroup]="formGroup">
      <mat-form-field *ngIf="['PwAndSignUp'].indexOf(dialogLoginService.loginMode)>=0">
        <mat-label>Email</mat-label>
        <input autocomplete="off" matInput
               formControlName="name"
               required
               (keydown.enter)="data_send()">
      </mat-form-field>
      <mat-form-field *ngIf="['simplePw', 'noPw'].indexOf(dialogLoginService.loginMode)>=0">
        <mat-label>Email/User</mat-label>
        <input autocomplete="off" matInput
               formControlName="name"
               required
               (keydown.enter)="data_send()">
      </mat-form-field>
      <mat-form-field *ngIf="['simplePw', 'PwAndSignUp'].indexOf(dialogLoginService.loginMode)>=0">
        <mat-label>Password</mat-label>
        <input matInput
               formControlName="password"
               [type]="'password'"
               required
               (keydown.enter)="data_send()">
      </mat-form-field>
    </form>
    <div class="error-message">{{error_message}}</div>
</div>
<div mat-dialog-actions class="actions">
    <p *ngIf="pw_reset_request">If name or email exists you will receive an email</p>
    <!--
    <button mat-button (click)="onCancel()">Abbrechen</button>
    -->
    <button *ngIf="['simplePw', 'PwAndSignUp'].indexOf(dialogLoginService.loginMode)>=0" class="button" color="primary" mat-raised-button [disabled]="!nameStatus || !pwStatus" (click)="data_send()" >Login</button>
    <button *ngIf="['simplePw', 'PwAndSignUp'].indexOf(dialogLoginService.loginMode)<0" class="button" color="primary" mat-raised-button [disabled]="!nameStatus" (click)="data_send()" >Login</button>
    <button *ngIf="['simplePw', 'PwAndSignUp'].indexOf(dialogLoginService.loginMode)>=0" class="button" color="primary" mat-raised-button [disabled]="!nameStatus" (click)="send_email()">Reset Password</button>
    <button *ngIf="['PwAndSignUp'].indexOf(dialogLoginService.loginMode)>=0" class="button" color="primary" mat-raised-button (click)="sign_up()">Sign Up</button>
    <!--button *ngIf="dialogLoginService.app!='addiplan'" class="reset-button" mat-button (click)="anonymous()">Continue w/o login</button-->
    <div class="version-info">{{versionService.name_and_version_number}}</div>
</div>