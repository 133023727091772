import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Observable, throwError ,Subject, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Message } from 'src/app/shared-generic/types/message';
import { MatSnackBar,MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from './../../shared/components/snackbar/snackbar.component';
import { FormDataService } from 'src/app/shared-generic/services/form-data.service';

const localHeadersConfig = new HttpHeaders(
    {
        //'Content-Type': 'multipart/form-data; boundary=something'
        'enctype':"multipart/form-data"
    }
)

@Injectable({
    providedIn: 'root',
  })
export class UiSupplierService {
    public events: Array<string> = [];
    private subscriptions = [];
    public openSubj = new BehaviorSubject<boolean>(false);
    // public checkViewStatusSubject =  new Subject<string>();
    public imgSubject =  new Subject<Message>();
    public isOpen = false;
    public next_view: string;
    public last_subview: string;
    public cadOpen: boolean = false;
    public snackBarRef: MatSnackBarRef<any>;
    public image = undefined; // set in part-view
    public backend = "supplier";
    public enableCancel: boolean = false;

    constructor(
        private formDataService: FormDataService,
        private msgService: MessageService,
        private httpClient: HttpClient,
        private router: Router,
        public snackBar: MatSnackBar,
        ) {
        this.openSubj.subscribe(
            (open) => this.isOpen = open
        );
    }

    public getDefaultViews(): void {
        const callbackId = "navigateToView/supplier";
        const msg = {
          name: 'default_views',
          args: [callbackId],
          action: 'default'
        }
        this.msgService.sendMsg(msg, this.backend);
    }

    public setViews(msg: Message): void {
        this.next_view = msg.args.view;
        console.log("view: ", this.next_view);
        this.router.navigate(["/supplier/", this.next_view ]);
    }

    public navigate(step: string): void {
        const callbackId = 'navigateToView/supplier';
        const msg = {
          name: 'navigate_request',
          args: [step, callbackId],
          action: 'default'
        }
        this.msgService.sendMsg(msg, this.backend);
    }

    public checkViewStatus(view_element: string): void {
        this.last_subview = view_element;
        this.formDataService.checkViewStatusSubject.next(view_element);
    }


    public getImage(row: any): Observable<Blob>  {
      const endpoint = '/api-offer/img_getter';
      return this.httpClient
        .get(endpoint, {responseType: 'blob'})
        .pipe(
            tap(data => console.log(data)),
            catchError(this.handleError('get', endpoint))
        )
    }

    private handleError(operation: String, url: string) {
        return (err: any) => {
            let errMsg = `error in ${operation}() retrieving ${url}`;
            console.log(`${errMsg}:`, err)
            if(err instanceof HttpErrorResponse) {
                // you could extract more info about the error if you want, e.g.:
                console.log(`status: ${err.status}, ${err.statusText}`);
                // errMsg = ...
            }
            // or throwError
            return throwError(errMsg);
        }
    }

    public openSnackbar(msg: string, duration: number = 0): void{
        let auto_close;
        if (duration) {
            auto_close = true;
        }else {
            auto_close = false;
        }
        let panelClass = this.cadOpen ? ['snackbar-ai-splited'] : ['snackbar-ai-full'];
        let config = duration ?
        { duration: duration,
            data: { msg: msg, auto_close: auto_close },
            panelClass: panelClass}
        : {data: { msg: msg, auto_close: auto_close},
            panelClass: panelClass };
        this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, config);
    }


}
