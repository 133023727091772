import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductInfoMenuComponent } from '../components/product-info-menu/product-info-menu.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardTitleService {
  private selectedTitleSubject = new BehaviorSubject<string | null>("Product Info");
  selectedTitle$: Observable<string | null> = this.selectedTitleSubject.asObservable();

  public formStates = {
    "Product-Info": [
      {form: "product-general-form", state: false},
      {form: "product-general-form-I", state: false},
      {form: "product-consumer-info-form", state: false}
    ],
    "Cultivation": [
      {form: "product-cultivation-form", state: false},
      {form: "product-cultivation-form-2", state: false},
    ],
    "Production": [
      {form: "product-production-form", state: false},
      {form: "product-batch-form", state: false},
    ],
    "Bottling": [
      {form: "product-bottling-form", state: false},
    ],
    "Sales": [
      {form: "product-sales-form", state: false},
    ],
    "Transport": [
      {form: "product-transport-form", state: false},
    ],
  }

  // updateFormState(component: ProductInfoMenuComponent, formStateUpdates: { [key: string]: { state: boolean }[] }): void {
  //   component.formStates = formStateUpdates;
  //   component.checkSectionStates();
  // }

  setSelectedTitle(title: string) {
    this.selectedTitleSubject.next(title);
    
  }
}
