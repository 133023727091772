import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';
import { FilterOption } from './../types/filter-option';

@Injectable({
  providedIn: 'root'
})
export class SimpleFilterService {
  private subscriptions = [];
  public simpleFilterGroupSubject =  new Subject<Message>();

  constructor(private messageService: MessageService) { }

  public getSimpleFilterGroup(backend: string): Subject<Message>  {
    const callbackId = 'simplefiltergroup/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            console.log("-------be got data");
            this.simpleFilterGroupSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.getSimpleFilterGroupMessage(backend);
    return this.simpleFilterGroupSubject;
  }

  public getSimpleFilterGroupMessage(backend: string){
    const callbackId = 'simplefiltergroup/' + backend;
    const message = {
      name: 'getSimpleFilterGroup',
      args: [callbackId]
  };
  this.messageService.sendMsg(message, backend);
  }

  public setSimpleFilterSelectionMessage(backend: string, filterOption: FilterOption ){
    const callbackId = 'simplefilterselection/' + backend;
    const message = {
      name: 'setSimpleFilterSelection',
      args: [callbackId, filterOption]
  };
  this.messageService.sendMsg(message, backend);
  }
}
