import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent implements OnInit {

  @Input() backend = 'offer-creator';
  @Input() sort: boolean = true;
  @Input() title: string = "Filter und Sortieren";
  @Input() controller: string = "";
  @Input() optionsMaxHeight;
  @Input() filtersMaxHeight;
  @Output() sortTrigger = new EventEmitter<string>();
  @Input() onFilter = true;
  @Input() maxWidth: string = '200';
  @Output() filterEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  public emitSortTrigger(event){
    this.sortTrigger.emit(event);
  }

  applyFilter(event: string) {
    this.filterEvent.emit(event);
  }
}
