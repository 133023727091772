<div class="flex items-center bg-zinc-100 rounded-3xl p-4 h-full">
  <img
    (click)="toProductInfo()"
    [src]="product.image_url"
    alt="Product Image"
    class="w-2/12 h-full rounded-2xl"
  />
  <div class="w-8/12 h-full flex flex-col items-start justify-around ml-4">
    <p class="text-lg font-bold">{{ product.title }}</p>
    <p class="text-lg font-bold">{{ product.name }}</p>
    <p class="text-lg font-bold">{{ product.type }}</p>
  </div>
  <div class="w-1/12 h-full flex flex-col items-center justify-center">
    <div class="flex flex-col mt-2">
      <!-- <div class="flex flex-row justify-end">
        
      </div> -->
      <qrcode
        #parent
        style="display: none"
        (qrCodeURL)="onCodeChange($event)"
        imageSrc="../assets/VisTrace.png"
        [qrdata]="qrCodeDownloadLink"
        [width]="500"
        [imageHeight]="75"
        [imageWidth]="75"
        [errorCorrectionLevel]="'M'"
        margin="10"
      ></qrcode>
      <button (click)="saveAsImage(parent)">
        <img
          src="../assets/qr_icon_download.svg"
          class="w-8 h-8 m-2 cursor-pointer"
          alt="lock"
        />
      </button>

      <img
        src="../assets/visbility.svg"
        class="w-8 h-8 m-2 cursor-pointer"
        alt="preview"
        (click)="previewClicked()"
      />
      <img
        src="../assets/delete.svg"
        class="w-8 h-8 m-2 cursor-pointer"
        alt="delete"
        (click)="deleteProduct()"
      />
    </div>
  </div>
  <div class="w-1/12 h-full flex flex-col justify-center">
    <img
      src="../assets/incomplete.svg"
      class="w-8 h-8 m-4 cursor-pointer"
      alt="lock"
    />
  </div>
</div>
