
  <div class="user-main">
    <mat-card appearance="outlined" class="base">
       <mat-card-title class="title">      
         <div class="titlediv">
          <app-styled-button class="back-button"
                [colorStyle]="'brand'"
                [title]="'Zurück'"
                [isFilled]="false"
                (action)="backHome()"
                [width]="'105'"
                [iconName]="'keyboard_arrow_left'">
            </app-styled-button>
          
           Benutzerverwaltung
         </div>
       </mat-card-title>
   
       <mat-card-content style="margin-top: 10px;">
                 <!--- table with exisitng offers -->
                 <div class="bottom-panel" [ngStyle]="{'height.px': layoutService.contentCard, 'overflow-x': 'hidden', 'overflow-y':'auto'}">
             
                  <app-database-table [xname]="user_table"
                    [onFilter]="true"
                    [backend]="backend" 
                    [singleSelection]="true"
                    [onEditor]="false"
                    [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
                  </app-database-table>

                  <div class="button-add">
                    <app-styled-button
                      [colorStyle]="'brand'"
                      [title]="'Benutzer hinzufügen'"
                      [isFilled]="false"
                      (action)="add_user()"
                      [width]="'200'"
                      [iconName]="'add_circle_outline'">
                    </app-styled-button>
                    <app-styled-button
                      [colorStyle]="'brand'"
                      [title]="'Benutzer entfernen'"
                      [isFilled]="false"
                      (action)="deleteUser()"
                      [width]="'200'"
                      [iconName]="'delete'"
                      [disabled]="!rowSelectedUser">
                    </app-styled-button>
                    <!-- show this button only for setups with password -->
                    <app-styled-button *ngIf="['noPw'].indexOf(dialogLoginService.loginMode)<0"
                      [colorStyle]="'brand'"
                      [title]="'Passwort setzen'"
                      [isFilled]="false"
                      (action)="setPasswort()"
                      [width]="'200'"
                      [iconName]="'vpn_key'"
                      [disabled]="!rowSelectedUser">
                    </app-styled-button>
                 
                  </div>

                  <!-- <mat-card-actions class="button-add">
                    <button mat-stroked-button color="primary" (click)="add_user()">
                      <mat-icon>add_circle_outline</mat-icon>Benutzer hinzufügen
                    </button>
                  </mat-card-actions> -->

                  <mat-card-content *ngIf="rowSelectedUser" class="input-user">
                    <!--- form displaying data of an exisitng (selected in the table above) offer -->
                    <app-generic-form class="input-user-form"
                      [xname]="formName"
                      [backend]="backend"
                      [titlesize]="14"
                      [fontsize]="14"
                      [entity]="userKey"
                      [col_number]="4"
                      [infoForm]="false"
                      [frozen]="true"
                      [init]="init"
                      [updateTrigger]="updateTriggerUserForm">
                    </app-generic-form>

                    <!-- <mat-card-actions class="button-add">
                      <button mat-stroked-button color="primary" (click)="deleteUser()">Benutzer entfernen
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-card-actions> -->

                  </mat-card-content>
                 </div>
       </mat-card-content>
     </mat-card>
   </div> 