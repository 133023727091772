import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  public currentApp = "/sales-order/overview-planning";
  public activeTab = 0;
  public activeNestedTab = 0;
  public activeTemplateTab = 0;
  public gotoSubject: Subject<string> = new Subject();

  public constructor(
    private router: Router,
    private dialogLoginService: DialogLoginService
  ) {

    this.gotoSubject.subscribe( target => {
      if (target==="main") {this.goto_main();}
    });
  }

  start_user_management() {
    this.router.navigateByUrl("/main/user-management");
  }

  start_email_settings() {
    this.router.navigateByUrl("/main/email-settings");
  }


  goto_main() {
    // if (!this.router.url.startsWith("/sales-order")) {
    //this.router.navigateByUrl("/sales-order/overview-planning");
    console.log("nav to main");
    this.router.navigateByUrl("/main/overview-view");
    //this.uiMainService.getDefaultViews();
    this.activeTab = 0;
    this.currentApp = "/main/overview-view";
    // }
  }


}
