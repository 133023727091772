import { Component, OnInit, Inject, OnChanges, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { FormDataService } from 'src/app/shared-generic/services/form-data.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import { TextService } from '../../services/text.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit, OnDestroy {
  
  private subscriptions: Dictionary<Subscription> = new Dictionary();
  public entityId: string;
  public okEnabled: boolean = false;
  private cancel_title: string;
  public updateDataCheckInfoCallbackId = 'dataCheckInfoFormDialog';
  public dataCheckInfo:string = "";
  
  constructor(
    private dialogService: DialogService,
    public messageService: MessageService,
    private formDataService: FormDataService,
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    // required: title, name, backend, form
    private projectSetupService: ProjectSetupService,
    private textService: TextService
  ) { }

  ngOnInit(): void {
    // NOTE: init input_values for everything what is user input
    this.data["input_values"] = {};
    this.cancel_title = this.textService.cancel_title[this.projectSetupService.language];
    if (! this.data.text_cancel_button) {
      this.data.text_cancel_button = this.cancel_title;
    }
    if (this.data.create_new) {
      this.entityId = 'create-new';
    } else {
      const callbackId = 'currentKey';
      this.subscriptions.add(callbackId,
        this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === callbackId))
            .subscribe(message => {
                this.entityId = message.args.current_key;
            }
        )); 
      const msg = {
          name: 'getCurrentFormKey',
          args: [this.data.form, callbackId]
      };
      this.messageService.sendMsg(msg, this.data.backend);

    }
    this.subscriptions.add(this.data.form,
      this.formDataService.formValidSubject.pipe(filter(item => item.form === this.data.form))
        .subscribe(item => {
            this.okEnabled = item.status;
      })
    );

    this.subscriptions.add(this.updateDataCheckInfoCallbackId,
      this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === this.updateDataCheckInfoCallbackId))
          .subscribe(message => {
              this.dataCheckInfo = message.args.dataCheckInfo;
          }
    ));

  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatusEntity(this.data.form_dialog_name, false, this.entityId);
  }

  onOk() {
    if (this.data.create_new) {
      if (!this.subscriptions.containsKey(this.data.form_dialog_name)){
        const subs =
          this.messageService.awaitMessage(this.data.backend).pipe(filter(msg => msg.name ===  this.data.form_dialog_name))
          .subscribe(
            msg => {
              if (msg.args.approved) {
                const out = {
                  name: 'finallySendFormDialogData',
                  args: [this.data],
                };
                this.messageService.sendMsg(out, this.data.backend);
                this.dialogRef.close(this.data);
                this.dialogService.dialogStatusEntity(this.data.form_dialog_name, true, this.entityId);
              } else {
                this.dataCheckInfo = msg.args.problem_message;
              }
          });
          this.subscriptions.add(this.data.form_dialog_name, subs);
      }
      const msg = {
        name: 'preCheckFormDialogData',
        args: [this.data, this.data.form_dialog_name],
      };
      this.messageService.sendMsg(msg, this.data.backend);
    } else {
      this.dialogRef.close(this.data);
      this.dialogService.dialogStatusEntity(this.data.form_dialog_name, true, this.entityId);
    }
  }

  genericData(event) {
    for (const k of Object.keys(event)) {
      this.data["input_values"][k] = event[k];
    }
  }

  evalDisable() {
    if (this.data.disableOkBasedOndataCheckInfo) {
      return !this.okEnabled || this.dataCheckInfo.length>0;
    } else {
      return !this.okEnabled;
    }
  }

  ngOnDestroy() {
    this.subscriptions.keys().forEach(callbackId =>
       this.subscriptions.item(callbackId).unsubscribe())
  }

}
