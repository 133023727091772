<section (click)="selectMenuItem()" 
          [ngClass]="{
            'bg-vistrace-quinary': isSelected,
            'text-white': isSelected}"
          class="w-full menu-item flex text-gray-600 text-xl cursor-pointer">
  <div class="w-1/3 h-full flex justify-center items-center">
    <div [ngClass]="{' bg-vistrace-primary ': isBlue, 'bg-white': !isBlue}" class="h-10 w-10 flex justify-center items-center border-2 border-vistrace-primary rounded-full focus:outline-none">
      <img *ngIf="isBlue" class="w-3/4 h-3/4" src="assets/check-mark.svg">
    </div>
  </div>
  <div class="w-2/3 h-full flex items-center ml-2">
    <span>{{ title }}</span>
  </div>
</section>