import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy, HostListener, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { BokehService } from '../../services/bokeh.service';
import { DropService } from '../../services/drop-service';


@Component({
  selector: 'app-chart-data',
  templateUrl: './chart-data.component.html',
  styleUrls: ['./chart-data.component.scss']
})
export class ChartDataComponent implements OnInit, OnDestroy {
  @Input() chartId = 'default';
  @Input() col = 'col1';
  @Input() backend = 'offer-creator';
  @Input() headerless: boolean = false;
  @Input() noicon: boolean = false;
  @Input() frozen: boolean = false;
  @Output() action = new EventEmitter<string>();
  @Input() replot = '';
  @Input() updateAll ='';
  @Input() height = "450";
  public chartItem = {};
  public subs: Subscription[] = [];
  public isSelected = false;
  public isSelectable = true;
  public hover = false;
  public chartTitle = '';
  public chartSubTitle = '';
  public chartLoaded: boolean = false;
  public startUpDone: boolean = false;


  constructor(private bokehService: BokehService,
              private messageService: MessageService,
              private dropService: DropService,
  ) { }


  ngOnInit() {
    // either get one as input context or build a unique one here
    if (this.chartId === 'default') {
      this.chartId = String(this.messageService.getRandomInt(900000) + 100000);
    }
    this.subs.push(this.bokehService.getChartItem(this.chartId, this.backend).pipe(filter(msg => msg.args.chartId === this.chartId))
      .subscribe(
        msg => {
          if (!msg.args.chartItem) {
            return;
          }
          //this.chartLoaded = true;
          this.chartItem = msg.args.chartItem;
          this.chartTitle = msg.args.chartTitle;
          this.chartSubTitle = msg.args.chartSubTitle;
          // console.log("backend chart --> ", this.backend, this.chartItem);
          this.chartLoaded = this.bokehService.plot(this.chartId, this.chartItem, this.backend);
          setTimeout( ()=> {this.startUpDone=true}, 250);
        }
      ));
  }

  cardEnter($event) {
    this.hover = true;
    this.dropService.hoverId = this.chartId;
  }

  cardLeave($event) {
    this.hover = false;
  }

  onDelete() {
    this.action.emit('delete ' + this.col + ' ' + this.chartId);
  }

  onClick($event) {
    console.log($event, this.isSelected);
    if (this.isSelectable) {
      this.isSelected = !this.isSelected;
    }
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }
  
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      console.log("PROP", propName, this.replot);
      if(propName === "replot"  && this.chartId !== 'default' && this.replot != '') {
        console.log("replot ChartDataComponent", this.chartId, this.chartItem, this.chartLoaded);
        if (!this.startUpDone) {
          // best solution up to now to skip first incoming replot after startup is done
          // replot comes in with the first selection of the table line, which is triggered
          // by the backend
          return;
        }
        if (!this.chartLoaded) {
            this.bokehService.restartChart(this.chartId, this.backend);
        } else {
            this.bokehService.replot(this.chartId, this.backend);
        }
      }else if(propName == "updateAll" && this.updateAll != ''){
        this.bokehService.restartChart(this.chartId, this.backend);
      }
    }
  }

}
