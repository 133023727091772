<!-- Removed for now as this filter does not work -->
<!-- <div class="button-top" *ngIf="onFilter && onButton">
  <button mat-raised-button (click)="toggleFilter()">Filter</button>
</div> -->
<div class="button-top" *ngIf="onEditor && onButton">
  <!-- <button mat-raised-button
          (click)="openEditor()"
          [disabled]="editorDisabled">Editor
  </button> -->
  <app-styled-button
    [colorStyle]="'brand'"
    [title]="'Test'"
    [isFilled]="false"
    (action)="openEditor()"
    [width]="'150'"
    [disabled]="editorDisabled"
    [textToolTipDisable]="'Select a table row to edit'">
  </app-styled-button>

</div>
<div class="top-row"> 
  <mat-form-field class="item-top-row" *ngIf="onFilter">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>
  <mat-form-field style="margin-left: 5px;" class="item-top-row" *ngIf="onFilterColumns">
    <mat-select placeholder="Suchspalte" (selectionChange)="narrowFilter($event)">
      <mat-option *ngFor="let col of initializedSearchColumns" [value]="col['id']">
        {{ col['title'] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Removed for now as this filter does not work -->
  <!-- <div class="item-top-row" *ngIf="onFilter && onTopButton">
    <button mat-raised-button (click)="toggleFilter()">Filter</button>
  </div> -->
  <div class="item-top-row" *ngIf="onEditor && onTopButton">
    <!-- <button mat-raised-button
            (click)="openEditor()"
            [disabled]="editorDisabled">Editor</button> -->
            <app-styled-button
              [colorStyle]="'brand'"
              [title]="'Editor'"
              [isFilled]="false"
              (action)="openEditor()"
              [width]="'150'"
              [disabled]="editorDisabled"
              [textToolTipDisable]="'Select a table row to edit'">
            </app-styled-button>
  </div>
</div>


<div *ngIf="title" class="subtitle">
  {{title}}
</div>
<div class="edit-table-row" [ngClass]="page_size?'':'scroll-sticky'">
  <img class="main-img" *ngIf="main_img" width="117" height="105" [src]="getSanitizedUrl(main_img)" />

  <section [ngStyle]="{'max-height': tableHeight+'px', 'overflow':'scroll', 'width': '100%'}">
    <table mat-table [dataSource]="tableDataList" matSort class="table-border" [ngClass]="page_size?'':'scroll-sticky-header'">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
      <!-- Name Column -->
      <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col['id']">
        <!-- could depend on each column -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="col['tooltip']" [disabled]="disableSort" [arrowPosition]="'before'"
        [style.text-align]="col['text-align']" [style.width.px]="col['width']" [ngClass]="[disableSort?'':'sort', col['arrow']?col['arrow']:'', col['text-align']?col['text-align']:'center']">
          <div [ngStyle]="{'flex-grow': '1','display': 'flex', 'flex-direction': 'row', 
           'justify-content': col['text-align'] ? col['text-align']:'center', 'align-items': 'center',
           'text-align': col['text-align'] ? col['text-align']:'center'}">
            {{col['title']}}
            <div *ngIf="col['tooltip'] || col['tooltip_img']" class="tooltip" [ngStyle]="{'padding-top': '3px'}">
              <mat-icon class="info-icon" svgIcon="icon_info"></mat-icon>
              <span *ngIf="col['tooltip_img']"><img class="tooltip-img" [src]="col['tooltip_img']"></span>
            </div>
          </div> 
        </th> 
        <!--th mat-header-cell class="header" *matHeaderCellDef mat-sort-header> 
            {{col['title']}}
          </th-->
        <td mat-cell *matCellDef="let element" 
                [style.text-align]="col['text-align']" 
                [style.width.px]="col['width']"
                [ngStyle]="{'word-break': 'break-word'}"
                id="cell"> 
          <div *ngIf="col['id']!=='download_link' && col['id']!=='img_link' && col['id']!=='warning_icon' && col['id']!=='status_circle' && col['id']!=='mat_icon' && col['id']!=='display_color'" >
            {{ element[col['id']] | cell: col['pipe'] }}
          </div>
          <div *ngIf="col['id']=='warning_icon' && element[col['id']]">
            <mat-icon [ngStyle]="{'color': element[col['id']]}" [matTooltip]="element['warning_message']">report_gmailerrorred</mat-icon>
          </div>
          <!-- draw circle in specified color and center it -->
          <div *ngIf="col['id']=='status_circle' && element[col['id']]">
            <div [ngStyle]="{'background-color': element[col['id']], 'height': '10px', 'width': '10px', 'border-radius': '50%', 'margin-left': 'auto', 'margin-right': 'auto'}"></div>
          </div>
          <!-- show mat-icon if given in column -->
          <div *ngIf="col['id']=='mat_icon' && element[col['id']] && element[col['id']]['icon_color'] && element[col['id']]['icon_type']">
            <mat-icon [ngStyle]="{'color': element[col['id']]['icon_color']}">{{element[col['id']]['icon_type']}}</mat-icon>
          </div>
          <!-- <div *ngIf="col['id']=='img_str'" >
            <img width="117" height="105" class="table-img" [src]="getSanitizedUrl(element[col['id']])" />
          </div> -->
          <div *ngIf="col['id']=='img_link'" >
            <img width="117" height="105" class="table-img" [src]="element[col['id']]" />
          </div>
          <div *ngIf="col['id']=='download_link'" >
            <button mat-icon-button (click)="openFile(element[col['id']])">
              <mat-icon>get_app</mat-icon>
            </button>
          </div>
          <div *ngIf="col['id']=='display_color'" >
            <div [ngStyle]="{'background-color': element[col['id']], 'height': '20px', 'width': '20px', 'margin':'auto'}"></div>
          </div>
        </td>
      </ng-container>
    
      <ng-container *ngFor="let name of filterHeaders" [matColumnDef]="name">
          <th mat-header-cell class="header" *matHeaderCellDef> 
            <mat-form-field *ngIf="filters[name] !== undefined" class="filter" floatLabel="always">
              <mat-label>Search</mat-label>
              <input matInput [formControl]="filters[name]">
            </mat-form-field>
          </th>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="getDisplayColumnIds() ; sticky:true"></tr>
      <tr mat-header-row [ngClass]="{'showFilter': filtersOn, 'hideFilter': !filtersOn}" *matHeaderRowDef="filterHeaders"></tr>
      <!-- was before 221223: 'selected': this.selectedKey==row['key'] -->
      <tr mat-row [ngClass]="{'selected': selection.isSelected(row[row_key]), 'enabled': this.enabled}" *matRowDef="let row; columns: getDisplayColumnIds();"
        (click)="onRowClicked(row)">
      </tr>
    </table>
  </section>

<app-styled-button  *ngIf="onEditor && !onButton && !onTopButton"
  [colorStyle]="'brand'"
  [isFilled]="false"
  (action)="openEditor()"
  [width]="'60'"
  [disabled]="editorDisabled"
  [iconName]="'edit'">
</app-styled-button>
<!-- <div class="button-right">
    <button mat-icon-button [disabled]="editorDisabled" (click)="openEditor()" *ngIf="onEditor && !onButton && !onTopButton">
        <mat-icon>edit</mat-icon>
    </button>
</div> -->

</div>
<mat-paginator *ngIf="page_size" [pageSize]="page_size" showFirstLastButtons></mat-paginator>

