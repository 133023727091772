<div class="occupancy-wrapper">
<div class="flexible-occupancy-container" [ngStyle]="getOccupancyContainerStyle()">
    <!---first row/ header-->
    <div class="header" [ngStyle]="{width: rowWidth + 'px'}">
        <div class="subject"  [ngStyle]="getSubjectStyle()">
        </div>
        <div *ngFor="let day of days" class="day" [ngClass]="{isToday: day.isToday}" [ngStyle]="{'width': dayWidth + 'px'}"> {{day.display_label}}
        </div>    
    </div>
    <div [ngClass]="[scroll? 'scroll' : '']">
        <!---rows-->
        <div *ngFor="let subject of subjects" class="row" [ngStyle]="{height: rows_height.item(subject.subject_id) + 'px', width: rowWidth + 'px'}">
            <!---first layer in a row-->
            <div class="grid">
                <div class="subject" [ngStyle]="getSubjectStyle()">
                </div>
                <div *ngFor="let day of days" class="day" [ngClass]="{isToday: day.isToday}" [ngStyle]="{'width': dayWidth + 'px'}">
                </div>
            </div>
            <!---second layer in a row-->
            <div class="tasks">
                <div class="subject" [ngStyle]="getSubjectStyle()">
                    <div *ngFor="let subject_label of subject.display_label" [ngStyle]="{'white-space': 'pre-line'}">
                        {{subject_label}}
                    </div>
                </div>
                <div class="tasks-container" [ngStyle]="{width: daysWidth + 'px'}">
                    <div class="day-part" (click)="selectTask(task.task_id)" [ngClass]="[task.type, task.isSelected ? 'isSelected' : '',displayIcon ? 'displayIcon' : '', task.subType ]" [ngStyle]="{left: task.task_left + 'px', top: task.task_top + 'px', 'width': taskWidth + 'px', height: rowHeight + 'px'}" *ngFor="let task of tasks_data.item(subject.subject_id)">
                        <div *ngFor="let label of task.display_label" [title] ="label" [ngStyle]="{'white-space': 'nowrap', 'text-overflow' : 'ellipsis', 'overflow' : 'hidden'}">{{label}}</div>
                        <mat-icon *ngIf="task.icon"  [matTooltip]="task.iconTooltip" svgIcon="{{task.icon}}" class="status-icon"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>