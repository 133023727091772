<div class="flex h-screen justify-center items-center">
    <div class="p-8 rounded shadow-md w-80">
        <h2 class="text-2xl font-semibold mb-4">RECOVER PASSWORD</h2>
        <div class="mb-4">
            <label for="username" class="block text-sm font-medium text-gray-700">Password</label>
            <input type="password" [(ngModel)]="newPassword" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
            <label for="password" class="block text-sm font-medium text-gray-700">Retype Password</label>
            <input type="password" [(ngModel)]="confirmPassword" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
        </div>
        <!--div class="flex flex-col items-center justify-between mb-4">
            <a class="text-sm text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Forgot your password?</a>
        </div-->
        <button (click)="onSubmit()" type="submit" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-blue-600 ">Send</button>
        <!--div class="mt-4 text-center">
            <a (click)="toRegister()" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Register for free</a><br>
            <a class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Privacy</a>
        </div-->
    </div>
</div>
