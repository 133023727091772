import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { StripeService } from '../../services/stripe-service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'src/app/shared-generic/services/message.service';

const style = {
  base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
          color: '#aab7c4',
      },
  },
  invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-customer-payments-profile-component',
  templateUrl: './customer-payments-profile.component.html',
  styleUrls: ['./customer-payments-profile.component.scss']
})
export class CustomerPaymentsProfileComponent implements OnInit {
  cardErrors: any;
  cardElement: any;
  cardholderName = "";
  loading = false;
  private stripe: Stripe | null = null;
  constructor(
    private stripeService: StripeService,
    private messageService: MessageService
    ) {}

  
  async ngOnInit() {
    this.stripe = this.stripeService.getStripe();
    
    console.log(this.stripe);
    
    if (this.stripe) {
      const elements = this.stripe.elements({
        locale: 'en' // Specify English locale
      });
      this.cardElement = elements.create('card', {style, hidePostalCode: true, });
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (event: any) => {
        this.cardErrors = event.error ? event.error.message : '';
      });
    }
  } 

  /*
  invalidError: any;
  cardDetailsFilledOut: any;
  cardCaptureReady = false

  onStripeInvalid( error: Error ){
    console.log('Validation Error', error)
  }

  onStripeError( error: Error ){
    console.error('Stripe error', error)
  }

  
  setPaymentMethod( token: stripe.paymentMethod.PaymentMethod ){
    console.log('Stripe Payment Method', token)
 
  }
  */



  async onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.loading = true;
    const { cardholderName } = form.value;

    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        name: cardholderName,
      },
    });
    this.loading = false;

    if (error) {
      this.cardErrors = error.message;
    } else {
      this.handlePaymentMethod(paymentMethod);
    }
  }

  handlePaymentMethod(paymentMethod: any) {
    // Handle the payment method ID as needed (e.g., send it to your server)
    console.log('Payment method:', paymentMethod);
    
    const msg = {
      name: 'handlePaymentMethod',
      args: [paymentMethod]
    };
    this.messageService.sendMsg(msg, 'main');
  }
}