import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  @Input() file_name: string;
  public data: any;
  private subscriptions: Array<Subscription> = [];
  //data_mat_subj = new Subject<any>();
  constructor(private layoutService: LayoutService,
              private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.http.get(this.file_name).subscribe((res: any,) => {
      //this.data_mat_subj.next(res);
      this.data = res;
    }, err => console.log("ERROR: path to import file:" + this.file_name + " does not exist"));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}