<div [ngStyle]="{'font-size.px':titlesize}" class="subtitle">{{title}}</div>
<div class=form-border [ngClass]="{'border': boundingBox, 'background': backgroundColor, 'formSpacing': formSpacing}" [ngStyle]="{'font-size.px':fontsize}">
    
  <form *ngIf="show" class="form" [formGroup]="formGroup" [ngStyle]="{'justify-content': justifyContent, 'padding': '0 ' + formPadding +'px'}">
  
  <div class="form-column" 
    [ngStyle]="{'margin-left.px': spacer, 'margin-right.px': spacer}"
    [ngClass]="{'info-input': infoForm, 'partial-info-input': 'partialInfoForm'}" *ngFor="let column of formFieldsList">
    <div *ngFor="let formField of column" style="display: flex; flex-direction: row; align-items: center;">
      <mat-form-field [style.width]="formField.width" [ngStyle]="formField.style" [ngSwitch]="formField.type_of_field" 
        [ngClass]="[justifyContent=='flex-start'?'flex-start-margin':'', formField.type_of_field.startsWith('fileUpload') ? 'disable-underline' : '', checkForArray(formGroup.get(formField.column_name).value) && formGroup.get(formField.column_name).value.length > 5? 'no-padding': '']">
        <!-- Extend label with tooltip and icon -->
        <div [ngStyle]="{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center'}">
          <label class="generic-form-label">{{formField.name}}<div *ngIf="formField.required"> *</div></label>
          <div *ngIf="formField.tooltip || formField.tooltip_img" [matTooltip]="formField.tooltip" [matTooltipPosition]="'above'"  class="tooltip">
            <mat-icon svgIcon="icon_info" class="info-icon"></mat-icon>
            <span *ngIf="formField.tooltip_img"><img [src]="formField.tooltip_img"></span>
          </div>
        </div>
        <!---text or generate_password-->
        <div *ngSwitchCase="formField.type_of_field === 'textInput' || formField.type_of_field === 'generate_password' ? formField.type_of_field : ''" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <input autocomplete="off" matInput
            id={{entity+formField.column_name}}
            (focus)="onFocus(entity+formField.column_name)"
            [formControlName]="formField.column_name"
            [placeholder]="formField.placeholder"
            [required]="formField.required"
            [type]="password ? 'password' : 'text'"
            appInvalidCharacters [invalidCharacters]="formField.invalid_characters"
            (pastedTextReplaced)="setReplacedText($event, formField.column_name)">
            <div class="unit" *ngIf="formField.unit">
              {{formField.unit}}
            </div>
        </div>
        <div *ngSwitchCase="'passwordInput'">
          <input autocomplete="off" matInput
            id={{entity+formField.column_name}}
            (focus)="onFocus(entity+formField.column_name)"
            [formControlName]="formField.column_name"
            [placeholder]="formField.placeholder"
            [required]="formField.required"
            [type]="'password'"
            appInvalidCharacters [invalidCharacters]="formField.invalid_characters"
            (pastedTextReplaced)="setReplacedText($event, formField.column_name)">
            <div class="unit" *ngIf="formField.unit">
              {{formField.unit}}
            </div>
        </div>
        <!-- color -->
        <div *ngSwitchCase="'colorInput'" class="color">
          <input matInput [ngxMatColorPicker]="picker" [formControlName]="formField.column_name">
          <ngx-mat-color-toggle matSuffix [for]="picker" 
            [ngStyle]="{'position':'absolute', 'right':'0', 'top':'15px', 'color': formGroup.get(formField.column_name).value}"></ngx-mat-color-toggle>
          <ngx-mat-color-picker #picker></ngx-mat-color-picker>
        </div>
        <!---date-->
        <div *ngSwitchCase="'datePicker'" class="date">
          <input matInput
              id={{entity+formField.column_name}}
              (focus)="onFocus(entity+formField.column_name)" 
              [matDatepicker]="picker" 
              [formControlName]="formField.column_name" 
              [placeholder]="formField.placeholder" 
              [required]="formField.required"
              (keydown)="disableInput($event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
        </div>
        <!---date and time -->
        <div *ngSwitchCase="'dateAndTimePicker'" class="date-and-time">
          <input matInput type="datetime-local"
              id={{entity+formField.column_name}}
              (focus)="onFocus(entity+formField.column_name)"
              [formControlName]="formField.column_name" 
              [placeholder]="formField.placeholder" 
              [required]="formField.required"
              (keydown)="disableInput($event)">
          <!---
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [showSeconds]="false" [disableMinute]="true" [enableMeridian]="true"></ngx-mat-datetime-picker>
          -->
        </div>
        <!---number-->
        <!---check: max does not work for type=number-->
        <div *ngSwitchCase="'numberInput'" class="input-no-arrows">
          <input autocomplete="off" matInput
            id={{entity+formField.column_name}}
            (focus)="onFocus(entity+formField.column_name)"
            [formControlName]="formField.column_name" type="number" 
            [placeholder]="formField.placeholder" 
            [required]="formField.required">
            <!-- Make use of math-ml in future as second option -->
            <!-- <app-math-ml *ngIf="formField.unit" [mathml]="formField.unit"></app-math-ml> -->
            <div class="unit" *ngIf="formField.unit">
              {{formField.unit}}
            </div>
        </div>
        <!---number with arrows
        <input *ngSwitchCase="'numberInputArrows'" matInput
          [formControlName]="formField.column_name" type="number" [placeholder]="formField.placeholder" [required]="formField.required"
          [attr.max]="formField.number_of_characters">
        -->
        <!---textarea-->
        <textarea *ngSwitchCase="'textArea'" [ngStyle]="{'display': 'flex', 'flex-direction': 'column', 'width': '100%'}" matInput
          id={{entity+formField.column_name}}
          [formControlName]="formField.column_name" [rows]="formField.height" [placeholder]="formField.placeholder" [required]="formField.required"
          appInvalidCharacters [invalidCharacters]="formField.invalid_characters" (pastedTextReplaced)="setReplacedText($event, formField.column_name)"></textarea>
        <!---drop-down-->
        <div *ngSwitchCase="'selectPicker'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <mat-select id={{entity+formField.column_name}} 
              (focus)="onFocus(entity+formField.column_name)"
              [formControlName]="formField.column_name"
              [required]="formField.required">
            
            <mat-option *ngFor="let option of selectPickerOptions.item(formField.column_name)" 
              [value]="checkOption(option) ? option.value : option">{{checkOption(option) ? option.label : option}}</mat-option>
          </mat-select>
          <div class="unit" *ngIf="formField.unit">
            {{formField.unit}}
          </div>
        </div>
        <!---drop-down with search option-->
        <div *ngSwitchCase="'selectPickerSearch'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <mat-select id={{entity+formField.column_name}} 
              (focus)="onFocus(entity+formField.column_name)"
              [formControlName]="formField.column_name"
              [required]="formField.required">
            <mat-option>
              <ngx-mat-select-search
              [formControlName]="'matSelectSearch'+formField.column_name">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions.item(formField.column_name) | async" 
              [value]="option">{{option}}</mat-option>
          </mat-select>
          <div class="unit" *ngIf="formField.unit">
            {{formField.unit}}
          </div>
        </div>
  
        <div *ngSwitchCase="'autoComplete'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <input autocomplete="off" matInput type="text"
                      id={{entity+formField.column_name}}
                      (focus)="onFocus(entity+formField.column_name)" 
                      [placeholder]="formField.placeholder" 
                      [required]="formField.required"
                      [formControlName]="formField.column_name"
                      [matAutocomplete]="auto"
                      appInvalidCharacters 
                      [invalidCharacters]="formField.invalid_characters" 
                      (pastedTextReplaced)="setReplacedText($event, formField.column_name)">
          <div class="unit" *ngIf="formField.unit">
            {{formField.unit}}
          </div>
          <button *ngIf="formField.delete_button" style="background-color: rgb(244, 179, 179); border-radius: 50%; height: 20px; width: auto; 
                        border: none; position: relative; margin-top: -2px; margin-left: 20px;" matTooltip="Delete" (click)="deleteInput(formField.column_name)" class="del-file-icon">
                  x
          </button>
          <!-- moved mat-autocomplete within *ngSwitchCase="'autoComplete'" -->
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions.item(formField.column_name) | async" [value]="option">
              {{option}}
              </mat-option>
          </mat-autocomplete>
        </div>

        <div *ngSwitchCase="'listInput'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <input autocomplete="off" matInput type="text"
                      id={{entity+formField.column_name}}
                      (focus)="onFocus(entity+formField.column_name)" 
                      [placeholder]="formField.placeholder" 
                      [required]="formField.required"
                      [formControlName]="formField.column_name"
                      [matAutocomplete]="auto"
                      appInvalidCharacters 
                      [invalidCharacters]="formField.invalid_characters" 
                      (pastedTextReplaced)="setReplacedText($event, formField.column_name)"/>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions.item(formField.column_name) | async" [value]="option">
            {{option}}
            </mat-option>
          </mat-autocomplete>
          <!--button style="background-color: rgb(244, 179, 179); border-radius: 50%; height: 20px; width: 20px; 
            border: none; position: relative; margin-top: -2px; margin-left: 20px;" matTooltip="Add To List" (click)="addToList(formField.column_name)" class="del-file-icon">
            +
          </button-->
        </div>

        <div *ngSwitchCase="'listInputInt'" class="input-no-arrows">
          <input autocomplete="off" matInput type="number"
                      id={{entity+formField.column_name}}
                      (focus)="onFocus(entity+formField.column_name)" 
                      [placeholder]="formField.placeholder" 
                      [required]="formField.required"
                      [formControlName]="formField.column_name"
                      [matAutocomplete]="auto"
                      appInvalidCharacters 
                      [invalidCharacters]="formField.invalid_characters" 
                      (pastedTextReplaced)="setReplacedText($event, formField.column_name)"/>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions.item(formField.column_name) | async" [value]="option">
            {{option}}
            </mat-option>
          </mat-autocomplete>
          <!--button style="background-color: rgb(244, 179, 179); border-radius: 50%; height: 20px; width: 20px; 
            border: none; position: relative; margin-top: -2px; margin-left: 20px;" matTooltip="Add To List" (click)="addToList(formField.column_name)" class="del-file-icon">
            +
          </button-->
        </div>

        <!-- file upload -->
        <div class="file-upload-area" *ngSwitchCase="'fileUpload'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <div style="display:flex; overflow-y: auto; height: 45px">
            <div *ngFor="let file of formGroup.get(formField.column_name).value">
              <div [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}" class="button-and-clear">
                <app-styled-button style="font-size: 16px;"
                  [textToolTip]="file"
                  [colorStyle]="'brand'"
                  [isFilled]="false"
                  (action)="openFile(file)"
                  [width]="'40'"
                  [height]="'26'"
                  [iconName]="'insert_drive_file'">
                </app-styled-button>
                <!-- <button mat-stroked-button color="primary" (click)="openFile(file)" class="file-button">
                  <mat-icon [matTooltip]="file">insert_drive_file</mat-icon>
                </button> -->
                <button style="background-color: white; border-radius: 50%; height: 10px; width: auto; 
                        border: none; position: absolute; margin-top: -2px; margin-left: 120px;" (click)="removeFile(file, formField.column_name)" class="del-file-icon">
                  x
                </button>
              </div>
            </div>
          </div>
          <input #uploadFile matInput id={{entity+formField.column_name}} hidden="true" [attr.type]="'file'"  onclick="this.value=null" (change)="inputUploadChange($event, formField.column_name)" accept=".png, .jpg, .jpeg, .pdf"/>
          <app-styled-button class="file-icon-add"
            [textToolTip]="'Datei hinzufügen'"
            [colorStyle]="'brand'"
            [isFilled]="false"
            (action)="uploadFile.click()"
            [width]="'40'"
            [height]="'26'"
            [iconName]="'add'"
            [disabled]="infoForm">
          </app-styled-button>
        </div>

        <!--div class="file-upload-preview-area" *ngSwitchCase="'fileUploadPreview'" [ngStyle]="{'display': 'flex', 'flex-direction': 'row'}">
          <input #uploadFile matInput id={{entity+formField.column_name}} hidden="true" [attr.type]="'file'" onclick="this.value=null" (change)="inputUploadChange($event, formField.column_name)" accept=".png, .jpg, .jpeg, .pdf"/>
            <app-styled-button class="file-icon-add"
              [textToolTip]="'Datei hinzufügen'"
              [colorStyle]="'brand'"
              [isFilled]="false"
              (action)="uploadFile.click()"
              [width]="'40'"
              [height]="'26'"
              [iconName]="'add'"
              [disabled]="infoForm">
            </app-styled-button>
        </div-->

        <!-- <div *ngSwitchDefault>
          <input matInput id={{entity+formField.column_name}}>Type of field not implemented
        </div> -->
        <div *ngIf="formField.type_of_field=='fileUploadPreview'" [appDnd]='backend' (fileUploaded)="inputUploadChange($event, formField.column_name)">
          <input #uploadFile matInput id={{entity+formField.column_name}} hidden="true" [attr.type]="'file'" onclick="this.value=null" (change)="inputUploadChange($event, formField.column_name)" accept=".png, .jpg, .jpeg, .pdf"/>
          <div class="button-and-clear-preview preview-container" [ngStyle]="{'height': formField.img_height+'px', 'width': formField.img_width+'px'}"
              >
              <div *ngIf="!formField.upload_finished" (click)="uploadFile.click()">
                <img
                  src="assets/Picture_icon_BLACK.svg"
                  class="w-16 h-16 m-4 cursor-pointer"
                  alt="image"
                  style="opacity: 0.7;"
                />
              </div>
              <button *ngIf="formField.upload_finished" color="primary" (click)="removeFile(formGroup.get(formField.column_name).value, formField.column_name)" class="del-file-icon">
                <mat-icon>cancel</mat-icon>
              </button>  
            <img *ngIf="formField.upload_finished" [src]="formGroup.get(formField.column_name).value" [width]="formField.img_width" [height]="formField.img_height" />
          </div>
        </div>

      </mat-form-field>




      <div class="flex-row list-input-area" *ngIf="formField.type_of_field=='listInput' || formField.type_of_field=='listInputInt'">
          <!-- <button style="background-color: rgb(244, 179, 179); border-radius: 50%; height: 20px; width: 20px; 
                        border: none; position: relative; margin-top: -2px; margin-left: 20px;" matTooltip="Add To List" (click)="addToList(formField.column_name)" class="del-file-icon">
                  +
          </button> -->
          <!--div class="list-icon">
            <button mat-stroked-button color="primary" (click)="addToList(formField.column_name)" class="add-file-icon">
              <mat-icon style="width: auto;">chevron_right</mat-icon>
            </button>
          </div-->
          <app-styled-button class="file-icon-add" 
            [textToolTip]="formField.language==='EN' ? 'Add Value' : 'Wert hinzufügen'"
            [colorStyle]="'brand'"
            [isFilled]="false"
            (action)="addToList(formField.column_name, formField.type_of_field)"
            [width]="'40'"
            [height]="'26'" 
            [iconName]="'chevron_right'"
            [disabled]="infoForm">
          </app-styled-button>
          <div class="chip-list" [ngStyle]="formField.extra_style">
            <mat-chip-listbox >
              <mat-chip-option *ngFor="let item of listItems.item(formField.column_name)" selectable="false" color="primary" [removable]="true" (removed)="removeFromList(item, formField.column_name)">
                  {{item}}
                  <mat-icon *ngIf="!infoForm" matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
      </div>

      <div style="display: inline-block; justify-content: center; text-align: center; margin-top: 12px; margin-left: 5px;">
        <app-styled-button *ngIf="formField.disabled && formField.edit_disabled"
          [textToolTipDisable]="'Editieren'"
          [colorStyle]="'brand'"
          [isFilled]="false"
          (action)="openDisabledEditor(formField)"
          [width]="'60'"
          [iconName]="'edit'">
        </app-styled-button>
      </div>
      <div style="display: inline-block; justify-content: center; text-align: center; margin-top: 12px; margin-left: 5px;">
        <app-styled-button *ngIf="formField.type_of_field=='generate_password'"
          [textToolTip]="'Passwort Generator'"
          [colorStyle]="'brand'"
          [isFilled]="false"
          (action)="generatePassword(formField)"
          [width]="'60'"
          [svgIcon]="'new_password_generator'">
        </app-styled-button>
      </div>
    </div>
  </div>
</form>

<button [disabled]="infoForm" *ngIf="!frozen" mat-icon-button class="question-garbage" 
    (click)="onDeleteItem()">
    <mat-icon class="del-icon" color="primary" matTooltip="Löschen">clear</mat-icon>
</button>
</div>
