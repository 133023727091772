<div data-html2canvas-ignore="true" class="date-selector-wrapper">
    <mat-form-field class="form-field" [ngStyle]="{width: width + 'px'}">
        <mat-label>Von: </mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="value" (ngModelChange)="valueChangedAction($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [dateClass]="dateClass" #picker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
    </mat-form-field>
    <div class="weekly-switch" *ngIf="directionType=='week'">
      <button mat-icon-button (click)="changeWeek(-1)">
          <mat-icon>chevron_left</mat-icon>
      </button>
      <div>KW: {{week}}</div>
      <button mat-icon-button (click)="changeWeek(1)">
          <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <div class="monthly-switch" *ngIf="directionType=='month'">
      <button mat-icon-button (click)="changeMonth(-1)">
          <mat-icon>chevron_left</mat-icon>
      </button>
      <div>{{month}}</div>
      <button mat-icon-button (click)="changeMonth(1)">
          <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
</div>
<!-- alternative for html2canvas -->
<div class="showInCanvas" [style.display]="'none'">
  <span>{{'Von: ' + dateVisibleRender + ' KW: ' + week}}</span>
</div>