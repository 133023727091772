import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { OccupancyDataService } from './../../services/occupancy-data.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OccupancyTask } from './../../types/occupancy-task';
import { OccupancyDay } from './../../types/occupancy-day';
import { OccupancySubject } from './../../types/occupancy-subject';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
@Component({
  selector: 'app-occupancy-fixed-row',
  templateUrl: './occupancy-fixed-row.component.html',
  styleUrls: ['./occupancy-fixed-row.component.scss']
})
export class OccupancyFixedRowComponent implements OnInit, OnChanges, OnDestroy {

  /*
  TODO: show status in jobs

    +-------+--------+--------+---------+
    |       |   day  |   day  |   day   |
    +-------+--------+--------+---------+
    |       | ______                    |
    |       || task |                   |
    |subject||______|                   |
    |.......|...........................|..
    |       | _______________           | |
    |       || task          |          | | rowHeight
    |subject||_______________|          | |
    +-------+--------+--------+---------+--
            |_________daysWidth__________|
    |_________________rowWidth___________|

    xMargin - margin on both sides (left, right) of the calendar
    taskMargin - margin on all sides of a task
    calendarX - width of the component
  */
  @Input() backend = 'offer-creator';
  @Input() occupancyName: string;
  @Input() days_number: number = 7;
  @Input() updateTrigger = "0";
  @Input() startDate: string;
  @Input() scroll: boolean = true; // whether the component should scroll or not 
  // @Input() filterTrigger = "0";
  // @Input() sortTrigger = "0";
  @Input() selectTile = false;
  @Output() rowSelected = new EventEmitter<{status:boolean, subject_id: string}>();
  @Output() tileSelected = new EventEmitter<{status:boolean, task_id: string, task_type: string}>();
  @Output() resultCount = new EventEmitter<number>();;

  public subs: Subscription[] = [];
  public tasks: OccupancyTask[] = [];
  public days: OccupancyDay[] = [];
  public subjects: OccupancySubject[] = [];

  public calendarX = 1200;
  public scrollX = 21;
  public firstColumn = 150;
  public defaultRowHeight = 90;
  public xMargin = 0; //10;
  public display = false;
  public taskMargin = 5;
  public taskHeight;
  public rowWidth;
  public daysWidth;
  public dayWidth;

  public tasks_data = new Dictionary<any>();

  constructor(private occupancyDataService: OccupancyDataService) { }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }
    // calc css
   initialize() {
    this.taskHeight = this.defaultRowHeight - 2*this.taskMargin;
    this.rowWidth = this.calendarX - 2*this.xMargin;
    this.daysWidth = this.rowWidth - this.firstColumn;
    this.dayWidth = (this.calendarX - this.firstColumn - 2*this.xMargin)/this.days_number;
    // get data
    this.subs.push(this.occupancyDataService.getOccupancy(this.occupancyName, this.startDate,  this.days_number, this.backend).pipe(filter(msg => msg.args.occupancyName === this.occupancyName))
      .subscribe(
        msg => {
          var subjects = [];
          var tasks = [];
          var days = [];
          msg.args.tasks.forEach(task => {
            let occupancyTask = OccupancyTask.parseOccupancyTask(task);
            tasks.push(occupancyTask);
          });
          msg.args.days.forEach(day => {
            let occupancyDay = OccupancyDay.parseOccupancyDay(day);
            days.push(occupancyDay);
          });
          msg.args.subjects.forEach(subject => {
            let occupancySubject = OccupancySubject.parseOccupancySubject(subject);
            subjects.push(occupancySubject);
          });
          this.subjects = subjects;
          this.tasks = tasks;
          this.days = days;
          this.getTasks();
          this.display = true;
          this.resultCount.emit(this.subjects.length);
        }
      ));
   }
  

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            // if (propName === "updateTrigger") {
            //     this.subs.forEach( subs => subs.unsubscribe() );
            //     this.initialize();
            // }else if(propName === "startDate" || propName == "sortTrigger" || propName == "filterTrigger"){
            //   this.occupancyDataService.occupancyMessage(this.occupancyName, this.startDate, this.days_number, this.backend, "fixed changes");
            // }
            if((propName == "startDate" && !changes["startDate"].isFirstChange()) || (propName == "updateTrigger" && !changes["updateTrigger"].isFirstChange())){
              this.occupancyDataService.occupancyMessage(this.occupancyName, this.startDate, this.days_number, this.backend, "fixed changes");
            }
        }
    }

  public getTasks():void{
    /*
    return all tasks assigned to a subject
    */
    this.tasks_data.removeAll();
    this.tasks.forEach(task =>{
       task.task_left = (task.date_count * this.dayWidth) + this.taskMargin;
       task.task_width = (this.dayWidth * task.task_length) - 2*this.taskMargin;
       if(this.tasks_data.containsKey(task.subject_id)){
         this.tasks_data.item(task.subject_id).push(task);
       }else{
         this.tasks_data.add(task.subject_id, [task]);
       }
     });
  }

  public getOccupancyContainerStyle() {
    return {
      width: this.calendarX + this.scrollX + 'px',
      //'padding-top': '100px',
      'padding-left': this.xMargin + 'px',
      'padding-right': this.xMargin + 'px'}
  }

  public getSubjectStyle() {
    return {
      width: this.firstColumn + 'px',
      'max-width': this.firstColumn + 'px',}
  }

  public selectRow(subject_id: string) {
    /*
    select/diselect a task
    */
   if(!this.selectTile){
    this.subjects.forEach(subject => {
      if (subject.subject_id == subject_id && !subject.is_selected){
        subject.is_selected = true;
      }else {
        subject.is_selected = false;
      }
    });
    var clicked_subject = this.subjects.find(subject => subject.subject_id === subject_id);
    this.rowSelected.emit({status: clicked_subject.is_selected,subject_id: subject_id });
   }
  }

  public selectedTile(task_id: string){
    if(this.selectTile) {

      this.tasks.forEach(task => {
        if (task.task_id == task_id && !task.isSelected){
          task.isSelected = true;
        }else {
          task.isSelected = false;
        }
      });
      var clicked_task = this.tasks.find(task => task.task_id === task_id);
      this.tileSelected.emit({status: clicked_task.isSelected,task_id: clicked_task.task_id , task_type:clicked_task.type});
    }
  }

}
