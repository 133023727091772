<!--Popup window-->
<h3 mat-dialog-title>{{getEditorTitle()}}</h3>
<div mat-dialog-content class="login">
    <div class="doubleCol" *ngFor="let id of dataIds(); let i=index">
    <mat-form-field class="formField">
        <mat-label>{{getTitle(id)}}</mat-label>
        <!-- <input autocomplete="off" matInput *ngIf="textInputId(id)" [(ngModel)]="data.inputFields[id]" [placeholder]="getTitle(id)"> -->
        <input autocomplete="off" matInput *ngIf="textInputInitId(id)" (input)="onInput($event,id)" [placeholder]="getTitle(id)" [value]="data.initFields && data.initFields[id] ? data.initFields[id] : ''">
        <input autocomplete="off" matInput *ngIf="textInputId(id)" (input)="onInput($event,id)" [placeholder]="getTitle(id)" [value]="data.inputFields[id]">
        <mat-select *ngIf="!textInputId(id) && !textInputInitId(id)" [(ngModel)]="data.inputFields[id]" (selectionChange)="onSelect($event,id)">
            <mat-option *ngFor="let selectValue of getDropDownValues(id)" [value]="selectValue">
                {{selectValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="dataIds_C1[i]" class="formField">
            <mat-label>{{getTitle(dataIds_C1[i])}}</mat-label>
            <!-- <input autocomplete="off" matInput *ngIf="textInputId(dataIds_C1[i])" [(ngModel)]="data.inputFields[dataIds_C1[i]]" [placeholder]="getTitle(dataIds_C1[i])"> -->
            <input autocomplete="off" matInput *ngIf="textInputInitId(dataIds_C1[i])" (input)="onInput($event,dataIds_C1[i])" [placeholder]="getTitle(dataIds_C1[i])" [value]="data.initFields && data.initFields[dataIds_C1[i]] ? data.initFields[dataIds_C1[i]] : ''">
            <input autocomplete="off" matInput *ngIf="textInputId(dataIds_C1[i])" (input)="onInput($event,dataIds_C1[i])" [placeholder]="getTitle(dataIds_C1[i])" [value]="data.inputFields[dataIds_C1[i]]">
            <mat-select *ngIf="!textInputId(dataIds_C1[i]) && !textInputInitId(dataIds_C1[i])" [(ngModel)]="data.inputFields[dataIds_C1[i]]" (selectionChange)="onSelect($event,dataIds_C1[i])">
                <mat-option *ngFor="let selectValue of getDropDownValues(dataIds_C1[i])" [value]="selectValue">
                    {{selectValue}}
                </mat-option>
            </mat-select>
    </mat-form-field>
    </div>
</div>
<div class="warning-msg" *ngIf="data.warning_msg">{{data.warning_msg}}</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancel()">{{data.text_cancel_button}}</button>
    <button mat-button (click)="onOk()" [mat-dialog-close]="data">{{data.text_confirm_button}}</button>
</div>
