import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public software_name: string = 'VisTrace';
  public version_number: string = '0.0.1';
  public name_and_version_number: string = '';
  
  constructor() {
    this.name_and_version_number = this.software_name + ' ' + this.version_number;
  }
}
