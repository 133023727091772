<div
  class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] 2k:h-[800px] 2k:w-[415px] w-[350px] h-[650px] shadow-xl"
>
<div *ngIf="onLoading" style="position: absolute;">
  <mat-spinner></mat-spinner>
</div>
  <!-- placeholder -->
  <div
    *ngIf="!mobile_url || onLoading"
    class="rounded-[2rem] overflow-hidden w-full h-full bg-white dark:bg-gray-800"
  >
    <img
      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-light.png"
      class="dark:hidden w-full h-full"
      alt=""
    />
    <img
      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-dark.png"
      class="hidden dark:block w-full h-full"
      alt=""
    />
  </div>
  <!-- placeholder -->

  <iframe id="iframe_copy" class="w-full h-full rounded-xl" #iframe (load)="uploadDone()" style="position: absolute;"></iframe>
  <div
    class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"
  ></div>
  <div
    class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"
  ></div>
  <div
    class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"
  ></div>
  <div
    class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"
  ></div>
</div>
