
  <div class="flex flex-1 lg:px-[4%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full flex-shrink-0">
      <app-side-menu></app-side-menu>
    </aside>
    <!--h-[85vh] to be avoided!!!-->
    <div
      class="flex flex-1 flex-col items-center w-full h-full p-4"
    >
      <section class="flex flex-row justify-around w-full h-full">
        <app-chat-space class="w-[1000px]"
        [html]="component_data.html"
        [css]="component_data.css"
        [instance]="component_data.instance"
        [typescript]="component_data.typescript"
        [product_key]="product_key"
        ></app-chat-space>

        <div class="flex flex-col justify-around w-1/2">
          
          <app-mobile-preview [mobile_url]="mobile_url"></app-mobile-preview>
          <div class="flex justify-center mt-4">
            <!--button class="z-50 m-2 rounded-full bg-black text-white font-bold py-2 px-4"
              (click)="resetData()"
            >
              <mat-icon class="mt-1">autorenew</mat-icon>
            </button-->
            <button class="z-50 m-2 rounded-full bg-black text-white font-bold py-2 px-4"
              (click)="resetOneStep()"
            >
              <mat-icon class="mt-1">arrow_back_ios</mat-icon>
            </button>
            <button class="z-50 m-2 rounded-full bg-black text-white font-bold py-2 px-4"
              (click)="saveComponent()"
            >
              <mat-icon class="mt-1">save</mat-icon>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>

