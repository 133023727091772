import { Component, OnInit, Inject, Input, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogLoginService } from './../../services/dialog-login.service';
import { VersionService } from '../../../shared/services/version.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from './../../services/message.service';
import { CookieService } from 'ngx-cookie';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  public status: boolean = false;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  public subs: Subscription[] = [];
  public error_message: string = "";
  public nameStatus = false;
  public pwStatus = false;
  public pw_reset_request = false;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogLoginService: DialogLoginService,
    public messageService: MessageService,
    public versionService: VersionService,
    public httpClient: HttpClient,
    public cookieService: CookieService,
    private projectSetupService: ProjectSetupService
    ) { }

  ngOnInit() {
    this.init_controls();
    this.dialogLoginService.loginErrorSubject.subscribe((error: string) => {
      this.error_message = error;
    });
    this.dialogLoginService.closeSubject.subscribe(() => {
      // close better directly after actions
      this.dialogRef.close(this.data);
    });
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public data_send() {
    this.dialogLoginService.login(this.data.name, this.data.password);
    this.dialogRef.close(this.data);
  }

  public sign_up() {
    this.data["sign_up"] = true;
    this.dialogRef.close(this.data);
  }

  public anonymous() {
    const rand = this.messageService.getRandomInt(10000000).toString();
    this.data.name = "anonymous/"+rand;
    this.dialogRef.close(this.data)
  }

  public init_controls() {
    let group = {};
    group["name"] = new UntypedFormControl('',Validators.required);
    // console.log("INI", this.data);
    if (this.data.name?.length > 0) {
      if (!this.data.name.includes("anonymous")) {
        group["name"].value = this.data.name;
      }
    }
    if (this.data.name?.length > 0) {
      this.nameStatus = true;
    }
    this.subs.push(group["name"].valueChanges.subscribe( x => {
      this.data.name = x;
      if (this.data.name) {
        this.nameStatus = true;
      } else {
        this.nameStatus = false;
      }
    }));
    if (['simplePw', 'PwAndSignUp'].indexOf(this.dialogLoginService.loginMode)>=0) {
      group["password"] = new UntypedFormControl('',Validators.required);
      this.subs.push(group["password"].valueChanges.subscribe( x => {
        this.data.password = x;
        if (this.data.password) {
          this.pwStatus = true;
        } else {
          this.pwStatus = false;
        }
      }));

    }
    this.formGroup = new UntypedFormGroup(group);

    this.subs.push(this.formGroup.statusChanges.subscribe(val => {
      // action on status change
      // TODO: get answer from the server if it's a valid password
      this.status = this.formGroup.valid;
    }));
  }

  public send_email() {
    this.pw_reset_request = true;
    
    this.httpClient.post(this.messageService.rootUrl+'send_email_reset_pw' + `?user=${this.data.name}`,
     {}).subscribe(data => {
        // this.cookieService.put("addimap_user", this.data.name)        
        // do not close for AddiPlan
        if (this.projectSetupService.mode != "addiplan") {
          this.dialogRef.close(data);
          // this.router.navigateByUrl("/login");
        }
      });
    }
}
