import { Injectable } from '@angular/core';
import { ChatBotMessage } from '../types/chatbotMessages';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public onLoadingPreview = false;
  public messages: ChatBotMessage[] = [];
  public update = new Subject<any>();
  constructor(private messageService: MessageService) { 
    this.messageService.awaitMessage('main').pipe(filter(message => message.name === 'groqAnswer'))
    .subscribe(message => {
      this.messages.push(message.args.lastAnswer)
      
    });

    this.messageService.awaitMessage('main').pipe(filter(message => message.name === 'updatePreview'))
    .subscribe(message => {
      console.log("UPDATE preview");
      this.update.next("update");
    });
    
  }

  resetMessages() {
    this.messages = [];
  }
}
