import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from './message.service';
import { Subject } from 'rxjs';



@Injectable({
    providedIn: 'root',
  })
  export class FileDownloadService {
    public downloadStatus = new Subject();
    constructor(
        public messageService: MessageService,
        private httpClient: HttpClient) {}


    public downloadFile(url_total: any) {
        // console.log("download: ", url_total);
        this.httpClient.get(url_total, {responseType: 'blob'}).subscribe(
            response => {
                // let blob = new Blob([response], { type: 'application/pdf' });
                // const url = window.URL.createObjectURL(blob);
                window.open(url_total);
                /* use this for a new tab
                const newTab = document.createElement("a");
                newTab.href = url;
                newTab.target = '_blank';
                // Don't set download attribute
                // a.download = "Example.pdf";
                newTab.click();
                */
            }
        )
    }

    public downloadFileToDownloads(url_total: any, file_name: string) {
        // console.log("download to Downloads: ", url_total);
        this.httpClient.get(url_total, {responseType: 'blob'}).subscribe(
            response => {
                // save to Downloads;
                let blob = new Blob([response], { type: response.type });
                // console.log("response.type: ", response.type)
                const anchor = window.document.createElement('a');
                anchor.href = window.URL.createObjectURL(response);
                // console.log("anchor.href: ", anchor.href);
                anchor.download = file_name;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(anchor.href);
                const msg = {
                  name: 'deleteZipFile',
                  args: [url_total, file_name]
                };
                this.messageService.sendMsg(msg, "main");
                this.downloadStatus.next("successful");
            },
            error => {
                // console.log('oops', error);
                this.downloadStatus.next("unsuccessful");
            }
        )

        // const url = url_total+"?filename="+file_name;  
        // this.httpClient.get(url, { responseType: 'blob' }).subscribe(
        //   (response: any) => {
        //     console.log("response: ", response)
        //     let dataType = response.type;
        //     let binaryData = [];
        //     console.log("dataType: ", dataType)
        //     binaryData.push(response);
        //     let downloadLink = document.createElement('a');
        //     downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        //     if (file_name) {
        //       downloadLink.setAttribute('download', file_name);
        //     }
        //     document.body.appendChild(downloadLink);
        //     console.log("downloadLink: ", downloadLink)
        //     downloadLink.click();
        //   }
        // )
    }

}