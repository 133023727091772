import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalPreviewComponent } from '../../vistrace-components/modal-preview/modal-preview.component';

@Component({
  selector: 'app-product-info-selector',
  templateUrl: './product-info-selector.component.html',
  styleUrls: ['./product-info-selector.component.scss']
})
export class ProductInfoSelectorComponent implements OnInit {

  isModalOpen: boolean = false;

  constructor(private dialogRef : MatDialog) { }

  openDialog(){
    console.log('open dialog');
    this.dialogRef.open(ModalPreviewComponent);
  }

  ngOnInit(): void {
  }

}
