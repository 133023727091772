import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { FormDataService } from 'src/app/shared-generic/services/form-data.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import { TextService } from '../../../shared-generic/services/text.service';

const pw_length_check = "Das Passwort muss mindestens 8 Zeichen lang sein."

@Component({
  selector: 'app-reset-pw-admin',
  templateUrl: './reset-pw-admin.component.html',
  styleUrls: ['./reset-pw-admin.component.scss']
})
export class ResetPwAdminComponent implements OnInit {
  
  private subscriptions: Array<Subscription> = [];
  public entityId: string;
  public okEnabled: boolean = false;
  private cancel_title: string;
  public updateDataCheckInfoCallbackId = 'dataCheckInfoFormDialog';
  public dataCheckInfo:string = "";
  
  constructor(
    private dialogService: DialogService,
    public messageService: MessageService,
    private formDataService: FormDataService,
    public dialogRef: MatDialogRef<ResetPwAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    // required: title, name, backend, form
    private projectSetupService: ProjectSetupService,
    private textService: TextService
  ) { }

  ngOnInit(): void {
    this.cancel_title = this.textService.cancel_title[this.projectSetupService.language];
    if (! this.data.text_cancel_button) {
      this.data.text_cancel_button = this.cancel_title;
    }
    if (this.data.create_new) {
      this.entityId = 'create-new';
    } else {
      const callbackId = 'currentKey';
      this.subscriptions.push(
        this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === callbackId))
            .subscribe(message => {
                this.entityId = message.args.current_key;
            }
        )); 
      const msg = {
          name: 'getCurrentFormKey',
          args: [this.data.form, callbackId]
      };
      this.messageService.sendMsg(msg, this.data.backend);

    }
    this.subscriptions.push(
      this.formDataService.formValidSubject.pipe(filter(item => item.form === this.data.form))
        .subscribe(item => {
            this.okEnabled = item.status;
      })
    );

    this.subscriptions.push(
      this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === this.updateDataCheckInfoCallbackId))
          .subscribe(message => {
              this.dataCheckInfo = message.args.dataCheckInfo;
          }
    ));

  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatusEntity(this.data.name, false, this.entityId);
  }

  onOk() {
    this.dialogRef.close(this.data);
    this.dialogService.dialogStatusEntity(this.data.name, true, this.entityId);
  }

  genericData(event) {
    if (event['password']?.length < 8) {
      this.dataCheckInfo = pw_length_check;
    } else {
      this.dataCheckInfo = "";
    }
    for (const k of Object.keys(event)) {
      this.data[k] = event[k];
    }
  }

  evalDisable() {
    if (this.data.disableOkBasedOndataCheckInfo) {
      return !this.okEnabled || this.dataCheckInfo.length>0;
    } else {
      return !this.okEnabled;
    }
  }

}
