
<div class="header-top-infos">
  <div class="top-infos-left">
    <div class="top-info" *ngFor="let info of parameterCardHeader.topInfos">{{info}}</div>
  </div>
    
  <img src="assets/parameter-card.png" class="image">
</div>

<div class="header-bottom">
  <div class="header-bottom-info-left">{{parameterCardHeader.bottomInfoLeft}}</div>
  <div class="header-bottom-info-right">
    <span *ngIf="isLoggedIn">{{parameterCardHeader.bottomInfoRight}}</span>
  </div>
</div>

