import { OnInit, Component, Input, EventEmitter, Output,  QueryList, ViewChildren } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-styled-button',
  templateUrl: './styled-button.component.html',
  styleUrls: ['./styled-button.component.scss']
})
export class StyledButtonComponent implements OnInit {
  @ViewChildren(MatTooltip) tooltips: QueryList<MatTooltip>;

  @Input() title="";
  @Input() isFilled=true;
  @Input() disabled=false;
  @Input() colorStyle="action";    // can be action/brand/lightbrand/lightgrey for color selection
  @Input() key='';
  @Input() width: string;
  @Input() height="36";
  @Output() action = new EventEmitter<string>();
  @Input() textToolTip='';
  @Input() textToolTipDisable='';
  @Input() marginRight=0;
  @Input() iconName='';
  @Input() svgIcon='';
  @Input() shorten_title=0;
  

  constructor() { }

  ngOnInit() {

  }

  onClick() {
      this.action.emit(this.key);
  }


}

