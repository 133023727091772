
<div class="rounded shadow-lg mx-auto relative pb-4">


      <img class="h-40 w-full mb-2" src="assets/quote_of_the_day.jpg" alt="Card Image">
      <section class="h-[calc(100%-160px)] overflow-y-auto custom-scrollbar p-2">

        <div>
          <p><b>Quote of the day</b><p>
          <p style="white-space: pre-line" class="text-gray-700 text-base text-justify">
            {{quote}}
          </p>
        </div>
      </section>

</div>