<div class="top-action">
  <button mat-icon-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h3 mat-dialog-title class="dialog-title">{{data.title}}</h3>
<app-filter-panel 
  (sortTrigger)="setSortTrigger($event)" 
  [title]="''" 
  [backend]="data.backend" 
  [sort]="data.sort"
  [maxWidth]="data.filterMaxWidth" 
  [optionsMaxHeight]="''" 
  [filtersMaxHeight]="data.filtersMaxHeight" 
  [controller]="data.controller"></app-filter-panel>
<div mat-dialog-actions class="dialog-actions">
    <!-- <button mat-button (click)="onCancel()">Abbrechen</button> -->
    <button mat-button (click)="onOk()" [mat-dialog-close]="data" cdkFocusInitial>Close</button>
</div>