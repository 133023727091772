import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateRangeSelectorService } from '../../services/date-range-selector.service';
import { filter } from 'rxjs/operators';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import { HolidayService } from 'src/app/shared-generic/services/holiday.service';
import { DatePipe } from '@angular/common';
import { LayoutService } from 'src/app/shared-generic/services/layout.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import * as moment from 'moment';

@Component({  
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangeSelectorComponent implements OnInit {
  @Input() backend: string = "main";
  @Input() name: string;
  @Output() rangeChanged = new EventEmitter<{start: string, end: string}>();

  public subs: Subscription[] = [];
  campaignOne: UntypedFormGroup;
  public changedStartDate: string = "";
  public changedEndDate: string = "";
  public monthHolidays = [];
  datePipe = new DatePipe(this.projectSetupService.locale);
  public startVisibleRender: string = "";
  public endVisibleRender: string = "";

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      let date_month = cellDate.month()+1
      const date = cellDate.year()+'-'+date_month+'-'+cellDate.date();
      return (this.monthHolidays.includes(date) ) ? 'example-custom-date-class' : '';
    }

    return '';
  }

  constructor(
    private dateRangeSelectorService: DateRangeSelectorService,
    private holidayService: HolidayService,
    public layoutService: LayoutService,
    private projectSetupService: ProjectSetupService) {

    const today = moment();
    const month = today.month();
    const year = today.year();

    this.campaignOne = new UntypedFormGroup({
      // change start and end range from first jan in the year unitl today
      // start: new UntypedFormControl(new Date(new Date().getFullYear(), 0, 1)),
      // end: new UntypedFormControl(new Date(year, month, firstDay))
      start: new UntypedFormControl(moment([moment().year(), 0, 1])),
      end: new UntypedFormControl(moment([year, month, 1]))
    });
  }

  ngOnInit(): void {
    // init dates
    this.changedStartDate = this.convertDate(this.campaignOne.get("start").value);
    this.changedEndDate = this.convertDate(this.campaignOne.get("end").value);
    this.dateRangeSelectorService.setRangeMessage(this.changedStartDate, this.changedEndDate,this.name, this.backend);
    this.updateRenderingElements();

    this.subs.push(this.dateRangeSelectorService.triggerUpdate(this.name, this.backend).pipe(filter(msg => msg.args.name === this.name))
      .subscribe(
        msg => {
          this.rangeChanged.emit({start:this.changedStartDate, end:this.changedEndDate});
        }
    ));
    //code review @AGA
    // was: valueChanges.pipe(distinctUntilChanged()): fired twice without distinctUntilChanged
    this.subs.push(this.campaignOne.get("start").valueChanges.pipe().subscribe( x => {
      this.changedStartDate = this.convertDate(x);
    }));
    this.subs.push(this.campaignOne.get("end").valueChanges.pipe().subscribe( x => {
      this.changedEndDate = this.convertDate(x);
      this.updateRenderingElements();
      // once startDate is changed the endDate is set to null
      if (this.changedStartDate && this.changedEndDate){
        // emit range
        //this.rangeChanged.emit({start:this.changedStartDate, end:this.changedEndDate})
        this.dateRangeSelectorService.setRangeMessage(this.changedStartDate, this.changedEndDate,this.name, this.backend);
      }
    }));

    
    this.monthHolidays = this.holidayService.monthHolidays;
    this.subs.push(this.holidayService.getMonthHolidays().pipe()
    .subscribe(
      msg => {
        this.monthHolidays = msg.args.holidays;
      }
  ));
  }

  public convertDate(date: moment.Moment){
    var convertedDate = null;
    if (date){
      let dateDate = date.date();
      let dateMonth = date.month() + 1;
      let dateYear = date.year();
      convertedDate = dateYear+"-"+dateMonth+"-"+dateDate
    }
    return convertedDate
  }

  public monthChanged(event){
    console.log("MONTH CHANGED : ", event)
  }

  public updateRenderingElements() {
    this.startVisibleRender = this.datePipe.transform(this.changedStartDate, "shortDate");
    this.endVisibleRender = this.datePipe.transform(this.changedEndDate, "shortDate");
  }
}
