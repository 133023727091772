import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CodeInputComponent} from 'angular-code-input';

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
})
export class PinCodeComponent implements AfterViewInit {
  @Input() autoclose = true;
  @Output() pinCode = new EventEmitter<string>();

  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  constructor(
    public dialogRef: MatDialogRef<PinCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
    setTimeout(()=>{
      console.log("after view init...");
      this.codeInput.reset();
      this.codeInput.focusOnField(0); 
    }, 250);
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    //console.log("Your pin is: " + code)
    this.pinCode.emit(code); 
    this.data.pin = code;
    if (this.autoclose) {
      this.dialogRef.close(this.data);
    }
    setTimeout(()=>{
      this.codeInput.reset();
      this.codeInput.focusOnField(0); 
    }, 300);
    
  }
}

