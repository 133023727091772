


<div *ngFor="let id of chartFieldData[col]; let ii = index" [style.display]="visible_idx!=ii?'none':null" class="single-chart">  
  <app-chart-data 
    [replot]="replot" 
    [backend]="backend" 
    [frozen]="frozen"
    [headerless]="headerless"
    [noicon] = "noicon"
    [col]="col" 
    (action)="onAction($event)" 
    [chartId]="id">
  </app-chart-data>
</div>

<div class="chart-group">

<mat-tab-group headerPosition="below" style="margin-bottom:5px;" animationDuration="0" 
(selectedIndexChange)="visible_idx=$event"   mat-align-tabs="end">
<!-- you makes the mat-tabs "empty" -->
<mat-tab *ngFor="let id of chartFieldData[col]; let ii = index" [label]="titles[id]"></mat-tab>
</mat-tab-group>

</div>
<!--here you put your content using [style.display]="none" when 
the index is not equal to hte value (remember index begings by 0-->
<!--https://stackoverflow.com/questions/68129174/how-to-force-mat-tab-body-to-render-bind-html-element-for-inactive-tab-->

 