<nav class="bg-vistrace-primary h-20 p-3 text-white flex justify-between items-center">
  <div class="flex items-center lg:px-[10%]">
    <img
      (click)="toHome()"
      class="cursor-pointer"
      src="assets/Logo_VisTrace_white.png"
      alt="Logo"
      class="h-16 mr-2"
    />
  </div>
  <div class="flex items-center lg:px-[8%]" *ngIf="msgService.login">
    <div class="flex flex-row align-middle">
      <div class="circle text-center m-3">
        <span>{{sessionService.session_name}}</span>
      </div>
    <!--img
      src="assets/user_icon.svg"
      class="w-8 h-8 m-4 cursor-pointer"
      alt="lock"
    /-->
    </div>
    <img
      src="assets/logout.svg"
      class="w-8 h-8 m-4 cursor-pointer"
      alt="lock"
      (click)="logout()"
    />
  </div>
</nav>
