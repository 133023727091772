import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { FileDownloadService } from './file-download.service';
import { FileUploadService } from './file-upload.service';
import { MessageService } from './message.service';
//import domtoimage from 'dom-to-image-more';

@Injectable({
    providedIn: 'root',
  })
  export class HtmlRenderService {
    constructor(
        private fileUploadService: FileUploadService,
        private messageService: MessageService,
        private fileDownloadService: FileDownloadService,) {}


    public createCopy(capture_id:string, file_name:string, backend:string): void {
      //console.log("query: ", document.querySelector("#"+capture_id));
      //console.log("getElementById: ", document.getElementById(capture_id), capture_id);
      const iframe = document.getElementById(capture_id) as HTMLIFrameElement;
      const element = iframe.contentWindow.document.getElementById(capture_id+"_down"); 
      html2canvas(element, {
                  backgroundColor: 'transparent',
                  scale: 2,
                  }).then(canvas => {
        document.body.appendChild(canvas);
      
        /*
        var options = {
          quality: 0.99,
          width: 2000,
          height: 2000,
       };
        domtoimage.toBlob(element, options)
        .then((blob)=> {
            // convert blob to File
            const image_as_file: File = new File([blob], file_name, {
              //type: "image/svg+xml"
              type: "image/png"
            });
            this.fileUploadService.saveFileAll(image_as_file, backend, "api-main").subscribe(data => {
              let message = data.message;
              // console.log("png upload successfull!", message);
              //this.fileUploaded.emit(files);
            },
            error => {
              console.log(error);
            });
        });
        */
        // Convert the canvas to blob
        
        canvas.toBlob((blob)=>{
            const image_as_file: File = new File([blob], file_name, {
              type: "image/png"
            });
            this.fileUploadService.saveFileAll(image_as_file, backend, "api-main").subscribe(data => {
              const message = data.message;
              const restDataMsg = {
                name: 'saveComponent',
                args: [file_name]
              };
              this.messageService.sendMsg(restDataMsg, 'main');
            },
            error => {
              console.log(error);
            });
        
        });
      });
      
    }

    public createCanvas(capture_id:string, file_name:string, backend:string): void {
      // for id definition
      //console.log("query: ", document.querySelector("#"+capture_id));
      // for class definition
      console.log("query: ", document.querySelector("."+capture_id));
      console.log("getElementById: ", document.getElementById(capture_id), capture_id);
      html2canvas(document.querySelector("."+capture_id), {
                  // following line is solved via data-html2canvas-ignore="true" in html
                  // ignoreElements: function (el) {return (el.id === ignore_id);},//ignore_id is removed
                  // the following line is to ignore grey background appearing rendering (did not help, but was found in forum as solution)
                  // onclone: (clonedDoc)=> {if (clonedDoc.querySelector("."+"chart-tab-fixed")) {(clonedDoc.querySelector("."+"chart-tab-fixed") as HTMLElement).style.boxShadow ='none';}}
                  // post process cloned document
                  onclone: (clonedDoc)=> {
                    if (clonedDoc.querySelector("."+"showInCanvas")) {
                      (clonedDoc.querySelector("."+"showInCanvas") as HTMLElement).style.display='flex';
                    }
                    if (clonedDoc.querySelector("."+"showInCanvas2")) {
                      (clonedDoc.querySelector("."+"showInCanvas2") as HTMLElement).style.display='flex';
                    }
                  },
                  // scale removes the grey background areas, why?
                  scale: 1.3,
                  // backgroundColor: 'transparent',
                  }).then(canvas => {
        document.body.appendChild(canvas);
        // Convert the canvas to blob
        canvas.toBlob((blob)=>{
          // convert blob to File
            const image_as_file: File = new File([blob], file_name, {
              //type: "image/svg+xml"
              type: "image/png"
            });
            this.fileUploadService.saveFileAll(image_as_file, backend, "api-main").subscribe(data => {
              let message = data.message;
              // console.log("png upload successfull!", message);
              //this.fileUploaded.emit(files);
            },
            error => {
              console.log(error);
            });

            // open png in browser to see the size
            // setTimeout( ()=> {
            //   this.fileDownloadService.downloadFile("/documents/uploaded_files/" + backend + "/capture/" + file_name);
            // }, 500);            
        });//,'image/png'//default
      });
    }

}