<mat-form-field data-html2canvas-ignore="true" class="example-form-field" appearance="fill">
    <mat-label>Zeitraum</mat-label>
    <mat-date-range-input
      [formGroup]="campaignOne"
      [rangePicker]="campaignOnePicker">
      <input matStartDate placeholder="Start date" formControlName="start">
      <input matEndDate placeholder="End date" formControlName="end">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker (monthSelected)="monthChanged($event)" [dateClass]="dateClass" #campaignOnePicker></mat-date-range-picker>
</mat-form-field>
<!-- alternative for html2canvas -->
<div class="showInCanvas" [style.display]="'none'">
  <span>{{'Zeitraum: ' + startVisibleRender + ' - ' + endVisibleRender}}</span>
</div>
