import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { Message } from '../types/message';
import { MessageService } from './message.service';
import { filter } from 'rxjs/operators';


// TODO generalize the info service to be used for all apps

@Injectable({
    providedIn: 'root',
})
export class UiInfoService {

    private cadOpen: boolean = false;
    private snackBarRef: MatSnackBarRef<any>;
    public openSubj = new BehaviorSubject<boolean>(false);
    
    // public checkViewStatusSubject =  new Subject<string>();
    public imgSubject = new Subject<Message>();
    public isOpen = false;
    private regular: any;

    constructor(
        public snackBar: MatSnackBar,
        private msgService: MessageService,
    ) {
      this.openSubj.subscribe(
        (open) => this.isOpen = open
      );
      this.msgService
      .awaitMessage()
      .pipe(filter((msg) => msg.name === "reminderSnackbar"))
      .subscribe((msg) => {
        this.openSnackbar(msg.args.text, 4000, true);
      });
    }

    public getDefaultViews(): void {
      const callbackId = "navigateToView";
      const msg = {
          name: 'default_views',
          args: [callbackId],
          action: 'default'
      };
      this.msgService.sendMsg(msg);
  }

  public startRegularReminder(interval: number) {
    // interval in seconds
    // was: setInterval
    if (this.regular===undefined) {      
      this.regular = setTimeout(()=>{
        const msg = {
          name: "getRegularReminder",
          args: ["reminderSnackbar"]
        }
        this.msgService.sendMsg(msg);
      }, interval*1000);
    }
  }

  public stopReminder() {
    // clearInterval(this.regular);
    this.regular = undefined;
  }

  private playAudio() {
    // from https://mixkit.co/free-sound-effects/notification/
    let audio = new Audio();
    audio.src = "../assets/sound/alarm.wav";
    audio.load();
    audio.play();
  }

  public openSnackbar(msg: string, duration: number = 0, playAudio=false): void {
    if (playAudio) {
      this.playAudio();
    }
    let auto_close;
    if (duration) {
        auto_close = true;
    } else {
        auto_close = false;
    }
    let config = duration ?
        {
            duration: duration,
            data: { msg: msg, auto_close: auto_close },
        }
        : {
            data: { msg: msg, auto_close: auto_close },
        };
    this.snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, config);
  }
}
