// ng lib modules
import { Directive, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// materials
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips'; 
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { MatCarouselModule } from 'ng-mat-carousel';

// shared components and views
import { ExternalSourceDirective } from './directives/external-source.directive';
import { SafePipe } from './pipes/safe.pipe';
//import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DialogFilterComponent } from './components/dialog-filter/dialog-filter.component';
import { DateSelectorComponent, ExampleHeader } from './components/date-selector/date-selector.component';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { ConditionalFormDialogComponent } from './components/conditional-form-dialog/conditional-form-dialog.component';
import { CheckboxDialogComponent } from './components/checkbox-dialog/checkbox-dialog.component';

import { ChartDataComponent } from './components/chart-data/chart-data.component';
import { ChartDataFieldComponent } from './components/chart-data-field/chart-data-field.component';
import { OccupancyFixedRowComponent } from './components/occupancy-fixed-row/occupancy-fixed-row.component';
import { OccupancyFlexibleRowComponent } from './components/occupancy-flexible-row/occupancy-flexible-row.component';

import { HighlightDirective } from './directives/highlight.directive';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { FilterSelectionGroupComponent } from './components/filter-selection-group/filter-selection-group.component';
import { SimpleFilterComponent } from './components/simple-filter/simple-filter.component';
import { ChartCarouselComponent } from './components/chart-carousel/chart-carousel.component';
import { SortComponent } from './components/sort/sort.component';
import { MonthlyOverviewComponent } from './components/monthly-overview/monthly-overview.component';

import { SharedGenericModule } from '../shared-generic/shared-generic.module';
import { NavBarComponent } from './vistrace-components/nav-bar/nav-bar.component';
import { SideMenuComponent } from './vistrace-components/side-menu/side-menu.component';
import { WelcomeComponent } from './vistrace-components/welcome/welcome.component';
import { StatisticsComponent } from './vistrace-components/statistics/statistics.component';
import { ProductInfoMenuComponent } from './components/product-info-menu/product-info-menu.component';
import { ProductMenuItemComponent } from './components/product-menu-item/product-menu-item.component';
import { ProductInfoSelectorComponent } from './components/product-info-selector/product-info-selector.component';
import { ProductInfoComponent } from './vistrace-components/product-info/product-info.component';
import { MobilePreviewComponent } from './vistrace-components/mobile-preview/mobile-preview.component';
import { CreateProductButtonComponent } from './vistrace-components/create-product-button/create-product-button.component';
import { ModalConfirmProductComponent } from './vistrace-components/modal-confirm-product/modal-confirm-product.component';
import { ModalPreviewComponent } from './vistrace-components/modal-preview/modal-preview.component';
import { SubscriptionCardComponent } from './vistrace-components/subscription-card/subscription-card.component';
import { PreviewCardComponent } from './vistrace-components/preview-card/preview-card.component';
import { FooterComponent } from './vistrace-components/footer/footer.component';
import { ChatBotComponent } from './vistrace-components/chat-bot/chat-bot.component';
import { ChatSpaceComponent } from './vistrace-components/chat-space/chat-space.component';
import { RightColumnCardComponent } from './components/right-column-card/right-column-card.component';
import { DeleteProductDialogComponent } from './vistrace-components/delete-product-dialog/delete-product-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxSpinnerModule } from "ngx-spinner";
import { CodeEditorModule } from '@ngstack/code-editor';


@NgModule({
    imports: [
        // ng modules
        CodeEditorModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatRadioModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatSelectModule,
        MatBottomSheetModule,
        MatListModule,
        MatTableModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        DragDropModule,
        MatTabsModule,
        MatToolbarModule,
        MatMenuModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatChipsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatProgressSpinnerModule,
        //MatCarouselModule.forRoot()
        SharedGenericModule,
        QRCodeModule,
        NgxSpinnerModule
    ],
    declarations: [
        // shared directives
        ExternalSourceDirective,
        HighlightDirective,
        // shared components
        //LogoutDialogComponent,
        OccupancyFixedRowComponent,
        OccupancyFlexibleRowComponent,
        DateSelectorComponent,
        ExampleHeader,
        DateRangeSelectorComponent,
        SnackbarComponent,
        DialogFilterComponent,
        ConditionalFormDialogComponent,
        CheckboxDialogComponent,
        SortComponent,
        MonthlyOverviewComponent,
        // charts
        ChartDataComponent,
        ChartDataFieldComponent,
        FilterPanelComponent,
        FilterGroupComponent,
        FilterSelectionGroupComponent,
        SimpleFilterComponent,
        ChartCarouselComponent,
        //pipes
        SafePipe,
        NavBarComponent,
        SideMenuComponent,
        WelcomeComponent,
        StatisticsComponent,
        ProductInfoMenuComponent,
        ProductMenuItemComponent,
        ProductInfoSelectorComponent,
        ProductInfoComponent,
        MobilePreviewComponent,
        CreateProductButtonComponent,
        ModalPreviewComponent,
        ModalConfirmProductComponent,
        SubscriptionCardComponent,
        PreviewCardComponent,
        FooterComponent,
        ChatBotComponent,
        ChatSpaceComponent,
        RightColumnCardComponent,
        DeleteProductDialogComponent
    ],
    exports: [
        // shared components
        // LogoutDialogComponent,
        ConditionalFormDialogComponent,
        CheckboxDialogComponent,
        DialogFilterComponent,
        ExternalSourceDirective,
        HighlightDirective,
        OccupancyFixedRowComponent,
        OccupancyFlexibleRowComponent,
        DateSelectorComponent,
        DateRangeSelectorComponent,
        FilterPanelComponent,
        FilterGroupComponent,
        SortComponent,
        FilterSelectionGroupComponent,
        SimpleFilterComponent,
        ChartCarouselComponent,
        MonthlyOverviewComponent,
        // ng lib modules
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        ChartDataComponent,
        ChartDataFieldComponent,
        NavBarComponent,
        SideMenuComponent,
        WelcomeComponent,
        StatisticsComponent,
        ProductInfoMenuComponent,
        ProductMenuItemComponent,
        ProductInfoSelectorComponent,
        ProductInfoComponent,
        MobilePreviewComponent,
        CreateProductButtonComponent,
        ModalPreviewComponent,
        ModalConfirmProductComponent,
        SubscriptionCardComponent,
        PreviewCardComponent, 
        FooterComponent,
        ChatBotComponent,
        ChatSpaceComponent,
        RightColumnCardComponent,
        DeleteProductDialogComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }

