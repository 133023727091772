<h3 mat-dialog-title class="dialog-title">{{data.title}}</h3>
<!---first form-->

<app-generic-form *ngIf="entityId"
    [xname]="data.firstForm"
    [backend]="data.backend"
    [titlesize]="14"
    [fontsize]="14"
    [entity]="entityId"
    [col_number]="1"
    [frozen]="true"
></app-generic-form>
<div *ngIf="dataCheckInfo" [ngStyle]="{'color': 'red'}">{{dataCheckInfo}}</div>
<!---second form: structure dependent on value chosen in the first form-->
<div class="conditional-form" [ngClass]="{'hide': !option.isSelected}" *ngFor="let option of options">
    <app-generic-form
        [xname]="option.form"
        [backend]="data.backend"
        [titlesize]="14"
        [fontsize]="14"
        [entity]="entityId"
        [col_number]="2"
        [frozen]="true"
        [emitNameValidStatus]="true"
    ></app-generic-form>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()" *ngIf="!data.editMode">Abbrechen</button>
    <button mat-button (click)="onOk()" [disabled]="!okEnabled" cdkFocusInitial>Ok</button>
</div>