


  <!-- NavBar -->
  <header>
      <app-nav-bar></app-nav-bar>
  </header>
  
  <main class="h-[calc(100vh-152px)]">
    <router-outlet></router-outlet>
  </main>

  <footer class="bg-vistrace-primary bottom-0 absolute w-full flex flex-row items-center">
    <app-footer class="w-full"></app-footer>
  </footer>

  

