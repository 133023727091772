import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  public activeRoute: string = '';
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.activeRoute = this.router.url;
  }

  selectMenuItem(item: string) {
    this.activeRoute = item; // Establece el ítem seleccionado
    // Aquí puedes agregar lógica adicional según el ítem seleccionado si es necesario
    switch(item) {
      case 'Home':
        this.toHome();
        break;
      case 'My Products':
        this.toProducts();
        break;
      case 'Subscription Plans':
        this.toSubscriptions();
        break;
      case 'Settings':
        this.toSettings();
        break;
      default:
        // Lógica por defecto si es necesario
    }
  }

  toHome() {
    this.router.navigateByUrl("/main/home")
  }

  toProducts() {
    this.router.navigateByUrl("/main/products")
  }

  toSettings() {
    this.router.navigateByUrl("/main/settings")
  }

  toProductInfo() {
    this.router.navigateByUrl("/main/product-info-view")
  }

  toSubscriptions() {
    this.router.navigateByUrl("/main/subscriptions")
  }

}
