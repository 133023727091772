<mat-accordion>
    <mat-expansion-panel *ngFor="let filterGroup of filterGroups" [expanded]="filterGroup.status"
       (opened)="setLastOpened(filterGroup.id)" (closed)="removeLastOpened(filterGroup.id)" [ngStyle]="{'max-width': maxWidth + 'px'}">
      <mat-expansion-panel-header>
        <mat-panel-title [ngStyle]="{'font-size':'15px'}">
          {{filterGroup.displayName}}
        </mat-panel-title>
      </mat-expansion-panel-header>
    <div class="filter-wrapper" [ngStyle]="{'max-height': optionsMaxHeight + 'px'}">
      <mat-checkbox *ngFor="let filter of filterGroup.filterOptions" (change)="statusChanged(filter)" [(ngModel)]="filter.isActive">{{filter.displayName}}</mat-checkbox>
    </div>
    </mat-expansion-panel>
  </mat-accordion>
  
