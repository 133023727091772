import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChatBotMessage } from '../../types/chatbotMessages';
import { ChatService } from '../../services/chat.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { CodeModel } from '@ngstack/code-editor';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-chat-space',
  templateUrl: './chat-space.component.html',
  styleUrls: ['./chat-space.component.scss']
})
export class ChatSpaceComponent implements OnInit, OnChanges {
  @Input() html: string = "";
  @Input() css: string = "";
  @Input() typescript: string = "";
  @Input() instance: string = "";
  @Input() product_key: string = "";
  public userMessage: ChatBotMessage = {role: 'user', content: ''}
  public localMessages: any;
  questionForm = new FormGroup({
    textInput: new FormControl()
  })
  theme = 'vs-light';

  model_html: CodeModel = {
    language: 'html',
    uri: 'index.html',
    value: this.html
  };

  model_css: CodeModel = {
    language: 'css',
    uri: 'index.css',
    value: this.html
  };

  model_ts: CodeModel = {
    language: 'typescript',
    uri: 'index.ts',
    value: this.html
  };

  model_in: CodeModel = {
    language: 'html',
    uri: 'instance.html',
    value: this.instance
  };

  options = {
    contextmenu: false,
    minimap: {
      enabled: false
    },
    automaticLayout: true
  };



  constructor(public chatService: ChatService,
              private messageService: MessageService        
    ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void {
    this.model_html.value = this.html;
    this.model_html = { ...this.model_html };
    this.model_css.value = this.css;
    this.model_css = { ...this.model_css };
    this.model_ts.value = this.typescript;
    this.model_ts = { ...this.model_ts };
    this.model_in.value = this.instance;
    this.model_in = { ...this.model_in };
  }

  public sendUserMessage(): void {
    this.userMessage.content = this.questionForm.get("textInput").value;
    this.chatService.messages.push(this.userMessage)
    this.userMessage = {role: 'user', content: ''}
    this.questionForm.get("textInput").setValue("");
    const callbackId = 'groqAnswer';
    const message = {
          name: 'askGroq',
          args: [this.chatService.messages, callbackId, this.messageService.dynamic_port]
    };
    this.messageService.sendMsg(message, 'main');
    //this.chatService.messages.push({role: 'assistant', content: 'I am a bot, I am not able to help you with that.'})
  }

  onCodeChanged_html(value) {
    console.log('HTML', value);
    this.html = value;
  }

  onCodeChanged_css(value) {
    console.log('CSS', value);
    this.css = value;
  }

  onCodeChanged_in(value) {
    console.log('INSTANCE', value);
    this.instance = value;
  }

  onCodeChanged_ts(value) {
    console.log('TS', value);
    this.typescript = value;
  }

  sendInstance() {
    const message = {
      name: 'sendText',
      args: [this.instance, "instance"]
    };
    this.messageService.sendMsg(message, 'main');
  }
  sendCss() {
    const message = {
      name: 'sendText',
      args: [this.css, "css"]
    };
    this.messageService.sendMsg(message, 'main');
  }
  sendHtml() {
    const message = {
      name: 'sendText',
      args: [this.html, "html"]
    };
    this.messageService.sendMsg(message, 'main');
  }
  sendTs() {
    const message = {
      name: 'sendText',
      args: [this.typescript, "ts"]
    };
    this.messageService.sendMsg(message, 'main');
  }

}
