
import { map } from 'rxjs/operators';
import { Directive, ElementRef, Input, OnInit } from '@angular/core'; 
import { HttpClient, HttpResponse } from '@angular/common/http';


@Directive({ 
    selector: '[external-source]' 
}) 
export class ExternalSourceDirective implements OnInit {

@Input('external-source') private templateUrl: string;

constructor(private element: ElementRef, private http: HttpClient) {

}
parseTemplate(res: Response) {
    console.log("reload");
    this.element.nativeElement.innerHTML = res.text();
}

ngOnInit() {
    console.log(this.templateUrl);
    this.http.
        get(this.templateUrl).pipe(
        map((res:any) => this.parseTemplate(res))).subscribe();
}
}