
<mat-form-field *ngIf="onFilter">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)">
</mat-form-field>
<div class="edit-table-row">


  <table mat-table [dataSource]="tableDataList" matSort class="table-border mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- Name Column -->
    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col['id']" >
      <th mat-header-cell class="header" *matHeaderCellDef mat-sort-header [matTooltip]="col['tooltip']" [disabled]="disableSort"> 
        <div [ngStyle]="{'flex-grow': '1', 'display': 'flex', 'flex-direction': 'row', 'justify-content': 'center', 'align-items': 'center'}">
          {{col['title']}}
          <div *ngIf="col['tooltip'] || col['tooltip_img']" class="tooltip" [ngStyle]="{'padding-top': '3px'}">
            <mat-icon class="info-icon" svgIcon="icon_info"></mat-icon>
            <span *ngIf="col['tooltip_img']"><img class="tooltip-img" [src]="col['tooltip_img']"></span>
          </div>
        </div>
      </th> 
      <!--th mat-header-cell class="header" *matHeaderCellDef mat-sort-header> 
          {{name}}
        </th-->
      <td mat-cell *matCellDef="let element"
              [style.text-align]="col['text-align']"  
              id="cell"> 
          <!--{{ element[col['id']] | cell: col['pipe'] }}-->
          <mat-form-field [style.width]="col['width']"
                          [ngStyle]="col['style']"
                          [ngSwitch]="col['type_of_field']"
                          [style.height]="'52px'">

            <div *ngSwitchCase="'numberInput'" class="input-no-arrows">
              <input autocomplete="off" matInput type="number"
                [(ngModel)]="element[col['id']]" 
                (ngModelChange)="onChange(element, col['id'])"
                [disabled]="checkFieldDisabled(col, element)">
            </div>
            <div *ngSwitchCase="'textInput'">
              <input autocomplete="off" matInput type="text"
                [(ngModel)]="element[col['id']]" 
                (ngModelChange)="onChange(element, col['id'])"
                [disabled]="checkFieldDisabled(col, element)"
                appInvalidCharacters [invalidCharacters]="col['invalid_characters']"
                (pastedTextReplaced)="setReplacedText($event, element, col['id'])">
            </div>
            <div *ngSwitchCase="'autoComplete'">
              <input autocomplete="off" matInput type="text"
                      [(ngModel)]="element[col['id']]" 
                      (ngModelChange)="onChange(element, col['id'])"
                      [matAutocomplete]="auto"
                      [matAutocompleteDisabled]="col['disabled']"
                      [disabled]="checkFieldDisabled(col, element)"
                      appInvalidCharacters [invalidCharacters]="col['invalid_characters']"
                      (pastedTextReplaced)="setReplacedText($event, element, col['id'])">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of callFilteredOptions(element, col['id'])" [value]="option">
                  {{option}}
                  </mat-option>
              </mat-autocomplete>
            </div>
            <mat-select *ngSwitchCase="'selectPicker'"
              [(ngModel)]="element[col['id']]" 
              (ngModelChange)="onChange(element, col['id'])"
              [disabled]="checkFieldDisabled(col, element)">
              <mat-option *ngFor="let option of col['select_options']" [value]="checkOption(option) ? option.value : option" [disabled]="checkFieldDisabled(col, element)">{{checkOption(option) ? option.label : option}}</mat-option>
            </mat-select>
            <div *ngSwitchCase="'dateAndTimePicker'" class="date-and-time">
              <input matInput type="datetime-local" 
                [(ngModel)]="element[col['id']]" 
                (ngModelChange)="onChange(element, col['id'])"
                [disabled]="checkFieldDisabled(col, element)">
            </div>
            <p *ngSwitchDefault>
              Input type is not implemented
            </p>
          </mat-form-field>
          <!-- in generic form field "disabled" is implemented in ts -->
      </td>
    </ng-container>

    <ng-container *ngFor="let name of filterHeaders" [matColumnDef]="name">
        <th mat-header-cell class="header" *matHeaderCellDef> 
          <mat-form-field *ngIf="filters[name] !== undefined" class="filter" floatLabel="always">
            <mat-label>Search</mat-label>
            <input matInput [formControl]="filters[name]">
          </mat-form-field>
        </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsId; sticky:true"></tr>
    <tr mat-header-row [ngClass]="{'showFilter': filtersOn, 'hideFilter': !filtersOn}" *matHeaderRowDef="filterHeaders"></tr>
    <!-- 'selected': selection.isSelected(row) -->
    <tr mat-row [ngClass]="{'selected': this.selectedKey==row['entityId'], 'enabled': this.enabled}" *matRowDef="let row; columns: displayedColumnsId;"
      (click)="onRowClicked(row)">
    </tr>
  </table>


</div>
<mat-paginator *ngIf="page_size" [pageSize]="page_size" showFirstLastButtons></mat-paginator>

