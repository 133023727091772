<h3 mat-dialog-title class="dialog-title">Enter 2FA Pin</h3>

<mat-dialog-content>
  <code-input [isCodeHidden]="true"
    [codeLength]="6"
    (codeChanged)="onCodeChanged($event)"
    (codeCompleted)="onCodeCompleted($event)"
    #codeInput
    >
  </code-input>
</mat-dialog-content>
