<h3 mat-dialog-title class="dialog-title">2-Factor Key</h3>
<span>Scan with 2FA App (e.g. Aegis, Authy, Google Authenticator)</span>
<div mat-dialog-content class="dialog-content">
  <div class="qr-container">
    <qrcode [qrdata]="data.qr_string" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>
<span>To finish process please verifiy:</span>
<app-pincode (pinCode)="approvePin($event)" [autoclose]="false"></app-pincode>
<h4 mat-dialog-title class="dialog-title">{{resultMessage}}</h4>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
    <!--button mat-button [disabled]="notApproved" (click)="onOk()" cdkFocusInitial>Ok</button-->
</div>