import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirm-product',
  templateUrl: './modal-confirm-product.component.html',
  styleUrls: ['./modal-confirm-product.component.scss']
})
export class ModalConfirmProductComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalConfirmProductComponent>) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
