import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CodeInputComponent} from 'angular-code-input';
import { InfoCard } from '../../types/infoCard';
import { Icon } from '../../types/icon';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit{
  public isSelected = false;
  public imgSrc = 'assets/drucker_quadranten.png';
  @Input() infoCard: InfoCard;
  @Input() icons: Icon[];

  @Output() clickedIconEvent = new EventEmitter<string>();
  constructor(
  ) { }
  ngOnInit() {
    // let id = "main-wrapper-"+this.infoCard.infos[0];
    // console.log(document.getElementById(id));
    // const element = document.getElementById(id);
    // element.addEventListener("click", function() {
    //   this.isSelected = true;
    // });
  }
  public myClickFunction(event: any) {
    if (event.target.id === 'delete' || event.target.id =='visibility'){
      event.preventDefault();
      event.stopPropagation();
    }else if (!this.infoCard.draggable) {
      this.isSelected = !this.isSelected;
    }
  }

  public clickedIcon(icon: Icon){
    if (icon.triggerStatus){
      this.clickedIconEvent.emit(icon.icon);
    }
  }
}

