import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { MessageService } from "src/app/shared-generic/services/message.service";
import { Dictionary } from "src/app/shared-generic/types/dictionary";


@Component({
  selector: 'app-right-column-card',
  templateUrl: './right-column-card.component.html',
  styleUrls: ['./right-column-card.component.scss']
})
export class RightColumnCardComponent implements OnInit, OnDestroy {
  quote = "";
  private subscriptions = new Dictionary<Subscription>();


  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    const callbackId = "setQuote";
    this.subscriptions.add(callbackId,
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackId))
        .subscribe((msg) => {
          this.quote = msg.args.quote;
        }));
    const msg = {
      name: 'getQuote',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  ngOnDestroy() {
    this.subscriptions.keys().forEach(callbackId =>
       this.subscriptions.item(callbackId).unsubscribe())
  }

}



