<div class="preview-container relative">
    <div class="preview-header absolute top-0 right-0 m-4">
      <button class="close-button bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center" (click)="closePreview()">X</button>
    </div>
    <app-mobile-preview [mobile_url]="url"></app-mobile-preview>
    <app-generic-form *ngIf="product_key"
      [xname]="'product-colors-form'" 
      [entity]="product_key"
      [backend]="'main'" 
      [col_number]="3" 
      [frozen]="true" 
      [justifyContent]="'space-around'"
      [focusFirst]="true">
    </app-generic-form>
</div>