import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  public cancel_title;
  public limit_of_input = 5000;

  constructor() {
   }

   public generatePassword(length, useUpper, useNumbers, userSymbols) {
      var passwordLength = length || 12;
      var addUpper = useUpper || true;
      var addNumbers = useNumbers || true;
      var addSymbols = userSymbols || true;

      var lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      var upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      var symbols = ['!', '?', '@'];

      var getRandom = function (array) {
        return array[Math.floor(Math.random() * array.length)];
      }

      var finalCharacters = "";

      if (addUpper) {
        finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
      }

      if (addNumbers) {
        finalCharacters = finalCharacters.concat(getRandom(numbers));
      }

      if (addSymbols) {
        finalCharacters = finalCharacters.concat(getRandom(symbols));
      }

      for (var i = 0; i < passwordLength - 3; i++) {
        finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
      }

      //shuffle!
      return finalCharacters.split('').sort(function () {
        return 0.5 - Math.random()
      }).join('');
    }

}

