<div class="bg-blue-100 rounded-lg shadow px-5 py-4">
  <div class="flex justify-between items-center">
    <div>
      <span class="text-sm font-semibold text-gray-700">Statistics</span>
    </div>
    <div>
      <span class="text-sm font-semibold">More data</span>
    </div>
  </div>
  <!-- Content -->
</div>

