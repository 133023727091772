import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalPreviewComponent } from '../../vistrace-components/modal-preview/modal-preview.component';
import { Router } from '@angular/router';
import { ModalConfirmProductComponent } from '../../vistrace-components/modal-confirm-product/modal-confirm-product.component';
import { DashboardTitleService } from '../../services/dashboard-title.service';;
// import { DashboardTitleService } from '../../services/dashboard-title.service';

@Component({
  selector: 'app-product-info-menu',
  templateUrl: './product-info-menu.component.html',
  styleUrls: ['./product-info-menu.component.scss']
})
export class ProductInfoMenuComponent implements OnInit {

  selectedTitle: string | null = "Product Info";
  isModalOpen: boolean = false;
  product_info = false;
  cultivation = false;
  production = false;
  bottling = false;
  sales = false;
  transport = false;
  
  constructor(private dialogRef : MatDialog,
              private router : Router,
              private titleService : DashboardTitleService) 
  {
  }

  ngOnInit(): void {
  }

  checkSectionStates(): void {
    this.product_info = this.titleService.formStates["Product-Info"].every(form => form.state === true);
    this.cultivation = this.titleService.formStates["Cultivation"].every(form => form.state === true);
    this.production = this.titleService.formStates["Production"].every(form => form.state === true);
    this.bottling = this.titleService.formStates["Bottling"].every(form => form.state === true);
    this.sales = this.titleService.formStates["Sales"].every(form => form.state === true);
    this.transport = this.titleService.formStates["Transport"].every(form => form.state === true);
  }

  onItemSelected(title: string) {
    this.selectedTitle = title;
    this.checkSectionStates();
  }

  openDialogPreview(){
    console.log('open dialog');
    this.dialogRef.open(ModalPreviewComponent);
  }

  openDialogConfirm(): void {
    const dialogRef = this.dialogRef.open(ModalConfirmProductComponent, {
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Logic if user confirms
      } else {
        //Logic if user cancels
      }
    });
  }



  toProducts() {
    this.router.navigateByUrl("/main/products")
  }

}
