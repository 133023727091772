import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { TableDataService } from './../../../shared-generic/services/table-data.service';
import { Router } from '@angular/router';
import { FormDialogComponent } from 'src/app/shared-generic/components/form-dialog/form-dialog.component';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared-generic/services/layout.service';
import { ViewService } from 'src/app/shared/services/view.service';
import { ResetPwAdminComponent } from '../../components/reset-pw-admin/reset-pw-admin.component';
import { HttpClient } from '@angular/common/http';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { KeycloakService } from 'keycloak-angular';
import { DialogComponent } from 'src/app/shared-generic/components/dialog/dialog.component';
import { UiSupplierService } from '../../services/ui-supplier-service';

@Component({
  selector: 'app-user-management-view',
  templateUrl: './user-management-view.component.html',
  styleUrls: ['./user-management-view.component.scss']
})
export class UserManagementViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.app-bottom') dataView = true;
  public userKey = '';
  public newUserKey = "";
  private subscriptions: Array<Subscription> = [];
  public backend = "main";
  public user_table = "user-management-table";
  public rowSelectedUser = false;
  public selectedRowUser: any;
  public paginator =7;
  public wideScreen_paginator = 10;
  public updateTriggerUserForm = '';
  public formName = 'user-management-form';
  public newUserCallbackId = "setNewUser";
  public init = 100;
  public dialog_name ="add-user";
  public reset_passwort_form = 'reset-passwort-form';
  public reset_passwort_dialog = 'reset-passwort-dialog';
  private callbackSetPasswordAdmin = "setPasswordAdmin";

  constructor(
    private uiSupplierService: UiSupplierService,
    private messageService: MessageService,
    private tableDataService: TableDataService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private router: Router,
    public layoutService: LayoutService,
    public viewService: ViewService,
    private httpClient: HttpClient,
    public dialogLoginService: DialogLoginService,
    public keycloak: KeycloakService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.user_table))
      .subscribe(msg => {
        this.rowSelectedUser = msg.status;
        if (this.rowSelectedUser) {
          // console.log("selectedRow: ", msg.row);
          this.userKey = msg.row["key"];
          this.selectedRowUser = msg.row;
          // TEST this 03.03.2021: this prevents an existing form to write the fetched values again in the database
          // check0001
          this.init = this.messageService.getRandomInt(10000);
        }
      })
    ); 

    this.subscriptions.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.newUserCallbackId))
      .subscribe(msg => {
        this.newUserKey = msg.args.new_key;
      })
    );
    this.subscriptions.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.callbackSetPasswordAdmin))
      .subscribe(msg => {
        this.uiSupplierService.openSnackbar(msg.args.password_message, 4000);
      })
    );
  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }

  public deleteUser() {
    // console.log('delete', this.selectedRowUser);
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '450px',
      data: {
        title: "Nutzer entfernen",
        message: "Möchten Sie den Nutzer " + this.selectedRowUser["username"] + 
                 " wirklich entfernen?",
        name: "notNeeded"}
     });
     dialogRef.afterClosed().subscribe( data => {
         if (data) {
          const msg = {
              name: 'deleteLine',
              args: [this.user_table, this.selectedRowUser, 'tabledata/main/user-management-table'],
          }
          this.messageService.sendMsg(msg, this.backend);
          this.rowSelectedUser = false;
          this.selectedRowUser = undefined;
          this.userKey = "";
         }
       }
     );
  }

  public backHome() {
    this.router.navigateByUrl(this.viewService.currentApp);
  }

  public add_user(){
    const id = (Math.floor(Math.random() * Math.floor(10000))).toString();
    const dialogRef = this.dialog.open(FormDialogComponent, {
      disableClose: true,
      width: '800px',
      data: {
        title: "Benutzer hinzufügen",
        form_dialog_name: this.user_table + `_${id}`,
        backend: this.backend,
        form: this.formName + "-init",
        create_new: true,
      }  
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const msg = {
          name: 'select_new_row',
          args: [this.newUserCallbackId, this.user_table]
        }
        this.messageService.sendMsg(msg, this.backend);
      } else {
        this.newUserKey = "";
      }
    });
  }

  public setPasswort() {
    // console.log('setPasswort', this.selectedRowUser);
    const dialogRef = this.dialog.open(ResetPwAdminComponent, {
      disableClose: true,
      width: '500px',
      data: {
        title: "New password for " + this.selectedRowUser["username"],
        name: this.reset_passwort_dialog,
        backend: this.backend,
        form: this.reset_passwort_form,
        create_new: true,
        disableOkBasedOndataCheckInfo: true
      }  
    });
    dialogRef.afterClosed().subscribe( data => {
        if (data) {
          const msg = {
              name: 'set_password_admin',
              args: [this.selectedRowUser, data["password"]],
          }
          this.messageService.sendMsg(msg, this.backend);
        }
      }
    );
  }
}
