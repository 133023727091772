import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from '../types/message';

@Injectable({
  providedIn: 'root'
})
export class HolidayService {
  public backend = "main";
  private subscriptions = [];
  public monthHolidays = [];
  public formDataHolidaysSubject =  new Subject<Message>();

  public constructor(private messageService: MessageService) {
  }

  public getMonthHolidays(){
    const callbackId = 'holidays/' + this.backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.monthHolidays = msg.args.holidays;
            this.formDataHolidaysSubject.next(msg);

          });
      this.subscriptions.push(callbackId);
    }
    // this.getMonthHolidaysMessage(this.backend);
    return this.formDataHolidaysSubject;
  }

  public getMonthHolidaysMessage(){
    const callbackId = 'holidays/' + this.backend;
    const message = {
      name: 'getHolidays',
      args: [callbackId]
  };
  this.messageService.sendMsg(message, this.backend);
  }

}
