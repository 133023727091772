<!--form (ngSubmit)="onSubmit(paymentForm)" class="checkout">
  <h3 mat-dialog-title class="title-dialog">Insert Card Info</h3>
  <div id="form-field">
      <form class="form" [formGroup]="formGroup">
          <mat-form-field class="name-field">
              <input autocomplete="off" matInput formControlName="name" placeholder="Name of Cardholder" required>
          </mat-form-field>
      </form>

      <div class="input-field" id="card-info" #cardInfo>
      </div>
      
      
      <div mat-dialog-actions class="actions">
          <mat-error id="card-errors" role="alert" *ngIf="cardError">
              <mat-icon style="color: #f44336">cancel</mat-icon>
              &nbsp;{{ cardError }}
          </mat-error>
          <div class="spacer"></div>
          <button mat-stroked-button id="submit-button" type="submit" [disabled]="!status">
              Ok
          </button>
      </div>
  </div>

</form-->


<div class="flex flex-row justify-center">
  <div class="p-4 border rounded-md w-[450px] m-2">
    <p class="text-lg mb-4 text-center">Enter Your Payment Details</p>
    <form (ngSubmit)="onSubmit(paymentForm)" #paymentForm="ngForm">
      <div>
        <div class="mb-4">
          <input placeholder="Cardholder Name" type="text" id="cardholder-name" name="cardholderName" [(ngModel)]="cardholderName" class="mt-1 p-2 block w-full border-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 h-10">
        </div>
      </div>
      <div id="card-element" class="mt-1 p-2 block w-full border-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 mb-6"></div>
      <div id="card-errors" role="alert"></div>
      <button mat-stroked-button id="submit-button" type="submit" [disabled]="loading">
        Submit
      </button>

      <!--button type="submit" [disabled]="loading">Apply</button-->
    </form>
  </div>
</div>


<!--stripe-card #stripeCard
  (catch) = "onStripeError($event)"
  (complete) = "cardDetailsFilledOut"
  (invalid) = "onStripeInvalid($event)"
  [options]="{hidePostalCode: true}"
  (cardMounted) = "cardCaptureReady = true"
  (paymentMethodChange) = "setPaymentMethod($event)"
></stripe-card>

<button class="m-4 bg-vistrace-primary" type="button" (click)="stripeCard.createPaymentMethod()">Confirm</button-->
