import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateSelectorService } from './../../services/date-selector.service';
import { HolidayService } from 'src/app/shared-generic/services/holiday.service';

import { filter } from 'rxjs/operators';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import { MatCalendar, MatDatepicker } from "@angular/material/datepicker";
import {
  DateAdapter
} from "@angular/material/core";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { LayoutService } from 'src/app/shared-generic/services/layout.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import * as moment from 'moment';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateSelectorComponent implements OnInit {

  @Input() value: any;
  @Input() name: string;
  @Input() backend: string;
  @Input() directionType: string = "week";
  @Input() width: string;

  @Output() valueChanged = new EventEmitter<string>()

  public subs: Subscription[] = [];
  public week: string;
  public month: string;
  public monthHolidays = [];
  exampleHeader = ExampleHeader;
  public dateVisibleRender: string = "";
  datePipe = new DatePipe(this.projectSetupService.locale);

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    // Only highligh holidays inside the month view.
    if (view === 'month') {
      let date_month = cellDate.month()+1
      const date = cellDate.year()+'-'+date_month+'-'+cellDate.date();
      return (this.monthHolidays.includes(date) ) ? 'example-custom-date-class' : '';
    }

    return '';
  }

  constructor(
    private dateSelectorService: DateSelectorService,
    private holidayService: HolidayService,
    public layoutService: LayoutService,
    private projectSetupService: ProjectSetupService,) { }

  ngOnInit(): void {
    this.subs.push(this.dateSelectorService.getDate(this.value, 0, this.directionType, this.name, this.backend).pipe(filter(msg => msg.args.name === this.name))
      .subscribe(
        msg => {
          this.value = msg.args.date;
          this.valueChanged.emit(this.value);
          this.week = msg.args.week;
          this.month = msg.args.month;
          this.dateVisibleRender = this.datePipe.transform(this.value, "shortDate");
        }
    ));

    this.monthHolidays = this.holidayService.monthHolidays;
    this.subs.push(this.holidayService.getMonthHolidays().pipe()
    .subscribe(
      msg => {
        this.monthHolidays = msg.args.holidays;
      }
  ));
  }

  public valueChangedAction(event){
    let str_value = "";
    if (this.value) {
      let month = this.value.month() + 1;
      str_value = [this.value.year(), month, this.value.date()].join("-");
    }
    this.dateSelectorService.getDateMessage(str_value, 0, this.directionType, this.name, this.backend);
  }

  public changeWeek(direction: number){
    this.dateSelectorService.getDateMessage(this.value, direction, "week", this.name, this.backend);
  }

  public changeMonth(direction: number){
    this.dateSelectorService.getDateMessage(this.value, direction, "month", this.name, this.backend);
  }

  ngOnDestroy() {
    this.subs.forEach(subs => subs.unsubscribe());
  }

}

/** Custom header component for datepicker. */
@Component({
  selector: "example-header",
  styles: [],
  template: `
    <mat-calendar-header>
      <button mat-button (click)="todayClicked()">Heute</button>
    </mat-calendar-header>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExampleHeader<D>{

  constructor(
    private _datePicker: MatDatepicker<D>,
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
  ) { }

  public todayClicked() {
    this._calendar.activeDate = this._dateAdapter.today();
    this._datePicker.select(this._dateAdapter.today());
    this._datePicker.close();
  }

}
