import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.scss']
})
export class QrDialogComponent implements OnInit {
  //@Output() doApprovePin = new EventEmitter<string>();
  public notApproved = true;
  public route = '/api-main/approve_pin';
  public resultMessage = "waiting..."
  constructor(
    private dialogService: DialogService,
    private httpClient: HttpClient,
    public dialogRef: MatDialogRef<QrDialogComponent>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, message
  ) { }

  ngOnInit(): void {
  }

  onOk() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  approvePin($event) {
    const username = this.messageService.user;
    const token = this.messageService.token;
    this.httpClient.get(this.route + '?username=' + username + '&pin=' + $event).subscribe(
      resp => {
        console.log("...approve pin:", resp)
        this.notApproved = !resp["pin_approved"];
        if (this.notApproved) {
          this.resultMessage = "Pin incorrect! Try again."
        } else {
          this.resultMessage = "Pin correct! You may leave now."
          this.dialogRef.close(true);
        }
      }
    );
  }

}
