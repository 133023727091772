import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';
import { FilterOption } from './../types/filter-option';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private subscriptions = [];
  public lastOpenedGroup: string = "";
  public filterGroupsSubject =  new Subject<Message>();
  public filterSelectionGroupSubject =  new Subject<Message>();

  constructor(private messageService: MessageService) { }

  public getFilterGroups(controller: string, backend: string): Subject<Message>  {
    const callbackId = 'filtergroups/' + backend + controller;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.filterGroupsSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.getFilterGroupsMessage(controller, backend);
    return this.filterGroupsSubject;
  }

  public getFilterGroupsMessage(controller: string, backend: string){
    const callbackId = 'filtergroups/' + backend + controller;
    const message = {
      name: 'getFilterGroups',
      args: [controller, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  }

  public initFilterSelectionGroup(controller: string, backend: string): Subject<Message>  {
    const callbackId = 'filterselectiongroup/' + backend + controller;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.filterSelectionGroupSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.getFilterSelectionGroupMessage(controller, backend);
    return this.filterSelectionGroupSubject;
  }

  public getFilterSelectionGroupMessage(controller: string, backend: string){
    const callbackId = 'filterselectiongroup/' + backend + controller;
    const message = {
      name: 'getFilterSelectionGroup',
      args: [controller, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  }

  public setFilterSelectionGroupMessage(controller: string, backend: string, filterOption: FilterOption ){
    const callbackId = 'filterselectiongroup/' + backend + controller;
    const message = {
      name: 'setFilterSelectionGroup',
      args: [controller, callbackId, filterOption]
  };
  this.messageService.sendMsg(message, backend);
  }

  public removeFilterSelectionGroupMessage(controller: string, backend: string, group_id: string ){
    const callbackIdSelection = 'filterselectiongroup/' + backend + controller;
    const callbackIdFilters = 'filtergroups/' + backend + controller;
    const message = {
      name: 'removeFilterSelectionGroup',
      args: [controller, callbackIdSelection, callbackIdFilters, group_id, this.lastOpenedGroup]
  };
  this.messageService.sendMsg(message, backend);
  }
}
