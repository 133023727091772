import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject ,  Subscription } from 'rxjs';
import { MessageService } from './message.service';

@Injectable({
    providedIn: 'root',
  })
export class UpdateService {
    // generalized string and event exchange over components
    public update = new Subject<string>();
    public snackbar = new Subject<any>();
    constructor(private router: Router, private httpClient: HttpClient) {}
    
    push(upd: string): void { this.update.next(upd); }
    subscribe(action: any): Subscription { return this.update.subscribe(action); }



   
}

