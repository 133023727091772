import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable, Observer } from 'rxjs';
import { Dictionary } from '../types/dictionary';
import { UpdateService } from './update.service';


@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  // should be able to handle several websocket connections at once
  private subject = new Dictionary<Subject<MessageEvent>>();
  private ws = new Dictionary<WebSocket>();
  private checkId = new Dictionary<any>();
  private counterId = new Dictionary<any>();
  private counterAutoLogin = new Dictionary<any>();
  constructor(private router: Router, private updateService: UpdateService) {}
  
  public connect(url: string, id: string, user: string): Subject<MessageEvent> {

    this.subject.add(id, this.create(url, id));

    // keepalive ...
    //const keepaliveEvent = new MessageEvent(JSON.stringify({user:'aaa'}));
    const keepalive = JSON.stringify({isTrusted: true, user: user});
    //if (!this.counterId.containsKey(id)) {
    this.counterId.add(id, 0);

    if (!this.counterAutoLogin.containsKey(id)) {
      this.counterAutoLogin.add(id, 0);
    }
    if (this.checkId.containsKey(id)) {
      clearInterval(this.checkId.item(id));
      this.checkId.remove(id);
    }

      this.checkId.add(id, setInterval(() => { 
          // console.log("send", keepalive, this.ws.containsKey(id));
          if (this.ws.item(id).readyState === WebSocket.CONNECTING) {
            return;
          }
          if (this.ws.item(id).readyState === WebSocket.OPEN) {
            this.ws.item(id).send(keepalive); 
            this.counterAutoLogin.add(id, 0);
          } else  {
            console.log("WEBSOCKET??!!", id, this.counterAutoLogin.item(id), this.ws.item(id).readyState);
            // this.reconnect(url, id);
            this.close(id);  // otherwise the intervalls add up
            
            if (this.counterAutoLogin.item(id) < 100) {
              // this.updateService.push("logout");
              const c = this.counterAutoLogin.item(id) + 1;
              this.counterAutoLogin.setItem(id, c);
              // this.reconnect(url, id);
              this.updateService.push("reconnect "+id);  // tested on addiplan on 19.6.2023
            }
          }
        }, 2000));   // use much more time

    return this.subject.item(id);
  }

  public reconnect(url: string, id: string) {
    this.subject.add(id, this.create(url, id));
  }

  public close(id: string) {
    if (this.checkId.containsKey(id)) {
      clearInterval(this.checkId.item(id));
      this.checkId.remove(id);
    }
    if (this.ws.containsKey(id)) {
      this.ws.item(id).close();
      this.ws.remove(id);
    }
  }

  private create(url: string, id: string): Subject<MessageEvent> {
    const ws = new WebSocket(url);
    ws.onerror = (event) => {
      console.log('WebSocket Connection Failed with message', event);
      return false;
    };


    const observable = Observable.create(
      (obs: Observer<MessageEvent>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror = obs.error.bind(obs);
        ws.onclose = obs.complete.bind(obs);

        return ws.close.bind(ws);
      })

    const observer = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        } else if (ws.readyState === WebSocket.CONNECTING) {
          console.log('not ready yet... but resend...', this.counterId.item(id));
          // try again after 500 ms
          
          setTimeout(() => {
            if (this.subject.containsKey(id) && (this.counterId.item(id) < 10)) {
              this.subject.item(id).next(data);
              const c = this.counterId.item(id) + 1;
              this.counterId.setItem(id, c);
            }
            }, 500);
          
          //this.close(id); //cleanup mess
          //this.router.navigateByUrl("/sales-order/login-view");
        }
      }
    }
    this.ws.add(id, ws);
    return Subject.create(observer, observable);
  }
}
