
<mat-card-title *ngIf="title" [ngStyle]="{'font-size':'16px'}">
    {{title}}
</mat-card-title>
<!-- <mat-form-field *ngIf="onFilter">
    <input matInput placeholder="Filter" (keyup)="applyFilter($event)">
</mat-form-field> -->
<app-sort *ngIf="sort" class="sort" (sortTrigger)="emitSortTrigger($event)" [backend]="backend" [controller]="controller"></app-sort>
<app-filter-group class="filters" [backend]="backend" [maxWidth]="maxWidth" [optionsMaxHeight]="optionsMaxHeight" 
[ngStyle]="{'max-height': filtersMaxHeight + 'px'}" [ngClass]="filtersMaxHeight? 'scrollFilters' : ''" [controller]="controller"></app-filter-group>

