import { Component, OnInit, OnChanges,  Input, Output, EventEmitter,HostBinding, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { stringify } from 'querystring';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OccupancyDataService } from './../../services/occupancy-data.service';
import { OccupancyTask } from './../../types/occupancy-task';
import { OccupancyDay } from './../../types/occupancy-day';
import { OccupancySubject } from './../../types/occupancy-subject';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';

@Component({
  selector: 'app-occupancy-flexible-row',
  templateUrl: './occupancy-flexible-row.component.html',
  styleUrls: ['./occupancy-flexible-row.component.scss']
})
export class OccupancyFlexibleRowComponent implements OnInit {

  /* 
  TODO: show status in jobs

    +-------+--------+---------+---------+
    |       |   day  |   day   |   day   |
    +------+--------+---------+----------+
    |       |        | _______ |         |
    |subject|        || task  ||         |
    |       |        ||_______||         |
    |       |        | _______ |         |
    |       |        || task  ||         |
    |       |        ||_______||         |
    |       |        |         |         |
    |.......|........|.........|.........|
    |       | ______ |         |         |
    |       || task ||         |         |
    |subject||______||         |         |
    |       |        |         |         |
    +-------+--------+---------+---------+
    
            |_________daysWidth__________|
    |_________________rowWidth___________|
    
    xMargin - margin on both sides (left, right) of the calendar
    taskMargin - margin on all sides of a task
    calendarX - width of the component
  */

  @Input() backend = 'offer-creator';
  @Input() occupancyName: string;
  @Input() days_number: number = 7;
  @Input() startDate: string;
  @Input() updateTrigger = "0";
  @Input() displayIcon;
  @Input() scroll: boolean = true; // whether the component should scroll or not
  @Output() taskSelected = new EventEmitter<{status:boolean, task_id: string, type: string}>();

  public subs: Subscription[] = [];
  public tasks: OccupancyTask[] = [];
  public days: OccupancyDay[] = [];
  public subjects: OccupancySubject[] = [];

  public calendarX = 1200;
  public scrollX = 21;
  public firstColumn = 110;
  public rowHeight = 85;
  public xMargin = 0; //10;
  public taskMargin = 5;

  public rowWidth;
  public daysWidth;
  public dayWidth;
  public taskWidth;
  public defaultRowHeight;

  public tasks_data = new Dictionary<any>();
  public rows_height = new Dictionary<any>();

  constructor(private sanitizer: DomSanitizer,
              private occupancyDataService: OccupancyDataService) { }

  ngOnInit(): void {
    this.initialize();
  }
  
  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  initialize() {

    // calc css
    this.rowWidth = this.calendarX - 2*this.xMargin;
    this.daysWidth = this.rowWidth - this.firstColumn;
    this.dayWidth = (this.calendarX - this.firstColumn - 2*this.xMargin)/this.days_number;
    this.taskWidth = this.dayWidth - 2*this.taskMargin;
    this.defaultRowHeight = this.rowHeight + 2*this.taskMargin;
    // get data
    this.subs.push(this.occupancyDataService.getOccupancy(this.occupancyName, this.startDate, this.days_number, this.backend).pipe(filter(msg => msg.args.occupancyName === this.occupancyName))
      .subscribe(
        msg => {
          var subjects = [];
          var tasks = [];
          var days = [];
          msg.args.tasks.forEach(task => {
            let occupancyTask = OccupancyTask.parseOccupancyTask(task);
            tasks.push(occupancyTask);
          });
          msg.args.days.forEach(day => {
            let occupancyDay = OccupancyDay.parseOccupancyDay(day);
            days.push(occupancyDay);
          });
          msg.args.subjects.forEach(subject => {
            let occupancySubject = OccupancySubject.parseOccupancySubject(subject);
            subjects.push(occupancySubject);
          });
          this.subjects = subjects;
          this.tasks = tasks;
          this.days = days;
          this.getTasks();
          this.getRowHeight();
        }
      ));
  }

  ngOnChanges(changes: SimpleChanges){
    for (let propName in changes){
      // if (propName === "updateTrigger") {
      //     this.subs.forEach( subs => subs.unsubscribe() );
      //     this.initialize();
      // } else if(propName === "startDate" || propName == "filterTrigger"){
      //   this.occupancyDataService.occupancyMessage(this.occupancyName, this.startDate, this.days_number, this.backend, "flexible changes");
      // }
      if ((propName == "startDate" && !changes["startDate"].isFirstChange()) || (propName == "updateTrigger" && !changes["updateTrigger"].isFirstChange())) {
        this.occupancyDataService.occupancyMessage(this.occupancyName, this.startDate, this.days_number, this.backend, "flexible changes");
      }
    }
  }

  public getTasks():void{
    /*
    return all tasks assigned to a subject
    */
    this.tasks_data.removeAll();
   this.tasks.forEach(task =>{
      task.task_left = (task.date_count * ((this.calendarX - this.firstColumn - 2*this.xMargin)/this.days_number)) + this.taskMargin;
      task.task_top = ((task.subject_count - 1) * (this.rowHeight + this.taskMargin)) + this.taskMargin;
      if(this.tasks_data.containsKey(task.subject_id)){
        this.tasks_data.item(task.subject_id).push(task);
      }else{
        this.tasks_data.add(task.subject_id, [task]);
      }
    });
  }

  public getRowHeight(){
    /*
    return row height
    */
    this.subjects.forEach(subject=> {
      if (subject.subject_max_tasks === 0){
        this.rows_height.add(subject.subject_id, this.defaultRowHeight);
      }else {
        var rowHeight = (subject.subject_max_tasks * (this.rowHeight + this.taskMargin))+ this.taskMargin;
        this.rows_height.add(subject.subject_id, rowHeight);
      }
    });
  }

  public selectTask(task_id: string) {
    /*
    select/diselect a task
    */
    this.tasks.forEach(task => {
      if (task.task_id == task_id && !task.isSelected){
        task.isSelected = true;
      }else {
        task.isSelected = false;
      }
    });   
     var clicked_task = this.tasks.find(task => task.task_id === task_id);
    this.taskSelected.emit({status: clicked_task.isSelected,task_id: task_id, type: clicked_task.type });
    // TODO: send info to backend
    // task_id + task.isSelected status
  }

  public getOccupancyContainerStyle() {
    return {
      width: this.calendarX + this.scrollX + 'px',
      //'padding-top': '100px',
      'padding-left': this.xMargin + 'px',
      'padding-right': this.xMargin + 'px'}
  }

  public getSubjectStyle() {
    return {
      width: this.firstColumn + 'px', 
      'max-width': this.firstColumn + 'px',}
  }

}
