<div
  class="relative flex flex-col bg-clip-border rounded-xl bg-gradient-to-tr from-gray-900 to-gray-800 text-white shadow-gray-900/20 shadow-md w-full max-w-[18rem] 2k:max-w-[20rem] p-8 2k:h-[750px] h-[620px]"
  [ngClass]="{'from-blue-900 to-blue-800 h-[670px] 2k:h-[850px]': activated}">
  <div
    class="relative pb-8 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10"
    *ngIf="subscriptionType !== 'enterprise'">
    <p class="block font-sans text-sm antialiased font-normal leading-normal text-white uppercase">
      {{subscriptionType}}
    </p>
    <h1 class="flex justify-center gap-1 mt-6 font-sans antialiased font-normal tracking-normal  text-white text-4xl 2k:text-7xl">
      <span class="mt-2 text-2xl 2k:text-3xl">€</span>{{subscriptionDesc["price"]}}
      <span class="self-end text-2xl 2k:text-3xl">/mo</span>
    </h1>
    
  </div>
  <div
    class="relative pb-8 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10"
    *ngIf="subscriptionType == 'enterprise'">
    <p class="block font-sans text-sm antialiased font-normal leading-normal text-white uppercase">
      {{subscriptionType}}
    </p>
    <h1 *ngIf="subscriptionType == 'enterprise'" class="flex justify-center gap-1 mt-6 uppercase font-normal text-white text-4xl">
      price on demand
    </h1>
    
  </div>
  
  <div class="">
    <ul *ngIf="subscriptionType !== 'enterprise'" class="flex flex-col gap-4 pt-8">
      <li class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Number Of Product: {{subscriptionDesc["n_product"]}}
        </p>
      </li>
      <li class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Languages: <label> {{subscriptionDesc["language"]}} </label> 
        </p>
      </li>
      <li class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Branding: <label> {{subscriptionDesc["branding"]}} </label>
        </p>
      </li>
      <li *ngIf=" subscriptionType == 'advance' || subscriptionType == 'premium' " class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Secure Data: {{subscriptionDesc["secure"]}}
        </p>
      </li>
      <li *ngIf=" subscriptionType == 'premium' " class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Additional Features: {{subscriptionDesc["additional_features"]}}
        </p>
      </li>
    </ul>
    <ul *ngIf="subscriptionType == 'enterprise'" class="flex flex-col gap-4 pt-8">
      <li class="flex items-center gap-4">
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit justify-center">
          Additional Features
        </p>
      </li>
      <li class="flex items-center gap-4" *ngFor="let feature of subscriptionDesc['additional_features']">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          {{feature}}
        </p>
      </li>
      <!-- <li class="flex items-center gap-4">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          Tracking Information
        </p>
      </li>
       -->
    </ul>
  </div>
  <div class="p-0 mt-12 flex w-full absolute left-0 bottom-[5%] justify-center">
    <button
      *ngIf="subscriptionType != 'enterprise' && !activated"
      class="w-4/5 text-gray-900 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-white shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button">
      Choose Plan
    </button>
    <button
      *ngIf="subscriptionType == 'enterprise' && !activated"
      class="w-4/5 text-blue-200 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-gray-700 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button">
      Contact Us
    </button>
    <button
      *ngIf="activated"
      class="w-4/5 text-blue-200 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-gray-700 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button">
      Activated
    </button>
  </div>
</div> 