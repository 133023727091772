import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { FileDownloadService } from '../../services/file-download.service';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from './../../services/message.service';
import { DialogService } from './../../services/dialog.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { LayoutService } from '../../services/layout.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';

const init_title = {
  "DE": "Drag & Drop Dokumente hier",
  "EN": "Drag & drop documents here"
}

const delete_text = {
  "DE": ["Datei entfernen", "Möchten Sie die Datei ", " wirklich entfernen?"],
  "EN": ["Remove file", "Do you really want to delete the file ", " ?"]
}

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss']
})
export class DropZoneComponent implements OnInit, OnDestroy {

  @Input() maxHeight: string = "200";
  @Input() height: string = "150";
  @Input() width: string = "300";
  @Input() backend: string = "main";
  @Input() key = '';
  @Input() title = '';
  @Input() disabled: boolean = false;
  @Input() context = '';
  @Input() updateGetFiles = '';
  //@Output() fileDropped = new EventEmitter<any>();

  public files = [];
  public file_name = '';
  private subscriptions: Array<Subscription> = [];
  private dialog_name = "delete-file";
  public updateItemsCallbackIdB = '';
  public path = '';
  public init_title = '';
  public delete_text = [];
  public callBackToken = '';
  public current_file = '';

  constructor(
    private fileDownloadService: FileDownloadService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private dialogService: DialogService,
    private layoutService: LayoutService,
    private projectSetupService: ProjectSetupService
  ) { }

  ngOnInit(): void {
    this.init_title = init_title[this.projectSetupService.language];
    this.delete_text = delete_text[this.projectSetupService.language];
    // need to postpone, because key is not here yet
    setTimeout( ()=> {
        if (this.key.length>0) {
          this.dialog_name = this.dialog_name + '/' + this.key;
          this.updateItemsCallbackIdB = 'uploadFiles/' + this.key + this.context;
          this.callBackToken = 'callBackToken/' + this.key + this.context;
          const msg3 = {
            name: 'getUploadedFiles',
            args: [this.updateItemsCallbackIdB, this.key, this.context]
          };
          this.messageService.sendMsg(msg3, this.backend);
          // console.log("key: ", this.key)
          }
        }, 500);
      
      this.subscriptions.push(
          this.messageService.awaitMessage(this.backend).pipe(filter(message => message.name === this.updateItemsCallbackIdB))
              .subscribe(message => {
                  this.files = message.args.control_pdf_paths;
                  this.path = message.args.path;
                  // console.log("get filenames", message.args.control_pdf_paths);
                  // console.log("get path", message.args.path);
              }
      ));
      // await if file should be delete
      this.subscriptions.push(
        this.dialogService.statusSubject.pipe(filter(msg => msg.dialog_name === this.dialog_name))
        .subscribe( msg => {
          if (msg.status){
            // console.log("dialog_name", this.dialog_name)
            this.deleteFile();
          }
          else{
            this.file_name = '';
          }
        })
      );
      this.subscriptions.push(
          this.messageService.awaitMessage(this.backend).pipe(filter(message => message.name === this.callBackToken))
            .subscribe(message => {
              // console.log("open file with token: ")  
              this.fileDownloadService.downloadFile(this.path + "/" + this.current_file + `?token=${message.args.token}`);
              this.current_file = "";
            }
      ));
  }

  public onFileUploaded(event){
    if (!this.disabled) {
      // console.log("_____________", event[0]);
      // todo: upload multiple files? !!! must be included also ...
      //   for(var i=0; i<$event.length; i++) {
      if (!this.files.includes(event[0].name)){
        var uploaded_file_name = event[0].name;
        // console.log("add ", uploaded_file_name);
        this.files.push(uploaded_file_name);
        const msg = {
          name: 'addFileUploadedFile',
          args: [this.key, uploaded_file_name, this.context]
        };
        this.messageService.sendMsg(msg, this.backend);
      }
      //this.fileDropped.emit(uploaded_file_name);
    }
  }

  public openFile(file) {
    // console.log("show pdf of related files", this.path);
    this.current_file = file;
    const msg = {
      name: 'getToken',
      args: [this.callBackToken]
    };
    this.messageService.sendMsg(msg, this.backend);
  }

  public checkDeleteFile(file) {
    this.file_name = file;
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '450px',
      data: {
        title: this.delete_text[0],
        message: this.delete_text[1] + file + this.delete_text[2],
        name: this.dialog_name}
    });
  }

  public deleteFile() {
    this.files.forEach((element, index) => {if(element == this.file_name) this.files.splice(index, 1);});    
    // console.log("display_file_names: ", this.files);
    const msg = {
      name: 'removeUploadedFile',
      args: [this.files, this.file_name, this.key, this.context]
    };
    this.messageService.sendMsg(msg, this.backend);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // perform correct actions depending on @Input that changed
    for (let propName in changes) {
      // console.log("CHANGE", propName);      
      if (propName === "key" && !changes["key"].isFirstChange()) {
        if (this.key.length>0) {
          this.dialog_name = this.dialog_name + '/' + this.key;
          this.updateItemsCallbackIdB = 'uploadFiles/' + this.key + this.context;
          const msg3 = {
            name: 'getUploadedFiles',
            args: [this.updateItemsCallbackIdB, this.key, this.context]
          };
          this.messageService.sendMsg(msg3, this.backend);      
        }  
      }
      else if (propName === "updateGetFiles" && !changes["updateGetFiles"].isFirstChange()) {
        if (this.key.length>0) {
          this.dialog_name = this.dialog_name + '/' + this.key;
          this.updateItemsCallbackIdB = 'uploadFiles/' + this.key + this.context;
          const msg3 = {
            name: 'getUploadedFiles',
            args: [this.updateItemsCallbackIdB, this.key, this.context]
          };
          this.messageService.sendMsg(msg3, this.backend);      
        }  
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }


}
