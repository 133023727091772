export class OccupancySubject {

    static parseOccupancySubject(occupancySubjectObject) {
        const occupancySubjectItem = new OccupancySubject(
            occupancySubjectObject.display_label,
            occupancySubjectObject.subject_id,
        );
        if(occupancySubjectObject.subject_max_tasks){
          occupancySubjectItem.subject_max_tasks = occupancySubjectObject.subject_max_tasks;
        }
        if(occupancySubjectObject.is_selected){
          occupancySubjectItem.is_selected = occupancySubjectObject.is_selected;
        }
        return occupancySubjectItem;
    }
  
    constructor(
      public display_label: string[],
      public subject_id: string,
      public subject_max_tasks: number=0,
      public is_selected: boolean = false,
    ) { }

  }
