import { Component, OnInit, Inject, Input, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogLoginService } from '../../services/dialog-login.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';


@Component({
  selector: 'app-reset-pw-dialog',
  templateUrl: './reset-pw-dialog.component.html',
  styleUrls: ['./reset-pw-dialog.component.scss']
})
export class ResetPwDialogComponent implements OnInit, OnDestroy {
  public status: boolean = false;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  public subs: Subscription[] = [];
  public error_message: string = "";
  public username: string;

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<ResetPwDialogComponent>,
    private uiInfoService: UiInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,  // requires property url !!!
    public dialogLoginService: DialogLoginService
    ) { }

  ngOnInit() {
    this.init_controls();
    this.username = this.data.user_name;
    this.dialogLoginService.loginErrorSubject.subscribe((error: string) => {
      this.error_message = error;
    });
    this.dialogLoginService.closeSubject.subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public data_send() {
    this.dialogLoginService.login(this.data.name, this.data.password);
  }

  public init_controls() {
    let group = {};
    group["password_I"] = new UntypedFormControl('',Validators.required);
    this.subs.push(group["password_I"].valueChanges.subscribe( x => {
      this.data.password_I = x;
    }));
    group["password_II"] = new UntypedFormControl('',Validators.required);
    this.subs.push(group["password_II"].valueChanges.subscribe( x => {
      this.data.password_II = x;
    }));
    if (['simplePw', 'PwAndSignUp'].indexOf(this.dialogLoginService.loginMode)>=0) {
      group["pin"] = new UntypedFormControl('',Validators.required);
      this.subs.push(group["pin"].valueChanges.subscribe( x => {
        this.data.pin = x;
      }));
    }
    this.formGroup = new UntypedFormGroup(group);

    this.subs.push(this.formGroup.statusChanges.subscribe(val => {
      // action on status change
      // TODO: get answer from the server if it's a valid password
      this.status = this.formGroup.valid;
    }));
  }


  public send_data() {
    // the password is stored salted inside the database !! RSA .ssh
    // prevents brute force dictionary attacks with known hash functions
    const salt = this.messageService.salt;
    const pw_len = this.data.password_I.length;
    const hash_I = this.messageService.hexdigest_n(this.data.password_I+salt, 100);
    const hash_II = this.messageService.hexdigest_n(this.data.password_II+salt, 100);
    // console.log('Sending data...', this.data);
    this.httpClient.post(this.messageService.rootUrl+'reset_pw',
     {secret_number: this.data.secret_number, password_I: hash_I, password_II: hash_II, pin: this.data.pin, password_length: pw_len})
     .subscribe(data => {
        // console.log("from backend: ", data);
        if (data["error"]?.length > 0) {
          // console.log("error reseting password...")
          this.uiInfoService.openSnackbar(data["error"], 3000);
        } else {
          // this.messageService.login = true;
          // console.log("password reset successfull!")
          this.uiInfoService.openSnackbar("Password changed", 3000);
        }
        this.dialogRef.close(data);
       // this.router.navigateByUrl("/login");
        });
    }



}
