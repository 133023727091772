import { Component, OnInit, OnChanges, ViewChild, Input, AfterViewInit, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';   
import { filter } from 'rxjs/operators';
import { TableDataService } from '../../services/table-data.service';
import {SelectionModel} from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MessageService } from './../../services/message.service';
import { FormDataService } from './../../services/form-data.service';
import { TextService } from '../../services/text.service';

 
@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss']
})
export class EditableTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() table_name: any;
  @Input() context = "";
  @Input() formItems: any;
  @Input() updateTrigger = '';
  @Input() removeTrigger = '';


  @Input() singleSelection: boolean = false;
  @Input() index: number;
  @Input() onEditor: boolean = true;
  @Input() onFilter: boolean = false;
  @Input() onButton: boolean = false;
  @Input() selected = undefined;
  @Input() enabled: boolean  = true;
  @Input() entity: string  = '';
  @Input() updateRow: any = undefined;
  @Input() updateFilter = '';
  @Input() editorDisabled: boolean = true;
  @Input() page_size: number;
  @Input() backend = 'offer-creator';
  @Input() selectByKey = "";
  @Input() openEditorEvent = "";
  @Input() selectionDisabled = false;
  @Input() disableSort = true;
  @Input() tableStatus = false;
  @Input() infoTable = false;
  public selection = new SelectionModel<{}>(true, []);
  public tableDataList: MatTableDataSource<{}>;
  public rows = 0;
  public subs: Subscription[] = [];
  public filtersOn = false;
  filters = {};
  filtervalues = {};
  tableInputFormat = {};
  public initFields = {};
  private lastSelectedRow = {};
  private isSelected = false;
  public selectedRow = undefined;
  public selectedKey = "";
  public main_img = '';  
  public valueCallbackId = '';
  public editdableTableData = [];
  public form_details = [];
  // just an example in the first place
  public displayedColumns: any[] = [{"id": "number", "title": "Probennummer"},
                                    {"id": "volume_energy_density", "title": "Vol.Energiedichte"},
                                    {"id":"density", "title": "Dichte"}
                                  ];
  public displayedColumnsId = [];
  public group = {};
                    
  public filterHeaders: string[] = [];
  constructor(private tableDataService: TableDataService,
              private messageService: MessageService,
              private dialog: MatDialog,
              private formDataService: FormDataService,
              private textService: TextService) { }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    // console.log(numSelected);
    return numSelected === this.rows;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.valueCallbackId = "setEditableFormValues/" + this.table_name;
    this.subs.push(
        this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.valueCallbackId))
        .subscribe(
            msg => {
                if (msg.args.update_columns.length > 0) {
                  // update data: applied for updateDataValueDependency if one column entry is 
                  // changed not the complete table needs to be rendered
                  msg.args.update_columns.forEach(
                    column => this.tableDataList.data.forEach(
                      x => x[column] = msg.args.data[x["entityId"]][column]));
                } else {
                  // console.log("received full form", this.table_name, msg);
                  this.editdableTableData = msg.args.data;
                  this.displayedColumns = msg.args.header;
                  // console.log("displayedColumns: ", this.displayedColumns)
                  this.form_details = msg.args.form_details;
                  // console.log("form_details: ", this.form_details)
                  this.displayedColumnsId = this.displayedColumns.map(x => x.id);
                  for (let col of this.displayedColumns) {
                    //console.log("coooooooooooolumn: ", col);
                    // used for table status check
                    // if (col['required']){
                    //   col['title'] += " *"
                    // }
                    if (this.infoTable) {
                      col['disabled'] = true;
                    }
                  }
                  // render new table
                  this.tableDataList = new MatTableDataSource(this.editdableTableData);
                  this.tableDataList.paginator = this.paginator;
                  this.tableDataList.sort = this.sort;                  
                }
                //console.log("table data: ", this.tableDataList.data);
                // check table status
                // if (this.tableStatus){
                //   let tableStatus = this.checkTableStatus();
                //   this.tableDataService.setSingleStatus(this.table_name, tableStatus);
                // }
        })
    );

    this.subs.push(this.messageService.awaitMessage(this.backend)
      .pipe(filter(msg => ((msg.name === 'updateAutoSelectOptions') && (msg.args.form_name === this.table_name))))
      .subscribe(msg => {
          let old_field = this.displayedColumns.find(col => col["id"] == msg.args.field["column_name"]);
          if (old_field) {
            old_field["select_options"] = msg.args.field["select_options"];
          }
        }
      )
      );


    this.subs.push(this.messageService.awaitMessage(this.backend)
    .pipe(filter(msg => ((msg.name === 'updateDataValueDependenciesEditableTable') && (msg.args.table_name === this.table_name))))
    .subscribe(msg => {
        // console.log("updateDataValueDependenciesEditableTable.....", this.table_name, msg.args.updateCols);
        const msg2 = {
            name: 'getFormValuesDirect',
            args: [this.formItems.item(this.table_name), this.table_name, msg.args.updateCols, this.valueCallbackId]
        };
        this.messageService.sendMsg(msg2, this.backend);
      }
    )
    );

  }

  public callFilteredOptions(element, id) {
    // console.log("id...", id);
    // NOTE: this method is called every time element or id is changed
    let options = [];
    this.displayedColumns.forEach(col => {if (col["id"]==id) {options = col['select_options'];}})
    return this._filter(element[id], options);
  }

  private _filter(value: string, options: any[]): string[] {
    let filterValue = "";
    if (value) {
      filterValue = value.toLowerCase();
    }
    const opts = options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    return opts;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // tab related timeout: removed this timeout as the app seems to work without
    //setTimeout(()=> {
    // console.log("on change", this.valueCallbackId, this.formItems);
    if (this.formItems.containsKey(this.table_name)) {
      for (let propName in changes) {
        // console.log("on change", propName);
        if ((propName === "updateTrigger")  && this.valueCallbackId 
            && this.updateTrigger.startsWith(this.table_name)) {
            // console.log("on change", this.valueCallbackId);
            const msg = {
                name: 'getFormValuesDirect',
                args: [this.formItems.item(this.table_name), this.table_name, [], this.valueCallbackId]
            };
            this.messageService.sendMsg(msg, this.backend);
        }
        else if ((propName === "removeTrigger")  && this.valueCallbackId 
            && this.removeTrigger.startsWith(this.table_name)) {
            this.onDeleteItem();
            // check table status
            // if (this.tableStatus){
            //   let tableStatus = this.checkTableStatus();
            //   this.tableDataService.setSingleStatus(this.table_name, tableStatus);
            // }
        }
        else if ((propName === "infoTable")  && this.valueCallbackId) {
          for (let col of this.displayedColumns) {
            //console.log("coooooooooooolumn: ", col);
            if (this.infoTable) {
              col['disabled'] = true;
            } else {
              col['disabled'] = false;
            }
          }   
        }
        else if (propName === "updateRow" && this.updateRow != undefined) {
          this.getRowFromColumnNameAndClick(this.updateRow["value"], this.updateRow["column_name"]);
        }
      }
    }
    //}, 10); 
  }

//   @HostListener('window:keydown', ['$event'])
//   keyEvent(e) {
//       // optionally use preventDefault() if your combination
//       // triggers other events (moving focus in case of Shift+Tab)
//       // 
//     if (this.index === this.tableDataService.activeTabIndex) {
//       if (e.ctrlKey && e.keyCode === 65) {
//           e.preventDefault();
//           this.masterToggle();
//       }
//     }
// }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  
  masterToggle() {
    if (this.singleSelection) {
      return;
    }
    if (this.isAllSelected()) {
      this.tableDataList.data.forEach(row => {
        if (this.selection.isSelected(row)) {
          this.onRowClicked(row);
        }
      });
    } else {
      this.tableDataList.data.forEach(row => {
        if (!this.selection.isSelected(row)) {
          this.onRowClicked(row);
        }
      });
    }
  }
  
  //QUES: why do we not make use of onChange in generic-form?
  onChange(element, id) {
    // console.log("id...", element["entityId"], id, element);
    if (typeof(element[id]) != "string" || element[id].length <= this.textService.limit_of_input) {
      const message = {
        name: 'setGenericFormFieldValue',
        args: [this.table_name, element["entityId"], id, element[id]]
      };
      this.messageService.sendMsg(message, this.backend);
    }
    // check table status
    // if (this.tableStatus){
    //   let tableStatus = this.checkTableStatus();
    //   this.tableDataService.setSingleStatus(this.table_name, tableStatus);
    // }
  }

  public checkTableStatus(){
    for (let row of this.tableDataList.data){
      const keys = Object.keys(row);
      for (let colKey of keys) {
        let fieldDesc = this.displayedColumns.find(col => col["id"] == colKey);
        if(fieldDesc && fieldDesc['required']){
          if ((typeof row[colKey] == "string" && row[colKey].replaceAll(" ", "") == "") || row[colKey] == null){
            return false;
          }
        }
      }
    }
    return true;
  }

  toggleFilter() {
    // toggle filter display
    this.filtersOn = !this.filtersOn;
    // this.participantService.getTable(this.xname);
    // this.participantService.finalizeQuestionnaire();
  }

 
  public getFormat(digits: any): string {
    // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
    // const digits = highPrecision ? 3 : 2;
    if (!digits) {
        digits = 0;
    }
    const format = `1.${digits}-${digits}`;
    return format;
  }

  public onDeleteItem() {
    // from generic form copied
    // console.log("delete item");
    const xname = this.table_name;
    const msg = {
        name: 'deleteFormItem',
        args: [xname, this.selectedKey]
    }
    this.messageService.sendMsg(msg, this.backend);
    // must remove from table and render new
    const row = this.tableDataList.data.filter(x=>this.selectedKey === x["entityId"])[0];
    const idx = this.tableDataList.data.indexOf(row);
    // console.log("...", this.tableDataList.data.length, idx);
    this.tableDataList.data.splice(idx, 1); // ok
    // console.log("...", this.tableDataList.data.length, idx);
    // render current table
    this.tableDataList.paginator = this.paginator;  // is sufficient even without a paginator
    this.tableDataList.sort = this.sort;

  }

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = (data, filter): boolean => {
      const searchTerms = JSON.parse(filter);
      const keys = Object.keys(data);
      keys.shift();
      let out = true;
      for (const k of keys) {
        out = out && data[k].toLowerCase().indexOf(searchTerms[k].toLowerCase()) !== -1;
      }
      return out;
    }
    return filterFunction;
  }

  onRowClicked(row) {
    // refactor ...
    // return
    // console.log(row);
    if (this.enabled) {
      // console.log('Row clicked:  ', row);
      this.selectedRow = row;
      this.selectedKey = row["entityId"];
      // get data from be
      // this.tableDataService.selectRow(this.table_name, row, this.selection.isSelected(row), this.backend);
      if (this.singleSelection) {
        // row selected
        this.tableDataService.setTableStatus(this.table_name, this.entity, true, row);
        if (this.context) {
          this.tableDataService.setTableStatusContext(this.table_name, this.context, true, row);
        }
        this.tableDataList.data.forEach(row => {
          if (this.selection.isSelected(row)) {
            this.selection.toggle(row);
          }
        });
      }
      this.selection.toggle(row);
      this.lastSelectedRow = row;
      this.isSelected = true;
      this.editorDisabled = false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataList.filter = filterValue.trim().toLowerCase();
  }

  public setReplacedText(replacedText, element, id){
    element[id] = element[id] + replacedText;
    this.onChange(element, id);
  }

  public checkOption(option: any) {
    if (option.constructor == Object && 'value' in option && 'label' in option){
      return true;
    }else{
      return false;
    }
  }

  public checkFieldDisabled(col:any, element:any){
    if ("row_disabled" in element && element["row_disabled"].includes(col['id'])){
      return true
    }else if (col["disabled"]) {
      return true
    }
    return false
  }

  getRowFromColumnNameAndClick(value: string, column_name: string) {
    this.tableDataList.data.forEach(row => {
      if (row[column_name] == value) {
        this.onRowClicked(row);
      }
    });
  }
}

