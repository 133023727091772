export class FormField {

    static parseFormField(formFieldObject) {
        let formFieldItem = new FormField(
            formFieldObject.name,
            formFieldObject.default_value,
            formFieldObject.type_of_field,
            formFieldObject.placeholder,
            formFieldObject.required,
            formFieldObject.disabled,
            formFieldObject.invalid_characters,
            formFieldObject.unit,
            formFieldObject.width,
            formFieldObject.column_name,
            formFieldObject.select_options,
            formFieldObject.delete_button,
            formFieldObject.img_width,
            formFieldObject.img_height,
            formFieldObject.language,
            // formFieldObject.extra_style,
            // formFieldObject.style
            // formFieldObject.pipe
        );
        if(formFieldObject.height){
          formFieldItem.height = formFieldObject.height;
        }
        if(formFieldObject.validate_pattern){
          formFieldItem.validate_pattern = formFieldObject.validate_pattern;
        }
        if (formFieldObject.style) {
          // formFieldObject.style = formFieldObject.style.replace(/'/g, '"');
          // formFieldObject.style = JSON.parse(formFieldObject.style);
          formFieldItem.style = formFieldObject.style;
        }
        if (formFieldObject.extra_style) {
          formFieldItem.extra_style = formFieldObject.extra_style;
        }
        if(formFieldObject.tooltip){
          formFieldItem.tooltip = formFieldObject.tooltip;
        }
        if(formFieldObject.tooltip_img){
          formFieldItem.tooltip_img = formFieldObject.tooltip_img;
        }
        if(formFieldObject.edit_disabled){
          formFieldItem.edit_disabled = formFieldObject.edit_disabled;
        }
        if(formFieldObject.upload_finished){
          formFieldItem.upload_finished = formFieldObject.upload_finished;
        }
        if(formFieldObject.effects_on){
          formFieldItem.effects_on = formFieldObject.effects_on;
        }
        return formFieldItem;
    }
  
    constructor(
      public name: string,
      public default_value: string,
      public type_of_field: string,
      public placeholder: string,
      public required: boolean,
      public disabled: boolean,
      public invalid_characters: string,
      public unit: string,
      public width: string,
      public column_name: string,
      public select_options = [],
      public delete_button: boolean,
      public img_width: number,
      public img_height: number,
      public language: string='DE',
      public style: object = {},
      public height: number=0,
      public validate_pattern: string='',
      public tooltip: string='',
      public tooltip_img: string='',
      public edit_disabled: boolean= false,
      public extra_style: any = undefined,
      public upload_finished: boolean= false,
      public effects_on: any=[],
      // public pipe: string
    ) { }

  }
