  
    <div class="flex flex-1 lg:px-[4%] h-full">
      <!-- SideMenu -->
      <aside class="w-64 h-full flex-shrink-0">
        <app-side-menu></app-side-menu>
      </aside>
  
      <div class="w-full h-full overflow-auto pl-4">
        <section class="flex flex-row gap-4 flex-wrap">
            <app-subcription-card class="mt-2 mb-2" subscriptionType="basic" activated="true" [subscriptionDesc]="subscriptionBasic"></app-subcription-card>
            <app-subcription-card class="mt-2 mb-2" subscriptionType="advance" [subscriptionDesc]="subscriptionAdvance"></app-subcription-card>
            <app-subcription-card class="mt-2 mb-2" subscriptionType="premium" [subscriptionDesc]="subscriptionPremium"></app-subcription-card>
            <app-subcription-card class="mt-2 mb-2" subscriptionType="enterprise" [subscriptionDesc]="subscriptionEnterprise"></app-subcription-card>
        </section>
      </div>
    </div>

  