import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-preview-card',
  templateUrl: './preview-card.component.html',
  styleUrls: ['./preview-card.component.scss']
})
export class PreviewCardComponent {
  @Input() title: string;
  @Input() img_src: string;
  @Input() text_description: string;
  @Input() text_content: string;
  @Input() icon?: string;
  @Input() variant: 'default' | 'highlight' | 'accent' = 'default';

  constructor(private messageService: MessageService,
    private chatService: ChatService,
    private router: Router) { }

  getVariantClasses(): string {
    switch (this.variant) {
      case 'highlight':
        return 'bg-green-100 text-green-800';
      case 'accent':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-white text-gray-800';
    }
  }

  removeComponent() {
    const message = {
      name: 'removeComponent',
      args: [this.title]
    };
    this.messageService.sendMsg(message, 'main');
  }

  loadComponent() {
    const message = {
      name: 'loadComponent',
      args: [this.title]
    };
    this.messageService.sendMsg(message, 'main');
    this.chatService.resetMessages();
    this.router.navigateByUrl("/main/products")
  }
}