import { Injectable } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';

import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DropService {

  public moveX = new BehaviorSubject<number>(0);
  public targetIds = new BehaviorSubject<{}>({});
  public targetRows = [];
  public hoverId = '';
  public delX = 0;
  
  constructor() { 
    this.targetIds.subscribe( data => this.targetRows = Object.keys(data) );
    this.moveX.subscribe( data => {
      this.delX += data;
      console.log("finally", this.delX);
      }
    );
  }

  getRows() {
    return this.targetRows;
  }

}