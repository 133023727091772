import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Router } from '@angular/router';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  private personalData: any = {};
  private billingData: any = {};
  activeTab: number = 1;
  public userinfo_key = "";
  public company_key = "";
  private subscriptions: Array<Subscription>=[];
  public invoice_table = "user-invoices-table";

  constructor(private messageService: MessageService,
              private router : Router,
              private uiInfoService: UiInfoService
  ) { }

  ngOnInit(): void {
    const callbackId = "userinfo_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.userinfo_key = msg.args.userinfo_key;
        }
    ));
    const msg = {
      name: 'getUserInfoKey',
      args: [callbackId]
    };
    console.log(msg);
    this.messageService.sendMsg(msg, 'main');

    const callbackkey = "company_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackkey))
        .subscribe( msg => {
          this.company_key = msg.args.company_key;
          console.log("CUSTOMER ", this.company_key);
        }
    ));
    const msgBilling = {
      name: 'getCustomerKey',
      args: [callbackkey]
    };
    this.messageService.sendMsg(msgBilling, 'main');

    
  }

  toSubscriptions() {
    this.router.navigateByUrl("/main/subscriptions")
  }

  updatePersonalData() {
    if (this.personalData["new_password"]!=this.personalData["confirm_password"]) {
      this.uiInfoService.openSnackbar("Confirm Password not equal. Update failed.", 3000);
      return
    }
    if (this.personalData["new_password"]) {
      if (this.personalData["new_password"].length<8) {
        this.uiInfoService.openSnackbar("Password should be at least 8 characters. Update failed.", 3000);
        return
      } else {
        const salt = this.messageService.salt;
        const hash = this.messageService.hexdigest_n(this.personalData["new_password"]+salt, 100);
        this.personalData["new_password"] = hash;
        this.personalData["confirm_password"] = "";
      }
    }
    const msg = {
      name: 'updatePersonalData',
      args: [this.personalData]
    };
    console.log(this.personalData);
    this.messageService.sendMsg(msg, 'main');
  }

  updateBillingData(){
    const kk = Object.keys(this.billingData);
    if (kk.length>0) {

    
      const msg = {
        name: 'updateBillingData',
        args: [this.billingData, this.company_key]
      };
      this.messageService.sendMsg(msg, 'main');
    } else {
      this.uiInfoService.openSnackbar("No Data changed.", 3000);
    }
  }

  genericData(event) {
    console.log(event);
    for (const k of Object.keys(event)) {
      this.personalData[k] = event[k];
    }
  }

  genericBillingData(event) {
    console.log(event);
    for (const k of Object.keys(event)) {
      this.billingData[k] = event[k];
    }
  }

}
