import { Component, Inject, Input} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataRowOutlet } from '@angular/cdk/table';
import { OnInit } from '@angular/core';
import { TextService } from '../../services/text.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
// import { elementHostAttrs } from '@angular/core/src/render3';


@Component({
  selector: 'app-editor-dialog',
  templateUrl: './editor-dialog.component.html',
  styleUrls: ['./editor-dialog.component.scss']
})
export class EditorDialogComponent implements OnInit {
  dataIds_C1 = [];
  editor_title = '';
  public setInputValue = {};
  public cancel_title;
  constructor(
    public dialogRef: MatDialogRef<EditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private textService: TextService,
    private projectSetupService: ProjectSetupService) { }

  ngOnInit() {

    this.cancel_title = this.textService.cancel_title[this.projectSetupService.language];
    if (! this.data.text_cancel_button) {
      this.data.text_cancel_button = this.cancel_title;
    }
    if (! this.data.text_confirm_button) {
      this.data.text_confirm_button = 'Ok';
    }
  }

  onCancel(): void {
    this.dialogRef.close();
    this.data.cancel = true;
  }

  getEditorTitle() {
    const title_id =  this.data.tableInputFormat[0].editor_title;
    //return this.data.inputFields[title_id];
    return title_id;
  }

  dataIds() {
    const dataId = this.data.tableInputFormat.map(x => x.id);
    this.dataIds_C1 = dataId.splice(dataId.length / 2 + dataId.length % 2, dataId.length);
    // console.log("-----------", dataId, this.dataIds_C1);
    return dataId;
  }

  textInputInitId(id: string) {
    const names = this.data.tableInputFormat.filter(el => el['format'] === 'textInit')
        .map(x => x.id);
    return names.indexOf(id) !== -1;
  }

  textInputId(id: string) {
    const names = this.data.tableInputFormat.filter(el => el['format'] === 'text')
        .map(x => x.id);
    return names.indexOf(id) !== -1;
  }

  getDropDownValues(id: string) {
    return this.data.tableInputFormat.filter(el => el.id === id)[0].values;
  }

  getTitle(id: string) {
    return this.data.tableInputFormat.filter(el => el.id === id)[0].title;
  }

  onOk() {
    // do not call this.dialogRef.close(); as this is called via [mat-dialog-close] in html
    // you can add data which is returned via this.dialogRef.close(data)
    this.data.tableInputFormat.forEach( inputDict => {
      // could this be written more nicely?
      // key = inputDict['id'];
      // console.log("key: ", inputDict['id'])
      // option 1: variable is entered by user
      if(this.setInputValue[inputDict['id']]) {
        this.data.inputFields[inputDict['id']] = this.setInputValue[inputDict['id']];
        // console.log("setInput");
      // option 2: variables is not altered, but there is an init value
      } else if (this.data.initFields && this.data.initFields[inputDict['id']]) {
        this.data.inputFields[inputDict['id']] = this.data.initFields[inputDict['id']];
        // console.log("setInit");
      // option 3: nothing is entered by user
      } else {
        // this.data.inputFields[inputDict['id']] = this.data.inputFields[inputDict['id']];
      }
    });
    this.data.cancel = false;
    // console.log("onOk: ", this.data.inputFields);
  }

  onSelect(event: any, id:string) {
    // console.log("data on input: ", event, id);
    this.setInputValue[id] = event.value;
  }

  public onInput(event: any, id: string) {
    // console.log("data on input: ", event, id);
    this.setInputValue[id] = event.target.value;
    
  }
}
