import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';

@Injectable({
  providedIn: 'root'
})
export class DateRangeSelectorService {
  public dateRangeSelectorSubject =  new Subject<Message>();
  private subscriptions = [];
  public dateSelectorHolidaysSubject =  new Subject<Message>();

  constructor(private messageService: MessageService) { }
  
  public triggerUpdate(name: string,backend: string): Subject<Message>  {
    const callbackId = 'daterangeselector/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.dateRangeSelectorSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    return this.dateRangeSelectorSubject;
  }

  public setRangeMessage(start: string, end: string,name:string, backend:string){
    const callbackId = 'daterangeselector/' + backend;
    const message = {
      name: 'setDateRangeSelector',
      args: [start, end, name, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  }
}
