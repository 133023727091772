<!--Popup window-->
<h3 mat-dialog-title class="title-dialog">Reset Password</h3>
<mat-dialog-content>
    <p *ngIf="username">Please enter password reset data for {{username}}</p>
    <p *ngIf="!username">Please enter password reset data</p>
    <div class="login">
        <form class="form" [formGroup]="formGroup">
            <mat-form-field class="field">
                <input matInput formControlName="password_I" [type]="'password'" placeholder="Password" required>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput formControlName="password_II" [type]="'password'" placeholder="Password" required>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput formControlName="pin" [type]="'text'" placeholder="Pin" required>
            </mat-form-field>
        </form>
        <div class="error-message">{{error_message}}</div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="actions">

    <button mat-button [disabled]="!status" (click)="send_data()">Ok</button>
</div>
