<div class="w-full h-full justify-center items-center">

  <div class="w-full flex-grow justify-center items-center pt-4 relative border-white">
    <!-- <div class="h-full w-1 z-0 bg-blue-500 absolute left-8 transform -translate-x-1/2"></div> -->
    <app-product-menu-item 
      title="Product Info"
      [isBlue]="product_info"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Product Info'"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      title="Cultivation" 
      [isBlue]="cultivation"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Cultivation'"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      title="Production"
      [isBlue]="production"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Production'"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      title="Bottling"
      [isBlue]="bottling"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Bottling'"
      >
    </app-product-menu-item>
    <app-product-menu-item
      title="Transport"
      [isBlue]="transport"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Transport'"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      title="Sales"
      [isBlue]="sales"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="selectedTitle === 'Sales'"
      >
    </app-product-menu-item>

    <div class="p-3">
      <div class="h-24 flex justify-between w-full py-2">
        <button (click)="openDialogConfirm()"
          class="confirmPreview relative flex items-center justify-center text-vistrace-primary hover:text-white">
          <div class="imageDiv relative w-10 h-10">
            <img src="assets/incomplete_circle_FILL0_wght400_GRAD0_opsz24.svg"
              class="defaultImg absolute inset-0 w-full h-full" alt="Default Finalize">
            <img src="assets/incomplete_circle_FILL0_wght400_GRAD0_opsz24_2.svg"
              class="hoverImg absolute inset-0 w-full h-full opacity-0" alt="Hover Finalize">
          </div>
          <span class="pt-2">Finalize</span>
        </button>
        <button (click)="openDialogPreview()"
          class="confirmPreview relative flex items-center justify-center text-vistrace-primary hover:text-white">
          <div class="imageDiv relative w-10 h-10">
            <img src="assets/visibility_FILL0_wght400_GRAD0_opsz24.svg"
              class="defaultImg absolute inset-0 w-full h-full" alt="Default Preview">
            <img src="assets/visibility_FILL0_wght400_GRAD0_opsz24_2.svg"
              class="hoverImg absolute inset-0 w-full h-full opacity-0" alt="Hover Preview">
          </div>
          <span class="pt-2 ">Preview</span>
        </button>
      </div>
    </div>
    <div class="p-3">

      <button class="backButton" (click)="toProducts()">
        <div class="w-1/3 flex justify-end">
          <img src="assets/back.svg" class="h-8" alt="Back">
        </div>
        <span class="w-1/3 text-lg">Back</span>
        <section class="w-1/3"></section>
      </button>
  
    </div>
  </div>