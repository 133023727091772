<div class="w-[280px] h-[280px] rounded-lg shadow-md hover:shadow-lg overflow-hidden"
    [ngClass]="getVariantClasses()">

    

  <main class="flex flex-col items-center h-full p-6 relative">

      <button class="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-900" (click)="removeComponent()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <button class="absolute top-0 left-0 p-2 text-gray-600 hover:text-gray-900" (click)="loadComponent()">
          <mat-icon>remove_red_eye</mat-icon>
      </button>

    <h3 class="text-xl font-semibold text-gray-800 mb-4">{{ title }}</h3>
    
    <div class="flex-grow flex items-center justify-center">
      <ng-container *ngIf="img_src; else iconTemplate">
        <img class="w-32 h-32 object-contain" [src]="img_src" alt="{{ title }}"/>
      </ng-container>
      
      <ng-template #iconTemplate>
        <i *ngIf="icon" [ngClass]="icon" class="text-4xl text-green-600"></i>
      </ng-template>
    </div>
    
    <div class="mt-4 text-center">
      <p class="text-sm text-gray-600">{{ text_description }}</p>
      <p class="text-lg font-medium text-gray-800">{{ text_content }}</p>
    </div>
  </main>
</div>
