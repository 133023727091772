<div class="occupancy-wrapper">
    <div class="fixed-occupancy-container" *ngIf="display" [ngStyle]="getOccupancyContainerStyle()">
        <!---first row/ header-->
        <div class="header-occupancy" [ngStyle]="{width: rowWidth + 'px'}">
            <div class="subject"  [ngStyle]="getSubjectStyle()">
            </div>
            <div *ngFor="let day of days" class="day" [ngClass]="{isToday: day.isToday}" [ngStyle]="{'width': dayWidth + 'px'}"> {{day.display_label}}
            </div>
        </div>
        <div [ngClass]="[scroll? 'scroll' : '']">
            <!---rows-->
            <div *ngFor="let subject of subjects" class="row" (click)="selectRow(subject.subject_id)" [ngClass]="[subject.is_selected ? 'isSelected' : '' , selectTile ? 'selectTile': '']" [ngStyle]="{height: defaultRowHeight + 'px', width: rowWidth + 'px'}">
                <!---first layer in a row-->
                <div class="grid">
                    <div class="subject" [ngStyle]="getSubjectStyle()">
                    </div>
                    <div *ngFor="let day of days" class="day" [ngClass]="{isToday: day.isToday}" [ngStyle]="{'width': dayWidth + 'px'}">
                    </div>
                </div>
                <!---second layer in a row-->
                <div class="tasks">
                    <div class="subject" [ngStyle]="getSubjectStyle()">
                        <div *ngFor="let subject_label of subject.display_label" [title]="subject_label" class="subject-label">
                            {{subject_label}}
                        </div>
                    </div>
                    <div class="tasks-container" [ngStyle]="{width: daysWidth + 'px'}">
                        <div class="day-part" (click)="selectedTile(task.task_id)" [ngClass]="[task.isSelected ? 'isSelected' : '', task.type, selectTile ? 'selectTile' : '', task.subType ]" 
                        [ngStyle]="{left: task.task_left + 'px', width: task.task_width + 'px', height: taskHeight + 'px', top: taskMargin + 'px'}" *ngFor="let task of tasks_data.item(subject.subject_id)">
                            <div *ngFor="let label of task.display_label" [title] ="label" [ngStyle]="{'white-space': 'nowrap', 'text-overflow' : 'ellipsis', 'overflow' : 'hidden', width: task.task_width + 'px'}">{{label}}</div>
                            <mat-icon *ngIf="task.icon" class="day-icon" svgIcon="{{task.icon}}"></mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
