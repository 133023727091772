import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MonthlyOverviewWeek } from '../../types/monthly-overview-week';
import { MonthlyOverviewService} from './../../services/monthly-overview.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FlexibleConnectedPositionStrategyOrigin } from '@angular/cdk/overlay';
import { OccupancySubject } from './../../types/occupancy-subject';
import { OccupancyTask } from './../../types/occupancy-task';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { ElementFinder } from 'protractor';

@Component({
  selector: 'app-monthly-overview',
  templateUrl: './monthly-overview.component.html',
  styleUrls: ['./monthly-overview.component.scss']
})
export class MonthlyOverviewComponent implements OnInit {

  @Input() startDate: string = "";
  @Input() updateDataTrigger = "0";
  @Input() identifier = "";
  @Input() backend = "";
  @Input() firstColumn = 50;
  @Input() rowSelection = false;
  @Input() toggleButton = false;
  @Input() subjectVertical = true;
  @Input() showMonthsAround = true;
  @Input() calendarX = 1400;
  @Input() showActionTasks = false;
  @Input() outsideFilter: string = '';
  @Output() rowSelected = new EventEmitter<{status:boolean, subject_id: string}>();

  public subs: Subscription[] = [];

  public monthlyOverviewWeeks: MonthlyOverviewWeek[]= [];
  public month: string;
  public year: string;
  public subjects: OccupancySubject[] = [];
  public subjects_orig: OccupancySubject[] = [];
  public tasks: OccupancyTask[] = [];
  public action_tasks: OccupancyTask[] = [];
  public days = [];
  public tasks_data = new Dictionary<any>();
  public action_tasks_data = new Dictionary<any>();
  public displaySubject = true;
  public toggleSubjectIcon = "arrow_forward_ios";
  public toggleSubjectTooltip ="Detailansicht zuklappen";

  public defaultRowHeight; // = 40;
  public defaultRowBorderHeight;
  public scrollX = 21;
  public xMargin = 0; //10;
  public taskMargin = 2;
  public border = 1;
  public defaultTaskHeight;
  public rowWidth;
  public daysWidth;
  public dayWidth;
  public daysNumber;

  constructor(private monthlyOverviewService:MonthlyOverviewService) { }

  ngOnInit(): void {
    this.rowWidth = this.calendarX - 2*this.xMargin;
    this.daysWidth = this.rowWidth - this.firstColumn;

    this.subs.push(this.monthlyOverviewService.getCalendarLayout(this.startDate, this.showMonthsAround, this.identifier, this.backend).pipe(filter(msg => msg.args.identifier === this.identifier))
    .subscribe( msg => {
      this.monthlyOverviewWeeks = msg.args.weeks;
      this.daysNumber = msg.args.days_count;
      this.month = msg.args.month;
      this.year = msg.args.year;
      this.dayWidth = (this.calendarX - this.firstColumn - 2*this.xMargin)/this.daysNumber;
    }));

    this.subs.push(this.monthlyOverviewService.getCalendarData(this.startDate, this.identifier, this.backend).pipe(filter(msg => msg.args.identifier === this.identifier))
    .subscribe( msg => {
      var subjects = [];
      msg.args.subjects.forEach(subject => {
        let occupancySubject = OccupancySubject.parseOccupancySubject(subject);
        subjects.push(occupancySubject);
      });
      this.subjects = subjects;
      this.subjects_orig = subjects;
      // console.log(this.subjects);
      // tasks
      var tasks = [];
      msg.args.tasks.forEach(task => {
        let occupancyTask = OccupancyTask.parseOccupancyTask(task);
        tasks.push(occupancyTask);
      });
      this.tasks = tasks;
      // console.log(this.tasks);
      this.getTasks();
      // action tasks
      var action_tasks = [];
      msg.args.action_tasks.forEach(task => {
        let occupancyTask = OccupancyTask.parseOccupancyTask(task);
        action_tasks.push(occupancyTask);
      });
      this.action_tasks = action_tasks;
      // console.log(this.action_tasks);
      this.getActionTasks();
      if (this.showActionTasks) {
        this.defaultRowHeight = 59;
        this.defaultRowBorderHeight = this.defaultRowHeight + this.border;
        this.defaultTaskHeight = (this.defaultRowHeight/2) - 2*this.taskMargin;
      }else{
        this.defaultRowHeight = 34;
        this.defaultRowBorderHeight = this.defaultRowHeight + this.border;
        this.defaultTaskHeight = this.defaultRowHeight - 2*this.taskMargin;
      }
    }));
  
  }

  ngOnChanges(changes: SimpleChanges){
    for (let propName in changes) {
      if (propName == "startDate" && !changes["startDate"].isFirstChange()){
        this.monthlyOverviewService.occupancyMessage(this.startDate, this.showMonthsAround, this.identifier, this.backend);
        this.monthlyOverviewService.occupancyDataMessage(this.identifier, this.backend);
      }
      else if(propName == "updateDataTrigger" && !changes["updateDataTrigger"].isFirstChange()) {
        this.monthlyOverviewService.occupancyDataMessage(this.identifier, this.backend);
      }else if(propName === "outsideFilter") {
        this.applyOutsideFilter(this.outsideFilter);
      }
    }

  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public toggleSubject() {
    this.displaySubject = !this.displaySubject;
    if (this.displaySubject){
      this.toggleSubjectIcon = "arrow_forward_ios";
      this.toggleSubjectTooltip = "Detailansicht zuklappen";
    } else {
      this.toggleSubjectIcon = "arrow_back_ios";
      this.toggleSubjectTooltip = "Detailansicht aufklappen";
    }
  }

  public getTasks():void{
    /*
    return all tasks assigned to a subject
    */
    this.tasks_data.removeAll();
    this.tasks.forEach(task =>{
      console.log(this.dayWidth, task.date_count, this.calendarX, this.firstColumn, this.xMargin, this.daysNumber);
      //  task.task_left = (task.date_count * ((this.calendarX - this.firstColumn - 2*this.xMargin)/this.daysNumber));
       task.task_left = (this.dayWidth * task.date_count) + this.taskMargin;
       task.task_width = (this.dayWidth * task.task_length) - 2*this.taskMargin - this.border;
       if(this.tasks_data.containsKey(task.subject_id)){
         this.tasks_data.item(task.subject_id).push(task);
       }else{
         this.tasks_data.add(task.subject_id, [task]);
       }
     });
    // console.log("..........", this.tasks_data);
  }

  public getActionTasks():void{
    /*
    return all tasks assigned to a subject
    */
    this.action_tasks_data.removeAll();
    this.action_tasks.forEach(task =>{
      // console.log(task.date_count, this.calendarX, this.firstColumn, this.xMargin, this.daysNumber);
      //  task.task_left = (task.date_count * ((this.calendarX - this.firstColumn - 2*this.xMargin)/this.daysNumber));
       task.task_left = (this.dayWidth * task.date_count) + this.taskMargin;
       task.task_width = (this.dayWidth * task.task_length) - 2*this.taskMargin - this.border;
       if(this.action_tasks_data.containsKey(task.subject_id)){
         this.action_tasks_data.item(task.subject_id).push(task);
       }else{
         this.action_tasks_data.add(task.subject_id, [task]);
       }
     });
    // console.log("..........", this.action_tasks_data);
  }

  // first column style
  public getSubjectStyle() {
    return {
      // width alone did not work
      'min-width': this.firstColumn + 'px',
      'max-width': this.firstColumn + 'px'}
  }

  public getWeekWidth(length: number){
    return this.dayWidth*length + 'px';
  }

  public selectRow(subject_id: string) {
    /*
    select/diselect a task
    */
   if(this.rowSelection){
    this.subjects.forEach(subject => {
      if (subject.subject_id == subject_id && !subject.is_selected){
        subject.is_selected = true;
      }else {
        subject.is_selected = false;
      }
    });
    var clicked_subject = this.subjects.find(subject => subject.subject_id === subject_id);
    this.rowSelected.emit({status: clicked_subject.is_selected,subject_id: subject_id });
   }
  }

  applyOutsideFilter(value: string) {
    // this.subjects = this.subjects_orig.filter(subject => subject.display_label.find(label => label.trim().toLowerCase().includes(value.trim().toLowerCase())));
    this.subjects = this.subjects_orig.filter(subject => subject.display_label.some(label => label.trim().toLowerCase().includes(value.trim().toLowerCase())));
  }

}
