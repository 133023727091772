<div class="bounding" 
  [ngStyle]="{'width': width +'px', 'height': height +'px'}" 
  [matTooltip]="textToolTipDisable" 
  [matTooltipDisabled]="!disabled || textToolTipDisable.length == 0">   
  <button *ngIf="!isFilled && title" mat-stroked-button class="button-extra-styles" type="button"
    [ngClass]="{'button-secondary-action': colorStyle=='action', 
                'button-secondary-brand': colorStyle=='brand',
                'button-secondary-lightbrand': colorStyle=='lightbrand',
                'button-secondary-lightgrey': colorStyle=='lightgrey',
                'button-secondary-default': colorStyle=='default',
                'enabled': disabled==false
              }" 
    [disabled]="disabled" (click)="onClick()" 
    [ngStyle]="{'width': width +'px', 'height': height +'px'}"
    [matTooltip]="textToolTip"
    [matTooltipDisabled]="disabled"
    >
    <div style="align-self: center;" *ngIf="title">
      {{ (title.length>shorten_title && shorten_title)? (title | slice:0:shorten_title/2)+'...'+(title | slice:title.length-shorten_title/2:title.length):(title) }}
    </div>
   
    <mat-icon *ngIf="iconName" iconPositionEnd>{{iconName}}</mat-icon>
    <mat-icon *ngIf="svgIcon" iconPositionEnd [ngClass]="disabled==false ? 'active-icon' : 'inactive-icon'" svgIcon="{{svgIcon}}"></mat-icon>
  </button>

  <button *ngIf="!isFilled && !title" mat-icon-button class="button-extra-styles" type="button"
    [ngClass]="{'button-secondary-action': colorStyle=='action', 
                'button-secondary-brand': colorStyle=='brand',
                'button-secondary-lightbrand': colorStyle=='lightbrand',
                'button-secondary-lightgrey': colorStyle=='lightgrey',
                'button-secondary-default': colorStyle=='default',
                'enabled': disabled==false
              }" 
    [disabled]="disabled" (click)="onClick()" 
    [ngStyle]="{'width': width +'px', 'height': height +'px'}"
    [matTooltip]="textToolTip"
    [matTooltipDisabled]="disabled"
    >   
    <mat-icon *ngIf="iconName">{{iconName}}</mat-icon>
    <mat-icon *ngIf="svgIcon" [ngClass]="disabled==false ? 'active-icon' : 'inactive-icon'" svgIcon="{{svgIcon}}"></mat-icon>
  </button>

  <button *ngIf="isFilled && title" mat-flat-button class="button-extra-styles button-filled"  type="button"
    [ngClass]="{'button-primary-action': colorStyle=='action', 
                'button-primary-brand': colorStyle=='brand',
                'button-primary-lightbrand': colorStyle=='lightbrand',
                'button-primary-lightgrey': colorStyle=='lightgrey',
                'button-primary-default': colorStyle=='default',
                'enabled': disabled==false
              }" 
    [disabled]="disabled" (click)="onClick()"
    [ngStyle]="{'width': width +'px', 'height': height +'px'}"
    [matTooltip]="textToolTip"
    [matTooltipDisabled]="disabled"
    >
    <div style="align-self: center;" *ngIf="title">
      {{title}}
    </div>
    <mat-icon *ngIf="iconName" iconPositionEnd>{{iconName}}</mat-icon>
    <mat-icon *ngIf="svgIcon" iconPositionEnd [ngClass]="disabled==false ? 'active-icon' : 'inactive-icon'" svgIcon="{{svgIcon}}"></mat-icon>
  </button>

  <button *ngIf="isFilled && !title" mat-icon-button class="button-extra-styles button-filled"  type="button"
    [ngClass]="{'button-primary-action': colorStyle=='action', 
                'button-primary-brand': colorStyle=='brand',
                'button-primary-lightbrand': colorStyle=='lightbrand',
                'button-primary-lightgrey': colorStyle=='lightgrey',
                'button-primary-default': colorStyle=='default',
                'enabled': disabled==false
              }" 
    [disabled]="disabled" (click)="onClick()"
    [ngStyle]="{'width': width +'px', 'height': height +'px'}"
    [matTooltip]="textToolTip"
    [matTooltipDisabled]="disabled"
    >
    <mat-icon *ngIf="iconName">{{iconName}}</mat-icon>
    <mat-icon *ngIf="svgIcon" [ngClass]="disabled==false ? 'active-icon' : 'inactive-icon'" svgIcon="{{svgIcon}}"></mat-icon>
  </button>
</div>

