import { Injectable } from '@angular/core';

const cancel_title = {
  "DE": 'Abbrechen',
  "EN": 'Cancel'
}

@Injectable({
  providedIn: 'root'
})
export class TextService {
  public cancel_title;
  public limit_of_input = 5000;

  constructor() {
    this.cancel_title = cancel_title;
   }

}
