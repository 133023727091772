<div class="w-full flex flex-row bg-white rounded border-2 border-gray-500 p-6">
    <div class="w-1/3 ml-1 mr-1">
        <p class="text-s text-gray-400 mb-1 mt-1">Template Style</p>
        <select class="w-50 border border-gray-400 rounded px-2 py-1 mb-1 text-sm cursor-pointer">
            <option value="claro">Claro</option>
            <option value="oscuro">Oscuro</option>
            <option value="crystal">Crystal</option>
        </select>
    </div>
    <div class="w-1/3 flex justify-start items-center">
        <img src="assets/trash.svg" class="h-8 ml-2 cursor-pointer" alt="SVG 1">
        <img src="assets/eye.svg" (click)="openDialog()" class="h-8 ml-2 cursor-pointer" alt="SVG 2">
        <img src="assets/640px-Black_Lock.svg.png" class="h-8 ml-2 cursor-pointer" alt="SVG 3">
    </div>
    <div class="w-1/3 flex justify-end items-center">
        <div class="h-8 w-8 bg-green-500 rounded-full mr-5 cursor-pointer"></div>
        <div class="h-8 w-8 bg-blue-500 rounded-full mr-5 cursor-pointer"></div>
        <div class="h-8 w-8 bg-yellow-500 rounded-full mr-5 cursor-pointer"></div>
    </div>
</div>