export class OccupancyDay {

    static parseOccupancyDay(occupancyDayObject) {
        const occupancyDayItem = new OccupancyDay(
            occupancyDayObject.display_label,
            occupancyDayObject.day_id,
            occupancyDayObject.isToday,
        );
        return occupancyDayItem;
    }
  
    constructor(
      public display_label: string,
      public day_id: string,
      public isToday: boolean,
    ) { }

  }
