import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: Stripe | null = null;
  private publishableKey: string = 'pk_test_51J3k8vKdBghYZ0A8VVCBErEapgorIecJwW99dYTZ8xC7ZdkHEENEeRb4kQsv0uutdoy0z9Y6vh1Mzw6ptfw2Oryz00XVCB9mXm';

  constructor() {
    this.initializeStripe();
  }

  async initializeStripe() {
    console.log("Initializing Stripe...");
    try {
      this.stripe = await loadStripe(this.publishableKey);
      console.log("Stripe initialized successfully", this.stripe);
    } catch (error) {
      console.error("Error initializing Stripe:", error);
    }
  }

  getStripe(): Stripe | null {
    return this.stripe;
  }
}
