<!--Popup window-->

<mat-card appearance="outlined" class="chart-data-container" [ngStyle]="{'height': height + 'px'}" (mouseenter)="cardEnter($event)" (mouseleave)="cardLeave($event)"
  [ngClass]="{'background-accent': isSelected, 'mat-elevation-z2': !hover, 'mat-elevation-z8': hover}">
  <mat-card-header *ngIf="!headerless">
    <!--mat-icon mat-card-avatar>home</mat-icon-->
    <div mat-card-avatar class="metal" *ngIf="!noicon">
        <mat-icon>insert_chart_outlined</mat-icon>
    </div>
    <button class="top-action" *ngIf="!frozen" mat-icon-button (click)="onDelete()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-card-title>{{chartTitle}}</mat-card-title>
    <mat-card-subtitle>{{chartSubTitle}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="plot-container" [id]="chartId"></div>
    <div *ngIf="!chartLoaded">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card-content>
  
</mat-card>
