<mat-card appearance="raised" style="background-color: #f5f5f5; border-radius: 20px;" *ngIf="data">
  <mat-card-title class="title-card-xxl-primary-500">FAQ</mat-card-title>    
  <mat-card-header *ngIf="data.title">
    <mat-card-title>{{data.title}}</mat-card-title>
  </mat-card-header>
  <!-- here we probably need a padding: 16px -->
  <mat-accordion>
    <mat-expansion-panel *ngFor="let element of data.content">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <!-- <mat-icon class="add-icon">add</mat-icon> -->
          <div class="title-card-s-secondary-500">
            {{element.question}}
          </div>
        </mat-panel-title>
        <!-- <mat-panel-description>
          Do we need a description?
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <div *ngFor="let ans of element.answer">
        <p class="text-s-secondary-400" *ngIf="ans.text" style="white-space: pre-line; line-height: 1.7;">{{ans.text}}</p>
        <span><img *ngFor="let image of ans.img" [ngStyle]="{'width':image.width?image.width:'10%'}" [src]="'/assets/faq/'+image.name" alt="no img available"></span>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>