
  <div class="flex flex-1 overflow-hidden lg:px-[4%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full flex-shrink-0">
      <app-side-menu></app-side-menu>
    </aside>
    <main class="flex-1 p-4 h-full overflow-y-auto overflow-x-hidden">
      <section>
        <div class="w-full grid-container">
          <div *ngFor="let element of components">
            <app-preview-card 
              [title]="element.key"
              text_description="Expiring date:"
              text_content="16/09/2024"
              [img_src]="element.url">
            </app-preview-card>
          </div>
        </div>
      </section>
    </main>
    <!-- StatisticCard -->
    <div class="h-full flex flex-col justify-between w-64 bg-gray-100 p-4">

      <app-right-column-card class="h-[500px]"></app-right-column-card>


    </div>
  </div>

  


