import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import { TextService } from '../../services/text.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  private cancel_title: string;
  constructor(
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, message, name
    private projectSetupService: ProjectSetupService,
    private textService: TextService
  ) { }

  ngOnInit(): void {
    this.cancel_title = this.textService.cancel_title[this.projectSetupService.language];
    if (! this.data.text_cancel_button) {
      this.data.text_cancel_button = this.cancel_title;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, false);
  }

  onOk() {
    //this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, true);
  }

}
