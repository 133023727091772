import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { MessageService } from '../shared-generic/services/message.service';
import { DialogLoginService } from '../shared-generic/services/dialog-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private messageService: MessageService,
    private loginService: DialogLoginService
  ) {
    super(router, keycloak);
  }
  
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    
      if (!this.authenticated) {
        if (this.loginService.loginMode === "keycloak") {
          await this.keycloak.login({
            // in our case the redirection is done in the app
            redirectUri: window.location.origin, //+ state.url,
          });
        } else {
          return true;
        }
      }
      return this.authenticated;
  }

  async getToken() {
    const token = await this.keycloak.getToken();
    console.log("TOKEN", token);
  }
}
