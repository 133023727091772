import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { UpdateService } from 'src/app/shared-generic/services/update.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = '';
  password: string = '';
  mode = "login";
  autologin = false;
  public subs: Subscription[] = [];
  constructor(
    public msgService: MessageService,
    public router : Router,
    public messageService: MessageService,
    public loginService: DialogLoginService,
    public _Activatedroute: ActivatedRoute,
    public updateService: UpdateService,
    private spinnerService: NgxSpinnerService,
    private dialogLoginService: DialogLoginService,
    private uiInfoService : UiInfoService
    //public router: Router
    ) { }

  onSubmit(): void {
    // // NOTE only necessary call to login is:
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailPattern.test(this.email)) {
    //   this.uiInfoService.openSnackbar("You must enter a valid email", 3000);
    // } else if (this.password.length < 8) {
    //   this.uiInfoService.openSnackbar("Password must be at least 8 characters long", 3000);
    // } else {
      this.dialogLoginService.login(this.email, this.password);
    // }
  }


  ngOnInit(): void {
    
    this.subs.push(this._Activatedroute.paramMap.subscribe(params => { 
      this.mode = params.get('id'); 
      this.loginService.shouldRun.next(false);
      if (this.mode == "login") {
        this.startNewLogin(); 
      }  else if (this.mode == "autologin") {
        this.autologin = true;
        const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
        console.log("in login view with autologin");
      } else if (this.mode == "wait") {
        const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
      } else {
        this.startNewLogin(); 
      }
    }));

    this.subs.push(this.updateService.subscribe((upd: string) => {
      if (upd.split(" ")[1]===this.messageService.final_auth_backend) {
        if (upd.startsWith('failedAuth')) {
          // get some failed auth information before....
          this.startNewLogin();
        } 
      }
    }));

    
  }


  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public startNewLogin():void {
  }

  toRegister() {
    this.router.navigateByUrl("/register")
  }

  toPasswordRecover() {
    this.router.navigateByUrl("/password-recover")
  }

}
