<div class="flex h-screen justify-center items-center">
    <div class="p-8 rounded shadow-md w-80">
        <h2 class="text-2xl font-semibold mb-4">Password Recovery</h2>
        <form>
            <div class="mb-4">
                <label for="username" class="block text-sm font-medium text-gray-700">Email</label>
                <input type="text" name="username" [(ngModel)]="email" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <button (click)="onSubmit()" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-vistrace-quinary">Send email</button>
            <div class="mt-4 text-center">
                <a (click)="toLogin()" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Go back to login</a><br>
            </div>
        </form>
    </div>
</div>