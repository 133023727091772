import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from '../types/message';

@Injectable({
  providedIn: 'root'
})

export class TableDataService {
  private subscriptions = [];
  public activeTabIndex = 0;
  public tableDataSubject =  new Subject<Message>();
  public cardDataSubject = new Subject<Message>();
  public fieldDataSubject = new Subject<Message>();
  public tableInputFormatSubject =  new Subject<Message>();
  public editableTableSubject =  new Subject<Message>();
  // public unselectLastSubject =  new Subject<string>();
  public singleSelectionSubject =  new Subject<{table: string, entity: string, status: boolean, row: {}}>();
  public singleSelectionContextSubject =  new Subject<{table: string, context:string, status: boolean, row: {}}>();
  public selectRowSubject = new Subject<{table: string, row: {}}>();
  public tableValidSubject =  new Subject<{table: string, status: boolean}>();

  constructor(private messageService: MessageService) { 
  }

  public changeTableData(tablename: string, data: any, dataOld: any, backend: string) {
    const message = {
      name: 'changeTableData',
      args: [tablename, data, dataOld]
    };
    this.messageService.sendMsg(message, backend);
  }
  
public copyRow(row: {}) {
  const rowOut = {};
  for (const datename of Object.keys(row)) {
    rowOut[datename] = row[datename];
  }
  return rowOut;
}

public getEditableTables(backend: string): Subject<Message> {
  const callbackId = 'editabletable';
  if (this.subscriptions.indexOf(callbackId) === -1) {
    this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
      .subscribe(
        msg => {
          this.editableTableSubject.next(msg);
        });
    this.subscriptions.push(callbackId);
  }
  const message = {
      name: 'getEditableTables',
      args: [callbackId]
  };
  this.messageService.sendMsg(message, backend);
  return this.editableTableSubject;
}

public selectRow(tablename: string, row: any, isSelected: boolean, backend: string) {
  const message = {
    name: 'selectTableDataRow',
    args: [tablename, row, isSelected]
  };
  this.messageService.sendMsg(message, backend);
}

public getTable(tablename: string, entity: string, backend: string): Subject<Message> {
  const callbackId = 'tabledata/' + backend + "/" + tablename + entity;
  if (this.subscriptions.indexOf(callbackId) === -1) {
    this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
      .subscribe(
        msg => {
          this.tableDataSubject.next(msg);
        });
    this.subscriptions.push(callbackId);
  }
  const message = {
      name: 'getTableData',
      args: [tablename, entity, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  // console.log("tableDataSubject: ", this.tableDataSubject);
  return this.tableDataSubject;
}

public getFieldData(fieldId: string, backend: string): Subject<Message> {
  const callbackId = 'fielddata/' + backend;
  if (this.subscriptions.indexOf(callbackId) === -1) {
    this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
      .subscribe(
        msg => {
          this.fieldDataSubject.next(msg);
        });
    this.subscriptions.push(callbackId);
  }
  const message = {
      name: 'getFieldData',
      args: [fieldId, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  return this.fieldDataSubject;
}

public getDataItems(dataId: string, backend: string): Subject<Message> {
  const callbackId = 'carddata/' + backend;
  if (this.subscriptions.indexOf(callbackId) === -1) {
    this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
      .subscribe(
        msg => {
          this.cardDataSubject.next(msg);
        });
    this.subscriptions.push(callbackId);
  }
  const message = {
      name: 'getCardData',
      args: [dataId, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  return this.cardDataSubject;
}

public getTableInputFormat(tablename: string, backend: string): Subject<Message> {
  const callbackId = 'tableinputformat/' + backend;
  if (this.subscriptions.indexOf(callbackId) === -1) {
    this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
      .subscribe(
        msg => {
          this.tableInputFormatSubject.next(msg);
        });
    this.subscriptions.push(callbackId);
  }
  const message = {
      name: 'getTableInputFormat',
      args: [tablename, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  return this.tableInputFormatSubject;
}


public getGroups(callbackId: string, backend: string): Subject<Message> {
  const message = {
    name: 'getGroups',
    args: [callbackId]
  };
  this.messageService.sendMsg(message, backend);
  return this.messageService.awaitMessage(backend)
}

// public unselectLast(table: string): void {
//   this.unselectLastSubject.next(table);
// }

  public setTableStatus(table: string, entity: string, status: boolean, row: {}): void {
    this.singleSelectionSubject.next({table: table, entity:entity, status: status, row: row});
  }

  public setTableStatusContext(table: string, context: string, status: boolean, row: {}): void {
    this.singleSelectionContextSubject.next({table: table, context: context, status: status, row: row});
  }

  // select row
  public selectTableRow(table: string, row: {}): void {
    this.selectRowSubject.next({table: table, row: row});
  }

  public setSingleStatus(tableName: string, status: boolean): void {
    // used to enable navigation (forward) between views
    this.tableValidSubject.next({table: tableName, status: status});
  }

}
