import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  constructor(
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, message
  ) { }

  ngOnInit(): void {
  }

  // onCancel(): void {
  //   this.dialogRef.close();
  //   this.dialogService.dialogStatus(this.data.name, false);
  // }

  onOk() {
    this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, true);
  }

}
