<div class="flex flex-col h-full">
  <div class="flex flex-1 lg:px-[4%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full">
      <app-side-menu></app-side-menu>
    </aside>

    <div class="flex flex-1 flex-col items-center w-full h-full p-4">
      <div class="w-full flex justify-center mb-4 mt-0 border-b-2 border-solid border-vistrace-hover">
        <button class="tab-btn mr-4" (click)="activeTab = 1" [class.active]="activeTab === 1">Personal details</button>
        <button class="tab-btn mr-4" (click)="activeTab = 2" [class.active]="activeTab === 2">Account details</button>
        <button class="tab-btn mr-4" (click)="activeTab = 3" [class.active]="activeTab === 3">Billing info</button>
        <button class="tab-btn"      (click)="activeTab = 4" [class.active]="activeTab === 4">Invoices</button>
      </div>
      <!-- First Tab content -->
      <section *ngIf="activeTab === 1" class="w-full pl-40 pr-40"> 
        <app-generic-form *ngIf="userinfo_key"
            [xname]="'settings-personal-form'" 
            [entity]="userinfo_key"
            [backend]="'main'" 
            [col_number]="2" 
            [frozen]="true" 
            [justifyContent]="'space-around'"
            [focusFirst]="true"
            [require_finish]="true"
            (onChange)="genericData($event)"
            [boundingBox]="false">
        </app-generic-form>
        <label class="flex items-center pl-8">
          <input type="checkbox" class="form-checkbox h-6 w-6 mr-2">
          <span class="text-gray-600">I agree to receive news, product updates and event emails from QuantecDC. I can withdraw my consent at any time</span>
        </label>
        <label class="pl-8">To learn how QuantecDC processes your data, see QuantecDC <span class="text-blue-500 underline cursor-pointer">privacy policy</span></label><br>
        <button (click)="updatePersonalData()" class="w-1/4 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl">
          Update personal data
        </button>
      </section>
      <!-- Second Tab content -->
      <section *ngIf="activeTab === 2" class="w-full pl-40 pr-40">
        <!--section class="flex items-center h-1/4 bg-blue-200 rounded-xl mb-10 p-10 cursor-pointer">
        </section-->
        <section class="flex flex-col justify-center w-full h-1/4 rounded-xl cursor-pointer mb-10 p-10 border-2 border-solid border-gray-400">
          <p class="text-vistrace-primary text-3xl font-bold">Professional plan</p>
        </section>
        <section class="flex justify-center">
          <label>Learn more about account management in the <span class="text-blue-500 underline cursor-pointer" (click)="toSubscriptions()">Subscription</span></label>
        </section>
        <app-customer-payments-profile-component></app-customer-payments-profile-component>
      </section>
      <!-- Third Tab content -->
      <section *ngIf="activeTab === 3" class="w-full pl-40 pr-40">
        <app-generic-form *ngIf="userinfo_key"
            [xname]="'settings-billing-form'" 
            [entity]="company_key"
            [backend]="'main'" 
            [col_number]="2" 
            [frozen]="true" 
            [justifyContent]="'space-around'"
            [focusFirst]="true"
            [require_finish]="true"
            (onChange)="genericBillingData($event)"
            [boundingBox]="false">
        </app-generic-form>
        <button (click)="updateBillingData()" class="w-1/4 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold ml-8 py-2 rounded-xl">
          Update billing information
        </button>
      </section>
      <!-- Fourth Tab content -->
      <div *ngIf="activeTab === 4" class="p-5">
        <app-database-table [xname]="invoice_table"
          [onFilter]="true"
          [backend]="'main'"
          [enabled]="false"
          [row_key]="'invoice_number'"
          [page_size]="6"
          [onEditor]="false">
        </app-database-table>
      </div>
    </div>
  </div>
</div>
