import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.scss']
})
export class MobilePreviewComponent implements OnInit, OnChanges, AfterViewInit {
  public onLoading = false;

  @Input() mobile_url: string

  @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;
  public loaded: boolean = false

  // NgxSpinnerService and mat-spinner are different things
  constructor(private spinnerService: NgxSpinnerService,
    private chatService: ChatService) { }

  ngAfterViewInit(): void {
    if (this.iframe) {
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"];
      const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
    }
  }

  ngOnInit(): void {
    if (this.iframe) {
      //console.log(this.mobile_url)
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"]
      this.onLoading = true;
      this.chatService.onLoadingPreview = true;
      const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
    }
  }

  ngOnChanges() {
    if (this.iframe) {
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"]
      this.onLoading = true;
      this.chatService.onLoadingPreview = true;
      const prom = this.spinnerService.show();
        prom.then(value => {
          console.log('resolved', value);
        });
    }
  }

  uploadDone() {
    console.log("render mobile done");
    this.onLoading = false;
    this.chatService.onLoadingPreview = false;
    const prom = this.spinnerService.hide();
        prom.then(value => {
          console.log('resolved', value);
        });
  }


}
