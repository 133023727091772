import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { SessionService } from 'src/app/ui-main/services/session-service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(
    private router: Router,
    public msgService: MessageService,
    public sessionService: SessionService,
    private chatService: ChatService
  ) { }

  ngOnInit(): void {
  }

  toHome() {
    this.router.navigateByUrl("/main/products")
  }

  logout() {
    this.msgService.disconnect();
    // remove messages:
    this.chatService.messages = []
  }

}
