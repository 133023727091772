<!--Popup window-->
<!--div class="chart-tab-fixed" [style.width.px]="1400 + dropService.delX" -->
<div class="chart-tab-fixed">
  <div class="chart-row" *ngFor="let col of getCols()"
      id="{{col}}">
    <div *ngFor="let id of chartFieldData[col]" class="draggable">        
        <app-chart-data 
          [replot]="replot"
          [updateAll]="updateAll"
          [backend]="backend" 
          [frozen]="frozen"
          [noicon]="noicon"
          [headerless]="headerless" 
          [col]="col" 
          (action)="onAction($event)" 
          [chartId]="id"
          [height]="height">
        </app-chart-data>
    </div>    
  </div>
</div>