import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';


@Pipe({ 
  name: 'cell' 
})
export class CellPipe implements PipeTransform {
  datePipe = new DatePipe("de-DE");
  currencyPipe = new CurrencyPipe("de-DE");
  decimalPipe = new DecimalPipe("de-DE");
  constructor() {}
  transform(x: string, detail?: string) {
    if (!detail) {
        return x;
    }
    if (detail==="DATE") {
        x = this.datePipe.transform(x);
    } else if (detail==="DATETIME") {
        x = this.datePipe.transform(x, "short");
    } else if (detail==="EUR") {
        x = this.currencyPipe.transform(x, "EUR");
    } else if (detail.startsWith("NUM")) { // expects NUM-2-km/h, if no unit use NUM-2
        const items = detail.split("-")
        const digits = items[1];
        var unit = '';
        if (items.length===3) unit = ' ' + detail.split("-")[2];
        const format = `1.${digits}-${digits}`;
        if (x === undefined || x === null) {
          x = '';
        } else if (x !== "" && x !== "-") {
          x = this.decimalPipe.transform(x, format) + unit;
        }        
    }
    return x;
  }
} 