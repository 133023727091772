import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LayoutService } from './layout.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';
import { CookieService } from 'ngx-cookie';
import { PinCodeComponent } from '../components/pincode/pincode.component';
import { UiInfoService } from './ui-info-service';


@Injectable({
  providedIn: 'root'
})
export class DialogLoginService {
  private subscriptions = [];
  secret_number = '';
  mode = "login";
  app = "addimap"; 
  route = '/set_username_server';
  redirect_token = "";
  // default is true
  public loginMode = "";
  public apps: Array<string> = [];

  public resetSubject: Subject<string> = new Subject();
  public loginSubject: Subject<string> = new Subject();
  
  public shouldRun: Subject<boolean> = new Subject();
  public finalize_subject: Subject<any> = new Subject();
  public loginErrorSubject =  new Subject<string>();
  public closeSubject =  new Subject();
  public connectSubject =  new Subject<{username: string, password: string}>();
  public loginModeSubject =  new Subject();
  public loginAuthSubject =  new Subject();

  constructor(private httpClient: HttpClient,
    private layoutService: LayoutService,
    private projectSetupService: ProjectSetupService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    ) { }

  public loginError(error: string): void {
    this.loginErrorSubject.next(error);
  }

  public close_dialog(): void {
    this.closeSubject.next('');
  }

  public login_mode_available(status): void {
    console.log("login mode now available", status);
    this.loginModeSubject.next(status);
    // NOTE: through the delay we can simulate server delay?!
    //setTimeout(()=>this.loginModeSubject.next(status),250);
  }

  public async set_login_mode(): Promise<any> {
    var prom = new Promise((resolve, reject) => {
      // NOTE: if there is already a loginMode we can resolve immediately
      if (this.loginMode != "") {
        resolve(this.loginMode);
      }
      this.loginModeSubject.subscribe((status)=>{
        if (status) {
          resolve(this.loginMode);
        } else {
          // Not implemented yet on message.service side
          reject("error in loginModeSubject");
        }
      })
    });
    return prom;
  }

  public login(username: string, password: string): void {
    console.log("IN service start the login", username);
    username = username?.split(' ').join('_');
    setTimeout(()=> this.connectSubject.next({username: username, password: password}),
      200);
    // is done where?
    //this.login_finalize(username);
  }

  call_endpoints(username: string, token: string, final_urls, pin2fa) {
    console.log("PIN", pin2fa, username);
    final_urls.forEach(element => {
      this.httpClient.get(element + this.route + '?username=' + username + '&token=' + token + '&pin=' + pin2fa).subscribe(
        resp => {
          if (resp["pin_approved"]) {
            let date: Date = new Date(new Date().getTime()+(5*24*60*60*1000)); // 5 days plus
            this.cookieService.put('redirect-token', resp['redirect_token'], {expires: date});
            this.projectSetupService.pinApproved = true;
          } else {
            this.projectSetupService.pinApproved = false;
          }
          if (!username.startsWith("anonymous")) {
            this.projectSetupService.loginApproved = true;
            this.projectSetupService.username = username;
            // console.log("LOGIN APPROVED", username);
            this.finalize_subject.next({"login": true, "token": token});
          } else {
            this.projectSetupService.loginApproved = false;
            this.finalize_subject.next({"login": false, "token": token});
          }
        }
      );
    });
    
  }

  public login_finalize(username: string, token: string, final_urls, do2fa) {
    // console.log("get login finalize", username, token);
    // console.log(final_urls);
    // 2FA input here
    if ((do2fa) && (!username.startsWith("anonymous"))) {
      const dialogRef = this.dialog.open(PinCodeComponent, {
        disableClose: true,
        width: '380px',
        data: { username: username}
      });
      dialogRef.afterClosed().subscribe( data => {
        this.call_endpoints(username, token, final_urls, data.pin);
      })
    } else {
      this.call_endpoints(username, token, final_urls, null);
    }
    
    return this.finalize_subject;
    
  }
}
