import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetupService {
  public loginApproved = false; // switched to true if not username anonymous123432 any more
  public language = "DE";  // EN/DE/ESP/POR/POL
  public locale = "de-DE"; // en-US/de-DE
  public currency = "EUR"; // USD
  public username = "";
  public mode = "addiplan";      // mode can be addimap or addibase (or addiplan)
  public pinApproved = true;
  public constructor() {
  }
}
