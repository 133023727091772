import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { BokehService } from '../../services/bokeh.service';
import { moveItemInArray, transferArrayItem, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DropService } from '../../services/drop-service';

@Component({
    selector: 'app-chart-data-field',
    templateUrl: './chart-data-field.component.html',
    styleUrls: ['./chart-data-field.component.scss']
})
export class ChartDataFieldComponent implements OnInit, OnDestroy {
    @Input() chartFieldId = 'default';
    @Input() targetFieldId = 'default';
    @Input() backend = 'offer-creator';
    @Input() frozen: boolean = false;
    @Input() headerless: boolean = false;
    @Input() replot = '';
    @Input() updateAll = '';
    @Input() noicon: boolean = false;
    @Input() height= "450";

    public wh = window.innerHeight;
    public chartFieldData = {};
    public subs: Subscription[] = [];
    constructor(private bokehService: BokehService, public dropService: DropService, private dialog: MatDialog) { }
    ngOnInit() {
        this.subs.push(this.bokehService.getChartFieldData(this.chartFieldId, this.backend).pipe(filter(msg => msg.args.chartFieldId === this.chartFieldId))
            .subscribe(msg => {
                if (!msg.args.chartFieldData) {
                    return;
                }
                console.log('chartFieldData...', msg);
                this.chartFieldData = msg.args.chartFieldData;
                this.dropService.targetIds.next(this.chartFieldData);
            }));
    }
    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer.id === 'tree-1') {
            const chartname = event.item.data.name;
            const dropContainer = event.container.id;
            const dropIdx = event.currentIndex;
            console.log(chartname);
            // check if chart is already in dashboard
            // if yes refuse the transport !!!
            this.getCols().forEach(key => {
                const idx = this.chartFieldData[key].indexOf(chartname);
                if (idx > -1) {
                    console.log("already in");
                    this.chartFieldData[key].splice(idx, 1);
                    return;
                }
            });
            if (this.chartFieldData[dropContainer].indexOf(chartname) > -1) {
                const delIdx = this.chartFieldData[dropContainer].indexOf(chartname);
                this.chartFieldData[dropContainer].splice(delIdx, 1);
            }
            this.chartFieldData[dropContainer].splice(dropIdx, 0, chartname);
            console.log(this.chartFieldData);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
    }
    getCols() {
        return Object.keys(this.chartFieldData);
    }
    onAction($event) {
        console.log($event);
        const actionArray = $event.split(' ');
        if (actionArray[0] === 'delete') {
            const colId = actionArray[1];
            const delIdx = this.chartFieldData[colId].indexOf(actionArray[2]);
            // console.log()
            if (delIdx > -1) {
                this.chartFieldData[colId].splice(delIdx, 1);
            }
        }
        console.log(this.chartFieldData);
    }
    connectedTo() {
        return [this.targetFieldId];
    }
    ngOnDestroy() {
        this.subs.forEach(subs => subs.unsubscribe());
    }
  
    ngOnChanges(changes: SimpleChanges) {
      for (let propName in changes) {
        if(propName === "replot"  && this.chartFieldId !== 'default' && this.replot !='') {
          console.log("replot ChartDataFieldComponent", this.chartFieldId);
        }
      }
    }
}
