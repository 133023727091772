import { Component, OnInit, Input } from '@angular/core';
import { ParameterCardHeader } from '../../types/parameter-card-header';

@Component({
  selector: 'app-parameter-card-header',
  templateUrl: './parameter-card-header.component.html',
  styleUrls: ['./parameter-card-header.component.scss']
})
export class ParameterCardHeaderComponent implements OnInit {

  @Input() parameterCardHeader: ParameterCardHeader;
  @Input() isLoggedIn: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
