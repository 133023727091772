<h3 mat-dialog-title class="dialog-title">{{data.title}}</h3>
<div mat-dialog-content class="dialog-content">
    <app-database-table 
        [xname]="data.table_name" 
        [backend]="data.backend"
        [singleSelection]="true"
        [onEditor]="false"
        [onFilter]="data.onFilter">
    </app-database-table>
</div>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()">{{data.text_cancel_button}}</button>
    <button mat-button [disabled]="!rowSelected" (click)="onOk()">{{data.text_confirm_button}}</button>
    <!-- cdkFocusInitial -->
</div>