import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';

@Injectable({
  providedIn: 'root'
})
export class OccupancyDataService {
  private subscriptions = [];
  public occupancyDataSubject =  new Subject<Message>();

  constructor(private messageService: MessageService) { }

  public getOccupancy(occupancyName: string, startDate:string, daysNumber: number, backend: string): Subject<Message>  {
    const callbackId = 'occupancydata/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            console.log("occupancy data service", callbackId);
            this.occupancyDataSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    } 
    // next line is the initial data getter (is not necessary, since the onChanges immediately calls the getter also)
    // initial data getter is back again, as we avoid to call ngOnChanges if it is called the first time
    this.occupancyMessage(occupancyName, startDate, daysNumber, backend, "init");
    return this.occupancyDataSubject;
  }

  public occupancyMessage(occupancyName: string, startDate:string, daysNumber: number, backend: string, source: string){
    const callbackId = 'occupancydata/' + backend;
    const message = {
      name: 'getOccupancyData',
      args: [occupancyName, startDate, daysNumber, callbackId]
  };
  // console.log("Source occupancy msg", source);
  this.messageService.sendMsg(message, backend);
  }
}
