import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CodeModel } from '@ngstack/code-editor';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { ChatBotComponent } from 'src/app/shared/vistrace-components/chat-bot/chat-bot.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscriptions = new Dictionary<Subscription>();
  public openChatWindow: boolean = false;
  public components: any = [];
  constructor(public dialog: MatDialog,
      private messageService: MessageService
    ) { }


  ngOnInit(): void {
    const callbackId = "setComponents";
    this.subscriptions.add(callbackId,
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackId))
        .subscribe((msg) => {
          console.log("GET COMP", msg.args);
          this.components = msg.args.components;
        }));
    const msg = {
      name: 'getComponents',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  ngOnDestroy() {
    this.subscriptions.keys().forEach(callbackId =>
       this.subscriptions.item(callbackId).unsubscribe())
  }

  openChatBot(): void {
    this.openChatWindow = !this.openChatWindow;
  }

  theme = 'vs-light';

  model: CodeModel = {
    language: 'html',
    uri: 'index.html',
    value: '<div>\n  <!-- Your HTML code here -->\n</div>'
  };

  options = {
    contextmenu: false,
    minimap: {
      enabled: false
    },
    automaticLayout: true
  };

  onCodeChanged(value) {
    console.log('CODE', value);
  }

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

}
