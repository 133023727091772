import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HtmlRenderService } from 'src/app/shared-generic/services/html-render.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { ChatService } from 'src/app/shared/services/chat.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit, OnDestroy{

  private subscriptions = new Dictionary<Subscription>();
  private port = "8100";
  public product_key = "";
  public mobile_url: SafeResourceUrl;
  public component_data: any = {
    html: "",
    css: "",
    typescript: "",
    instance: ""  
  }

  constructor(
    private sanitizer: DomSanitizer, 
    private messageService: MessageService,
    private htmlRenderService: HtmlRenderService,
    private chatService: ChatService) { }

  ngOnInit(): void {
    const callbackGetProductId = "componentTextUpdate"
    this.subscriptions.add(callbackGetProductId,
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackGetProductId))
        .subscribe((msg) => {
          this.component_data = msg.args.component_data;
        }));

    const callbackId = "new_product_key";
    this.subscriptions.add(callbackId,
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.product_key = msg.args.product_key;
        }
    ));
    const msg = {
      name: 'newProductKey',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
   

    
    setTimeout(()=>{
      // this.launchPreview(1)
      const getProductIdMessage = {
        name: 'getComponentData',
        args: [callbackGetProductId]
      };
      this.messageService.sendMsg(getProductIdMessage, 'main');
    }
      , 500);

    this.chatService.update.subscribe( ()=> {
        if (!this.chatService.onLoadingPreview) {
          setTimeout(()=>this.launchPreview(1), 500);  // was 2000
        }
      }
    );

  }

  ngOnDestroy() {
    this.subscriptions.keys().forEach(callbackId =>
       this.subscriptions.item(callbackId).unsubscribe())
  }

  launchPreview(id: any) {
    let url = "/component?port="+this.messageService.dynamic_port;
    this.urlSanitizer(url);
    this.chatService.onLoadingPreview = true;
  }

  /*
  resetData() {
    const restDataMsg = {
      name: 'resetData',
      args: []
    };
    this.messageService.sendMsg(restDataMsg, 'main');
  }
  */

  resetOneStep() {
    this.chatService.messages.pop(); // the prev. AI
    this.chatService.messages.pop(); // the prev. own 
    const restDataMsg = {
      name: 'resetOneStep',
      args: []
    };
    this.messageService.sendMsg(restDataMsg, 'main');
  }

  saveComponent() {
    const capture_id = "iframe_copy"
    const rand = this.messageService.getRandomInt(100000).toString()
    const filename = `component_${rand}.png`
    this.htmlRenderService.createCopy(capture_id, filename, 'main');
    
  }

  urlSanitizer(url: string) {
    this.mobile_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }



}
