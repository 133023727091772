<h3 mat-dialog-title class="dialog-title">{{data.title}}</h3>
<!---first form-->
<div *ngIf="data.message">{{data.message}}</div>
<div *ngIf="dataCheckInfo" [ngStyle]="{'color': 'red'}">{{dataCheckInfo}}</div>
<app-generic-form *ngIf="entityId"
    [xname]="data.form"
    [backend]="data.backend"
    [titlesize]="14"
    [fontsize]="14"
    [entity]="entityId"
    [col_number]="2"
    [frozen]="true"
    [emitNameValidStatus]="true"
    (onChange)="genericData($event)"
></app-generic-form>
<div mat-dialog-actions class="dialog-actions">
    <button mat-button (click)="onCancel()">{{data.text_cancel_button}}</button>
    <button mat-button (click)="onOk()" [disabled]="evalDisable()" cdkFocusInitial>Ok</button>
</div>