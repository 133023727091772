<ul class="text-xl h-full flex flex-col">
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/home'}"
    (click)="toHome()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/home.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>Home</p>
    </div>
  </li>
  <!-- Repeat for other menu items, updating the href as needed -->
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/products'}"
    (click)="toProducts()"
  >
    <div 
      class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/trash.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>Component Creator</p>
    </div>
  </li>
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/subscriptions'}"
    (click)="toSubscriptions()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/membership.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>Subscription Plans</p>
    </div>
  </li>
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/settings'}"
    (click)="toSettings()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/settings.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>Settings</p>
    </div>
  </li>
  
</ul>
