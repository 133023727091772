<div class="flex items-center justify-between w-full">
    <section class="flex flex-row items-center justify-around w-1/5 ml-32">
        <p class="text-white cursor-pointer">© 2024 VisTrace </p>
        <p class="text-white cursor-pointer">Terms</p>
        <p class="text-white cursor-pointer">Privacy</p>
        <p class="text-white cursor-pointer">Cookies</p>
    </section>
    
    <div class="flex flex-1 items-center justify-end mr-10">
        <p class="text-white m-4 cursor-pointer">Contact</p>
        <p style="margin-inline: 128px;" class="text-white m-4 cursor-pointer">About Us</p>
    </div>
    
    <div class="flex items-center lg:px-[8%]">
        <img
          src="assets/icon_linkedin.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
        <img
          src="assets/icon_instagram.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
    </div>   
</div>