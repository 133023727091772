import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { NavMenuItem } from '../../types/nav-menu-item';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @Input() public backend = 'main';
  @Input() public navMenuItems: NavMenuItem[] = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  //replaced by on Changes
  }

  ngOnChanges(changes: SimpleChanges): void {
    let item = this.navMenuItems.filter(item => item.isSelected)[0]
    if (item){
      this.router.navigateByUrl(item.url);
    }
  }

  public navigate(item: NavMenuItem){
    this.router.navigateByUrl(item.url);
    this.navMenuItems.forEach(item => {item.isSelected = false});
    item.isSelected = true;
  }
}
