import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public session_name = "Martin";

  constructor(
    private messageService: MessageService,
    private uiInfoService: UiInfoService) {
    const callbackId = 'sessionData';
    this.messageService.awaitMessage('main').pipe(filter(msg => msg.name === callbackId))
    .subscribe(
        msg => {
          this.session_name = msg.args.session_name;
        });
    // NOTE listener for messages
    this.messageService.awaitMessage()
      .pipe(filter(msg => msg.name === "open_snackbar"))
      .subscribe( msg => {
        console.log("SHOW message", msg.args.text);
        this.uiInfoService.openSnackbar(msg.args.text, 5000);
      }
    );
  }


}
