import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';

@Injectable({
  providedIn: 'root'
})
export class DateSelectorService {
  private subscriptions = [];
  public dateSelectorSubject =  new Subject<Message>();
  public dateSelectorWeekSubject =  new Subject<Message>();
  public dateSelectorHolidaysSubject =  new Subject<Message>();

  constructor(private messageService: MessageService) { }

  public getDate(currentDate: string, direction:number, directionType:string, name: string, backend: string): Subject<Message>  {
    const callbackId = 'dateselector/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.dateSelectorSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.getDateMessage(currentDate, direction,directionType, name,backend);
    return this.dateSelectorSubject;
  }

  public getDateMessage(currentDate: string, direction:number, directionType:string, name: string, backend: string){
    const callbackId = 'dateselector/' + backend;
    const message = {
      name: 'getDateSelectorDate',
      args: [currentDate, direction, directionType, name, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  }

}
