import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.component.html',
  styleUrls: ['./modal-preview.component.scss']
})
export class ModalPreviewComponent implements OnInit, OnDestroy {
  public product_key = "";
  private subscriptions: Array<Subscription>=[];
  url = this.sanitizer.bypassSecurityTrustResourceUrl("https://data.vistrace.quantecdc.es/?id=943829")
  public mobile_url: SafeResourceUrl;
  public selectedProduct = ""

  constructor(private messageService: MessageService,
              private sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<ModalPreviewComponent>
  ) { }

  ngOnInit(): void {
    const callbackId = "new_product_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.product_key = msg.args.product_key;
        }
    ));
    const msg = {
      name: 'newProductKey',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
    // this.previewClicked()
  }

  // previewClicked() {
  //   this.urlSanitizer(this.url)
  // }

  // urlSanitizer(url: string) {
  //   this.mobile_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  // }

  closePreview() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( (subs) =>{
        subs.unsubscribe();
      }
    );
  }

}
