import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-filter',
  templateUrl: './dialog-filter.component.html',
  styleUrls: ['./dialog-filter.component.scss']
})
export class DialogFilterComponent implements OnInit {

  public sortTrigger = new EventEmitter<string>();

  constructor(
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, backend, controller, sort, filterMaxWidth, filtersMaxHeight
  ) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, false);
  }

  onOk() {
    //this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, true);
  }

  public setSortTrigger(event){
    this.sortTrigger.emit(event);
  }

}
