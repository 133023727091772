import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from 'src/app/shared-generic/types/message';

@Injectable({
  providedIn: 'root'
})
export class MonthlyOverviewService {

  private subscriptions = [];
  public monthlyOverviewSubject =  new Subject<Message>();
  public monthlyOverviewDataSubject =  new Subject<Message>();
  
  constructor(private messageService: MessageService) { }

  public getCalendarLayout(date:string, showMonthsAround: boolean, identifier: string, backend: string): Subject<Message>{
    const callbackId = 'monthlyoverview/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            console.log("monthly overview service", callbackId);
            this.monthlyOverviewSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.occupancyMessage(date, showMonthsAround, identifier, backend);
    return this.monthlyOverviewSubject;
  }

  public getCalendarData(date:string, identifier: string, backend: string): Subject<Message>{
    const callbackId = 'monthlyoverviewdata/' + backend;
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            console.log("monthly overview service", callbackId);
            this.monthlyOverviewDataSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    this.occupancyDataMessage(identifier, backend);
    return this.monthlyOverviewDataSubject;
  }

  public occupancyMessage(date:string, showMonthsAround: boolean, identifier: string, backend: string){
    const callbackId = 'monthlyoverview/' + backend;
    const message = {
      name: 'getMonthlyOverview',
      args: [date, showMonthsAround, identifier, callbackId]
  };
  // console.log("Source occupancy msg", source);
  this.messageService.sendMsg(message, backend);
  }

  public occupancyDataMessage(identifier: string, backend: string){
    const callbackId = 'monthlyoverviewdata/' + backend;
    const message = {
      name: 'getMonthlyOverviewData',
      args: [identifier, callbackId]
  };
  // console.log("Source occupancy msg", source);
  this.messageService.sendMsg(message, backend);
  }
}