// ng modules
import { CommonModule } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//own components
import { DatabaseTableComponent } from './components/database-table/database-table.component';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import { EditableTableComponent } from './components/editable-table/editable-table.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { ParameterCardHeaderComponent } from './components/parameter-card-header/parameter-card-header.component';
import { FaqComponent } from './components/faq/faq.component';
import { SnackbarComponent } from '../shared-generic/components/snackbar/snackbar.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { InfoCardComponent } from './components/info-card/info-card.component';

// own dialogs
import { DialogComponent } from './components/dialog/dialog.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { EditorDialogComponent } from './components/editor-dialog/editor-dialog.component';
import { TableDialogComponent } from './components/table-dialog/table-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { ResetPwDialogComponent } from './components/reset-pw-dialog/reset-pw-dialog.component';
import { PinCodeComponent } from './components/pincode/pincode.component';
import { StyledButtonComponent } from './components/styled-button/styled-button.component';
import { QrDialogComponent } from './components/qr-dialog/qr-dialog.component';
// directives
import { DndDirective } from './directives/dnd.directive';

// directives
import { InvalidCharactersDirective } from './directives/invalidCharacters.directive'

// materials
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import { CookieModule } from 'ngx-cookie'; 
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import { CellPipe } from './pipes/cell.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { CodeInputModule } from 'angular-code-input';

import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

@NgModule({
    imports: [
        CookieModule.withOptions(),
        CommonModule, 
        BrowserModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatRadioModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatSelectModule,
        MatBottomSheetModule,
        MatListModule,
        MatTableModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        DragDropModule,
        MatTabsModule,
        MatToolbarModule,
        MatMenuModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatChipsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatProgressSpinnerModule,
        NgxMatSelectSearchModule,
        CodeInputModule,
        QRCodeModule,
        NgxMatColorPickerModule,
    ],
    providers: [
      { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    ],
    declarations: [
        DatabaseTableComponent,
        GenericFormComponent,
        EditableTableComponent,
        EditorDialogComponent,
        TableDialogComponent,
        InvalidCharactersDirective,
        DialogComponent,
        FormDialogComponent,
        InfoDialogComponent,
        LoginDialogComponent,
        ResetPwDialogComponent,
        //pipes
        CellPipe,
        DropZoneComponent,
        DndDirective,
        ParameterCardHeaderComponent,
        FaqComponent,
        NavMenuComponent,
        StyledButtonComponent,
        PinCodeComponent,
        QrDialogComponent,
        SnackbarComponent,
        InfoCardComponent,
    ],
    exports: [
        LoginDialogComponent,
        ResetPwDialogComponent,
        DatabaseTableComponent,
        GenericFormComponent,
        EditableTableComponent,
        InvalidCharactersDirective,
        DropZoneComponent,
        DndDirective,
        ParameterCardHeaderComponent,
        FaqComponent,
        NavMenuComponent,
        StyledButtonComponent,
        PinCodeComponent,
        QrDialogComponent,
        SnackbarComponent,
        InfoCardComponent,
    ]
})
export class SharedGenericModule { 
  
  constructor(
          private matIconRegistry: MatIconRegistry,
          private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'mes_apps',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mes_apps.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'new_password_generator',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/new_password_generator.svg')
    );
  }

}
