

<mat-tab-group> 
<mat-tab label="AI"> 
   

  <!-- Chat Container -->
  <div #scroll [scrollTop]="scroll.scrollHeight" class="mt-4 pr-4 h-[400px] w-[900px] overflow-auto mb-4" style="min-width: 100%">
    <!-- Chat Message AI -->
    <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
      <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
        <div class="rounded-full bg-gray-100 border p-1">
          <svg stroke="none" fill="black" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
            </path>
          </svg>
        </div>
      </span>
      <p class="leading-relaxed"><span class="block font-bold text-gray-700">AI </span> Hello, how can I help you today?
      </p>
    </div>
    <div *ngFor="let message of chatService.messages">
      <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1" *ngIf="message.role == 'user'"><span
          class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
          <div class="rounded-full bg-gray-100 border p-1">
            <svg stroke="none" fill="black" stroke-width="0" viewBox="0 0 16 16" height="20" width="20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
              </path>
            </svg>
          </div>
        </span>
        <p class="leading-relaxed"><span class="block font-bold text-gray-700">User </span>{{message.content}}
        </p>
      </div>

      <!--  User Chat Message -->
      <div class="flex gap-3 my-4 text-gray-600 text-sm flex-1" *ngIf="message.role == 'assistant'"><span
          class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
          <div class="rounded-full bg-gray-100 border p-1">
            <svg stroke="none" fill="black" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20"
              width="20" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
              </path>
            </svg>
          </div>
        </span>
        <p class="leading-relaxed"><span class="block font-bold text-gray-700">AI </span>{{message.content}}</p>
      </div>

    </div>
  </div>

  <!-- Input box  -->

  <div class="flex items-center pt-0 ml-1 mr-1">
    <form [formGroup]="questionForm" class="flex items-center justify-center w-full space-x-2">
      <input
        class="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
        placeholder="Type your message" formControlName="textInput">
      <button
        class="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
        (click)="sendUserMessage()">
        Send</button>
    </form>
  </div>
  <app-generic-form
    [xname]="'product-general-form'" 
    [entity]="product_key"
    [backend]="'main'" 
    [col_number]="1" 
    [frozen]="true" 
    [justifyContent]="'flex-start'"
    [focusFirst]="true">
  </app-generic-form>
</mat-tab>
<mat-tab label="html">
  <div class="pr-4 h-[600px] w-[900px]">
    <div class="editor-container">
      <ngs-code-editor
        [theme]="theme"
        [codeModel]="model_html"
        [options]="options"
        (valueChanged)="onCodeChanged_html($event)"
      ></ngs-code-editor>
      <div class="flex justify-center">
        <button
            class="send-button inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
            (click)="sendHtml()">
            Send</button>
      </div>
    </div>
  </div>
</mat-tab>
<mat-tab label="css">
  <div class="pr-4 h-[600px] w-[900px]">
    <div class="editor-container">
      <ngs-code-editor
        [theme]="theme"
        [codeModel]="model_css"
        [options]="options"
        (valueChanged)="onCodeChanged_css($event)"
      ></ngs-code-editor>
      <div class="flex justify-center">
        <button
          class="send-button inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
          (click)="sendCss()">
          Send</button>
      </div>
    </div>
  </div>
</mat-tab>
<mat-tab label="typescript"> 
  <div class="pr-4 h-[600px] w-[900px]">
    <div class="editor-container">
      <ngs-code-editor
        [theme]="theme"
        [codeModel]="model_ts"
        [options]="options"
        (valueChanged)="onCodeChanged_ts($event)"
      ></ngs-code-editor>
      <div class="flex justify-center">
        <button
          class="send-button inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
          (click)="sendTs()">
          Send</button>
      </div>
    </div>
  </div> 
</mat-tab>
<mat-tab label="instance">
  <div class="pr-4 h-[600px] w-[900px]">
    <div class="editor-container">
      <ngs-code-editor
        [theme]="theme"
        [codeModel]="model_in"
        [options]="options"
        (valueChanged)="onCodeChanged_in($event)"
      ></ngs-code-editor>
      <div class="flex justify-center">
        <button
          class="send-button inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
          (click)="sendInstance()">
          Send</button>
      </div>
    </div>
  </div>
</mat-tab>
<mat-tab label="assets">
  <div class="p-8 h-[600px] w-[900px]">
    
    <app-generic-form 
      [xname]="'product-general-form-I'" 
      [entity]="product_key"
      [backend]="'main'" 
      [col_number]="1" 
      [frozen]="true" 
      [justifyContent]="'flex-start'"
      [focusFirst]="true">
    </app-generic-form>
  </div>
</mat-tab>
</mat-tab-group>
