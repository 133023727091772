import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subcription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss']
})
export class SubscriptionCardComponent implements OnInit {

  @Input() subscriptionType: string = '';
  @Input() activated: boolean = false;
  @Input() subscriptionDesc: string[] = [];
  

  constructor() { }

  ngOnInit(): void {
  }

}
