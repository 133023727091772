<div class="flex h-screen justify-center items-center">
    <div class="p-8 rounded shadow-md w-80">
        <div class="flex justify-center mb-4">
            <h2 class="text-2xl font-semibold">Log in</h2>
        </div>
        <form (ngSubmit)="onSubmit()">
            <div class="mb-4">
                <label for="username" class="block text-sm font-medium text-gray-700">Email</label>
                <input type="text" name="username" [(ngModel)]="email" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="mb-4">
                <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                <input type="password" [(ngModel)]="password" name="password" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="flex flex-col items-center justify-between mb-4">
                <!--div class="flex items-center">
                    <input type="checkbox" name="rememberMe" class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded">
                    <label for="rememberMe" class="ml-2 block text-sm text-gray-900">Remember me</label>
                </div-->
                <a (click)="toPasswordRecover()" class="text-sm text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Forgot your password?</a>
            </div>
            <button (click)="onSubmit()" type="submit" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-vistrace-quinary">Login</button>
            <div class="mt-4 text-center">
                <a (click)="toRegister()" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Register for free</a><br>
                <a class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">Privacy</a>
            </div>
        </form>
    </div>
</div>
