import { Injectable } from '@angular/core';
import { MessageService } from '../../../app/shared-generic/services/message.service';
import { ChartConfig } from './../types/chart-config';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { Message } from 'src/app/shared-generic/types/message';

// this is the global hook to the bokehjs lib (without types)
declare var Bokeh: any;
interface Roots {[index: string]: any};

@Injectable({
    providedIn: 'root'
  })
  export class BokehService {
    //private subscriptions = [];
    private subscriptions: Dictionary<Subscription> = new Dictionary();
    private showFigure = [];
    public date = '';
    public chartConfigs: Dictionary<ChartConfig> = new Dictionary();
    public chartDataSubject = new Subject<Message>();
    public chartFieldDataSubject = new Subject<Message>();

    constructor(private messageService: MessageService) { }

    public plot(id: string, item: any, backend="main") {
        // item is json?
        const el = document.getElementById(id);
        // first remove the previous charts as child
        // this necessary, since bokeh do not let us update a chart
        if (el !== null) {
          while (el.hasChildNodes()) {
                  el.removeChild(el.lastChild);
          }
          // be sure to include the correct dom-id as second argument
          // github.com/bokeh/bokeh/blob/b19f2c5547024bdc288d02e73fdb65e65991df5f/bokehjs/src/lib/embed/index.ts
          const doc = Bokeh.Document.from_json(item.doc);
          //if (doc.get_model_by_name('map-circles')) {
          const roots: Roots = {[item.root_id]: el};
          // next call is mandatory for tools!!!
          el.classList.add(Bokeh.embed.BOKEH_ROOT);
          const source = this.getSources(doc, id);
          // see the function below how to manipulate the source
          // do not forget to call source.change.emit() after data change
          Bokeh.embed.add_document_standalone(doc, el, roots, false);
          this.prepareUpdateChartData(source, id, backend); 
          //} else { 
          //  Bokeh.embed.embed_item(item, id);
          //}
          return true;
        } else {
            console.log("ELEMENT", el);
            return false;
        }
      }
  
      public getSources(doc: any, id: string) {
        let nn = 0;
        let name = id + `_${nn}`;
        // console.log(doc.get_model_by_name(name));
        const sources = [];
        while(doc.get_model_by_name(name)) {
          sources.push(doc.get_model_by_name(name).data_source);
          nn += 1;
          name = id + `_${nn}`;
        }
        console.log("getSources: ", id, sources);
        return sources;
      }
  
      public prepareUpdateChartData(sources: Array<any>, chartId: string, backend="main") {
              const callbackId = 'updateChartData_' + chartId;
              if (this.subscriptions.containsKey(callbackId)) {
                this.subscriptions.item(callbackId).unsubscribe();
              }
              const subs = this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
                  .subscribe(
                  msg => {
                      console.log("UPDATE CHART", msg.args.data);
                      // try something more generic
                      sources.forEach( source => {
                         //console.log(source);
                          source.data = msg.args.data;
                          source.change.emit();
                      })
                  });
              this.subscriptions.add(callbackId, subs);
          }

    public replot(chartId: string, backend: string) {
        const callbackId = 'updateChartData_';  // is appended in the backend + chartId;
        console.log("REPLOT ", callbackId, chartId);
        const message = {
            name: 'updateChartData',
            args: [chartId, callbackId]
        };
        this.messageService.sendMsg(message, backend);
    }

    public getChartFieldData(chartFieldId: string, backend: string): Subject<Message> {
      const callbackId = 'chartfielddata/' + backend;
      //if (this.subscriptions.indexOf(callbackId) === -1) {
      if (this.subscriptions.containsKey(callbackId)) {
        this.subscriptions.item(callbackId).unsubscribe();
      }
        const subs = this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
          .subscribe(
            msg => {
            console.log("receive chart field data ....", msg);
              this.chartFieldDataSubject.next(msg);
            });
        this.subscriptions.add(callbackId, subs);
      //}
      // why and how much of delay ???? (was 2 secs)
      setTimeout( ()=> {
        this.getChartFieldDataMessage(chartFieldId, backend);
        }, 1000);
      return this.chartFieldDataSubject; 
    }

    public getChartFieldDataMessage(chartFieldId:string, backend: string){
      const callbackId = 'chartfielddata/' + backend;
      console.log("get chart field data message....", chartFieldId, callbackId);
      const message = {
          name: 'getChartFieldData',
          args: [chartFieldId, callbackId]
      };
      this.messageService.sendMsg(message, backend);
    }

    public getChartItem(chartId: string, backend: string): Subject<Message> {
      const callbackId = 'chartdata/' + backend;
      if (this.subscriptions.containsKey(callbackId)) {
        this.subscriptions.item(callbackId).unsubscribe();
      }
      //if (this.subscriptions.indexOf(callbackId) === -1) {
        const subs = this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
          .subscribe(
            msg => {
              this.chartDataSubject.next(msg);
            });
        //this.subscriptions.push(callbackId);
        this.subscriptions.add(callbackId, subs);
      //}
      this.restartChart(chartId, backend)
      return this.chartDataSubject;
    }

    public restartChart(chartId: string, backend: string) {
        const callbackId = 'chartdata/' + backend;
        const message = {
            name: 'getChartData',
            args: [chartId, callbackId]
        };
        this.messageService.sendMsg(message, backend);
    }

    /*
    public getChart(id: number) {
      const callbackId = 'plot';
      const msg = {
        name: 'addChart',
        args: [id, callbackId],
        action: 'default'
      };
      this.messageService.sendMsg(msg);
      this.getChartConfig(id);
    }
    public getChartConfig(id: number) {
      const callbackId = 'config';
      const msg = {
        name: 'getChartConfig',
        args: [id, callbackId],
        action: 'default'
      };
      this.messageService.sendMsg(msg);
    }
    public configChart(id: number, chart: ChartConfig) {
      // remote procedure call !!! via websocket connection
      console.log("try to send chart", chart);
      const message = {
        name: 'configChart',
        args: [id, chart]
      };
      this.messageService.sendMsg(message);
    }
    */

  }

/*
  // https://github.com/bokeh/bokeh/blob/b19f2c5547024bdc288d02e73fdb65e65991df5f/bokehjs/src/lib/embed/index.ts

  // TODO make use of the BOKEH API (here a simple standalone example)
  // more info e.g.: https://github.com/bokeh/bokeh/blob/1.4.0/bokehjs/src/lib/api/plotting.ts

  // create a data source to hold data
var source = new Bokeh.ColumnDataSource({
    data: { x: [], y: [] }
});

// make a plot with some tools
var plot = Bokeh.Plotting.figure({
    title:'Example of Random data',
    tools: "pan,wheel_zoom,box_zoom,reset,save",
    height: 300,
    width: 300
});

// add a line with data from the source
plot.line({ field: "x" }, { field: "y" }, {
    source: source,
    line_width: 2
});

// show the plot, appending it to the end of the current section
Bokeh.Plotting.show(plot);

function addPoint() {
    // add data --- all fields must be the same length.
    source.data.x.push(Math.random())
    source.data.y.push(Math.random())

    // notify the DataSource of "in-place" changes
    source.change.emit()
}
*/